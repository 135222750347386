import React from "react";

import Modal from "../../../../common/Modal";
import CreateDocumentWorkflow from "../../workflows/CreateDocumentWorkflow";

import "./styles.scss";

export type CreateDocumentModalProps = {
  opportunityId: string;
  scenarioId: string;
  onCancel: () => void;
};

const CreateDocumentModal = ({
  opportunityId,
  scenarioId,
  onCancel,
}: CreateDocumentModalProps) => {
  return (
    <div className="component create-document-modal">
      <Modal
        title="Create Document"
        width={700}
        contentHeight={300}
        onClose={onCancel}
      >
        <CreateDocumentWorkflow
          opportunityId={opportunityId}
          scenarioId={scenarioId}
          onCancel={onCancel}
        />
      </Modal>
    </div>
  );
};

export default CreateDocumentModal;
