import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { map } from "ramda";
import moment from "moment/moment";

import { OpportunitySummaryFragment } from "@pricing-tool/graphql/lib/react";

import LoadingIndicator from "../../../common/LoadingIndicator";
import DebouncedTextField from "../../../common/DebouncedTextField";

import "./styles.scss";

export type OpportunitySearchFormProps = {
  searchTerm?: string;
  searching: boolean;
  searchResults: OpportunitySummaryFragment[];
  onSearchTermUpdated: (searchTerm: string) => void;
  onSearch: (searchTerm: string) => void;
  onCancel: () => void;
  onSelectOpportunity: (opportunity: OpportunitySummaryFragment) => void;
};

const OpportunitySearchForm = ({
  searchTerm,
  searching,
  searchResults,
  onSearchTermUpdated,
  onSearch,
  onCancel,
  onSelectOpportunity,
}: OpportunitySearchFormProps) => {
  const searchFieldRef = React.useRef<HTMLInputElement>(null);

  const focusSearchField = () => {
    if (searchFieldRef.current) {
      (searchFieldRef.current as HTMLInputElement).focus();
    }
  };

  React.useEffect(focusSearchField, [searchFieldRef]);

  return (
    <div className="opportunity-search-form">
      <div className="body">
        <div className="header relative mb-3 mx-3">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            {searching && <LoadingIndicator size="sm" />}
            {!searching && (
              <FontAwesomeIcon icon="search" className="text-gray-500" />
            )}
          </span>
          <DebouncedTextField
            className="search-input w-full pl-7 py-1 border rounded-md bg-gray-100 text-sm"
            debounceTime={300}
            ref={searchFieldRef}
            onChange={(event) => onSearchTermUpdated(event.target.value)}
            onDebouncedChange={onSearch}
            placeholder="Search for opportunities"
            value={searchTerm || ""}
          />
          {searchTerm && searchTerm.length > 0 && (
            <button
              className="absolute top-0 right-0 rounded-l-none btn btn-primary pr-2 py-1"
              onClick={() => {
                onSearchTermUpdated("");
                onSearch("");
              }}
            >
              <FontAwesomeIcon icon="times" />
            </button>
          )}
        </div>
        <div className="search-results">
          <div className="mx-3 mb-3">
            {map(
              (opportunity) => (
                <div
                  key={opportunity.id}
                  className="opportunity-result"
                  onClick={() => onSelectOpportunity(opportunity)}
                >
                  <div className="name">{opportunity.name}</div>
                  <div className="text-xs">
                    <span className="inline-block pr-6">
                      <div className="pb-1">
                        <span className="inline-block w-6 text-sm">
                          <FontAwesomeIcon icon="user" />
                        </span>
                        <span className="text-sm">
                          {opportunity.client?.name || "Unknown Client"}
                        </span>
                      </div>
                      <div>
                        <span className="inline-block w-6 text-sm">
                          <FontAwesomeIcon icon="user-friends" />
                        </span>
                        <span className="text-sm">{opportunity.owner}</span>
                      </div>
                    </span>
                    <span className="inline-block pr-4 pl-2 border-l-gray-500 border-l">
                      <div className="font-semibold uppercase text-gray-500 pb-2">
                        Pricing Date
                      </div>
                      <div className="">
                        {opportunity.pricingDate
                          ? moment(opportunity.pricingDate).format("LL")
                          : "--"}
                      </div>
                    </span>
                    <span className="inline-block pr-4 pl-2 border-l-gray-500 border-l">
                      <div className="font-semibold uppercase text-gray-500 pb-2">
                        Close Date
                      </div>
                      <div className="">
                        {opportunity.closeDate
                          ? moment(opportunity.closeDate).format("LL")
                          : "--"}
                      </div>
                    </span>
                    <span className="inline-block pr-4 pl-2 border-l-gray-500 border-l">
                      <div className="font-semibold uppercase text-gray-500 pb-2">
                        Region
                      </div>
                      <div className="">{opportunity.region || "--"}</div>
                    </span>
                  </div>
                </div>
              ),
              searchResults || [],
            )}
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:items-end">
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-auto sm:w-auto sm:text-sm"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default OpportunitySearchForm;
