import { Schema as S } from "@effect/schema";
import Decimal from "decimal.js-light";

import { Scenario } from "@pricing-tool/lib/scenario/core";
import {
  constructPerformance,
  Performance,
  PerformanceSchema,
  PerformanceTargets,
} from "../../../utils/constructPerformance";
import { OptDecimalFromString } from "@pricing-tool/lib/util/Decimal";

export const ScenarioSummarySchema = S.Struct({
  id: S.String,
  name: S.String,
  selected: S.Boolean,
  metrics: S.Struct({
    returnOnEquity: OptDecimalFromString,
    returnOnAssets: OptDecimalFromString,
  }),
  performance: PerformanceSchema,
});
export type ScenarioSummary = S.Schema.Type<typeof ScenarioSummarySchema>;
export type ScenarioSummaryDto = S.Schema.Encoded<typeof ScenarioSummarySchema>;

export const toScenarioSummary = (
  scenario: Scenario,
  targets?: PerformanceTargets,
  selectedScenarioId?: string,
): ScenarioSummary => {
  const returnOnEquity = scenario.incomeStatement?.returnOnEquity;
  const returnOnAssets = scenario.incomeStatement?.returnOnAssets;

  return {
    id: scenario.id,
    name: scenario.name,
    selected: scenario.id === selectedScenarioId,
    metrics: {
      returnOnEquity,
      returnOnAssets,
    },
    performance: constructPerformance(returnOnEquity, returnOnAssets, targets),
  };
};
