import Decimal from "decimal.js-light";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const PercentAmount = ({ amount }: { amount?: Decimal }) => {
  return (
    <div className="flex justify-end pl-2">
      <span>{amount?.mul(100).toFormat(2)}</span>
      <span className="pl-2">
        <FontAwesomeIcon className="text-gray-500" icon="percent" size="xs" />
      </span>
    </div>
  );
};

export default PercentAmount;
