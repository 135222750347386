import { Schema as S } from "@effect/schema";

import { IncomeStatement, IncomeStatementSchema } from "./index";

export type IncomeStatementDto = S.Schema.Encoded<typeof IncomeStatementSchema>;

export const fromDto = (
  dto: IncomeStatementDto | undefined,
): IncomeStatement => {
  return S.decodeUnknownSync(IncomeStatementSchema)(dto);
};

export const toDto = (incomeStatement: IncomeStatement): IncomeStatementDto => {
  return S.encodeSync(IncomeStatementSchema)(incomeStatement);
};
