import { join } from "ramda";

import { Address } from "@pricing-tool/graphql/lib/react";

export default (address: Address) => {
  if (!address) return "";
  const { street, extended, region, postalCode } = address;
  const fullStreet = `${street}${extended ? `, ${extended}` : ""}`;
  const fullRegion = `${region}, ${postalCode}`;
  return join(", ", [fullStreet, fullRegion]);
};
