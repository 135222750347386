import { Schema as S } from "@effect/schema";

import { ScenarioSchema } from "../scenario/core";
import { ClientSchema } from "../client/core";
import { IncomeStatementSchema } from "../incomeStatement";
import NullToUndefined from "../util/NullToUndefined";

export const OpportunityStatusSchema = S.Literal("IN_PROGRESS", "LOST", "WON");
export type OpportunityStatus = S.Schema.Type<typeof OpportunityStatusSchema>;

export const OpportunitySchema = S.Struct({
  id: S.String,
  branchId: S.String,
  clientId: S.String,
  client: S.optional(S.mutable(ClientSchema)),
  name: S.String,
  owner: S.optional(NullToUndefined(S.String)),
  status: OpportunityStatusSchema,
  pricingDate: S.optional(NullToUndefined(S.String)),
  closeDate: S.optional(NullToUndefined(S.String)),
  region: S.optional(NullToUndefined(S.String)),
  scenarios: S.mutable(S.Array(ScenarioSchema)),
  pipelineScenarioId: S.optional(NullToUndefined(S.String)),
  isDirty: S.optional(S.mutable(S.Boolean)),
  incomeStatement: S.optional(S.NullishOr(IncomeStatementSchema)),
});
export type Opportunity = S.Schema.Type<typeof OpportunitySchema>;
