import { useCallback, useMemo, useState } from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import moment from "moment";

import "./styles.scss";
import "react-datepicker/dist/react-datepicker.css";

export type DateFieldProps = {
  value?: string;
  onChange: (date: string) => void;
} & ReactDatePickerProps;

const DateField = ({ value, onChange }: DateFieldProps) => {
  const [open, setOpen] = useState(false);

  const selected = useMemo(
    () => (value ? moment(value).toDate() : null),
    [value],
  );

  const _onChange = useCallback(
    (newValue) => {
      onChange(newValue ? moment(newValue).format("YYYY-MM-DD") : newValue);
    },
    [onChange],
  );

  return (
    <div className="date-field">
      <DatePicker
        selected={selected}
        onChange={_onChange}
        onCalendarOpen={() => setOpen(true)}
        onCalendarClose={() => setOpen(false)}
        className={`relative w-full py-1 px-2 cursor-default overflow-hidden rounded-md bg-white text-left shadow-sm border border-gray-300 focus:ring-2 focus:ring-blue-500 ${
          open ? "ring-2 ring-blue-400" : ""
        }`}
        dateFormat="MMMM d, yyyy"
      />
    </div>
  );
};

export default DateField;
