import {
  asObservable,
  OpportunityModel,
} from "@pricing-tool/lib/opportunity/model";
import { useMemo } from "react";
import { useObservableState } from "observable-hooks";

export default function useOpportunityModel(
  opportunityModel: OpportunityModel,
) {
  const opportunity$ = useMemo(
    () => asObservable(opportunityModel),
    [opportunityModel],
  );
  const [scenarioModels] = useObservableState(
    () => opportunityModel.input.scenarios,
  );
  const [opportunity] = useObservableState(() => opportunity$);

  return { opportunity$, scenarioModels: scenarioModels || [], opportunity };
}
