import * as R from "ramda";
import { combineLatest, map, Observable, startWith } from "rxjs";

const any = (values: boolean[]): boolean =>
  R.reduce((acc, val) => acc || val, false, values);

export default (observables: Observable<boolean>[]) => {
  const combined = combineLatest(observables);
  return combined.pipe(map(any), startWith(false));
};
