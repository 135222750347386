import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  anyPass,
  complement,
  filter,
  flatten,
  includes,
  map,
  prop,
  reduce,
  uniqBy,
} from "ramda";

import { Element, Package } from "@pricing-tool/lib/products/treasury/core";
import {
  getPrerequisiteElements,
  hasElementAsPrerequisite,
} from "@pricing-tool/lib/products/treasury/core/Element";

import PackageTree from "../fields/PackageTree";

import "./styles.scss";
import { AddPackagesFormProps } from "./props";

const AddPackagesForm = (props: AddPackagesFormProps) => {
  const [searchText, setSearchText] = React.useState("");
  const [filteredPackages, setFilteredPackages] = React.useState(
    props.packages,
  );
  const [addedElements, setAddedElements] = React.useState<Element[]>([]);
  const searchFieldRef = React.useRef<HTMLInputElement>(null);

  const elements = flatten(map(prop("elements"), props.packages));

  const packageMatchesSearchText = (searchText: string) => (pkg: Package) =>
    searchText.length === 0 ||
    pkg.name.toUpperCase().includes(searchText.toUpperCase());

  const filterPackages = () => {
    setFilteredPackages(
      filter(packageMatchesSearchText(searchText), props.packages),
    );
  };

  const focusSearchField = () => {
    if (searchFieldRef.current) {
      (searchFieldRef.current as HTMLInputElement).focus();
    }
  };

  React.useEffect(focusSearchField, [searchFieldRef]);
  React.useEffect(filterPackages, [searchText, addedElements, props.packages]);

  const _addElement = (acc: Element[], element: Element): Element[] => {
    const prerequisiteElements = getPrerequisiteElements(elements, element);
    return [...acc, element, ...prerequisiteElements];
  };

  const addElement = (element: Element) => {
    const newAddedElements = _addElement(addedElements, element);
    setAddedElements(uniqBy(prop("id"), newAddedElements));
    focusSearchField();
  };

  const _removeElement = (acc: Element[], element: Element) => {
    const isElement = (e: Element) => e.id === element.id;
    const hasAsPrerequisite = (e: Element) =>
      hasElementAsPrerequisite(elements, e, element);
    const predicate = complement(anyPass([isElement, hasAsPrerequisite]));
    return filter(predicate, acc);
  };

  const removeElement = (element: Element) => {
    const newAddedElements = _removeElement(addedElements, element);
    setAddedElements(newAddedElements);
    focusSearchField();
  };

  const addPackage = (pkg: Package) => {
    const notAddedPackageElements = filter(
      (el) => !includes(el.id, props.addedElementIds),
      pkg.elements,
    );
    const newAddedElements = reduce(
      _addElement,
      addedElements,
      notAddedPackageElements,
    );
    setAddedElements(uniqBy(prop("id"), newAddedElements));
    focusSearchField();
  };

  const removePackage = (pkg: Package) => {
    const notAddedPackageElements = filter(
      (el) => !includes(el.id, props.addedElementIds),
      pkg.elements,
    );
    const newAddedElements = reduce(
      _removeElement,
      addedElements,
      notAddedPackageElements,
    );
    setAddedElements(newAddedElements);
    focusSearchField();
  };

  return (
    <div className="add-elements-form">
      <div className="header relative mb-3 mx-3">
        <span className="absolute inset-y-0 left-0 flex items-center pl-2">
          <FontAwesomeIcon icon="search" className="text-gray-500" />
        </span>
        <input
          className="search-input w-full pl-7 py-1 border rounded-md bg-gray-100 text-sm"
          type="text"
          ref={searchFieldRef}
          onChange={(field) => setSearchText(field.target.value)}
          placeholder="Search for elements"
          value={searchText}
        />
        {searchText.length > 0 && (
          <button
            className="absolute top-0 right-0 rounded-l-none btn btn-primary pr-2 py-1"
            onClick={() => setSearchText("")}
          >
            <FontAwesomeIcon icon="times" />
          </button>
        )}
      </div>
      <div className="body overflow-auto">
        <div className="mx-3 mb-3">
          <div className="rounded-md border p-3">
            {map(
              (pkg: Package) => (
                <div key={pkg.id} className="package-container">
                  <PackageTree
                    package={pkg}
                    previouslyAddedElementIds={props.addedElementIds}
                    addedElementIds={map(prop("id"), addedElements)}
                    onPackageCheck={addPackage}
                    onPackageUncheck={removePackage}
                    onElementCheck={addElement}
                    onElementUncheck={removeElement}
                  />
                </div>
              ),
              filteredPackages,
            )}
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:items-end">
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-auto sm:w-auto sm:text-sm"
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => props.onConfirm(addedElements)}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddPackagesForm;
