import React, { useCallback, useEffect, useState } from "react";
import { filter, find, includes, isEmpty } from "ramda";

import { Opportunity } from "@pricing-tool/lib/opportunity/core";
import { Product, ProductType } from "@pricing-tool/lib/products/core";

import ScenariosPicker from "../../fields/ScenariosPicker";
import ProductsPicker from "../../fields/ProductsPicker";

import "./styles.scss";

export type ScenarioProductsPickerWorkflowProps = {
  opportunity: Opportunity;
  excludedScenarioId?: string;
  onConfirm: (scenarioId: string, products: Readonly<Product[]>) => void;
  onCancel: () => void;
  onBack?: () => void;
};

const ScenarioProductsPickerWorkflow = ({
  opportunity,
  excludedScenarioId,
  onConfirm,
  onCancel,
  onBack,
}: ScenarioProductsPickerWorkflowProps) => {
  const [workflowState, setWorkflowState] = useState<"scenario" | "product">(
    "scenario",
  );
  const [selectedScenarioId, setSelectedScenarioId] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (!selectedScenarioId) {
      setWorkflowState("scenario");
      return;
    }

    setWorkflowState("product");
  }, [selectedScenarioId]);

  const _onConfirm = useCallback(
    (selectedProductTypes: ProductType[]) => {
      if (!selectedScenarioId) return;
      if (isEmpty(selectedProductTypes)) return;

      const selectedScenario = find(
        (scenario) => scenario.id === selectedScenarioId,
        opportunity?.scenarios || [],
      );
      if (!selectedScenario) return;

      const selectedProducts = filter(
        (product) => includes(product._type, selectedProductTypes),
        selectedScenario.products,
      );

      onConfirm(selectedScenarioId, selectedProducts);
    },
    [selectedScenarioId, onConfirm, opportunity?.scenarios],
  );

  return (
    <div className="scenario-products-picker-workflow">
      {workflowState === "scenario" && (
        <ScenariosPicker
          opportunity={opportunity}
          excludedScenarioId={excludedScenarioId}
          selectMultiple={false}
          onConfirm={([id]) => setSelectedScenarioId(id)}
          onCancel={onCancel}
          onBack={onBack}
        />
      )}

      {workflowState === "product" && (
        <>
          <ProductsPicker
            opportunity={opportunity}
            selectMultiple={true}
            selectedScenarioId={selectedScenarioId!}
            onConfirm={_onConfirm}
            onBack={() => setSelectedScenarioId(undefined)}
            onCancel={onCancel}
          />
        </>
      )}
    </div>
  );
};

export default ScenarioProductsPickerWorkflow;
