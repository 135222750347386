import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router";
import Helmet from "react-helmet";

import {
  useGetClientQuery,
  useUpdateClientMutation,
} from "@pricing-tool/graphql/lib/react";

import LoadingOverlay from "../../../components/common/LoadingOverlay";
import ErrorBanner from "../../../components/common/ErrorBanner";
import ClientForm, {
  ClientFormValue,
} from "../../../components/client/forms/ClientForm";

function EditClient() {
  const { clientId } = useParams();
  const navigate = useNavigate();

  const { data, loading, error } = useGetClientQuery({
    variables: {
      branchId: "branch-1",
      clientId: clientId!,
    },
    fetchPolicy: "network-only",
  });

  const [updateClient, { loading: updateLoading, error: updateError }] =
    useUpdateClientMutation();

  const onSubmit = useCallback(
    (client: ClientFormValue) => {
      updateClient({
        variables: {
          branchId: "branch-1",
          id: clientId!,
          input: {
            branchId: "branch-1",
            ...client,
          },
        },
      }).then(() => {
        navigate(`/clients`);
      });
    },
    [updateClient, navigate],
  );

  const onCancel = () => {
    navigate(`/clients/${clientId}`);
  };

  return (
    <div className="page update-client">
      <Helmet>
        <title>Update Client</title>
      </Helmet>
      <div className="text-2xl">Update Client</div>

      {updateLoading && <LoadingOverlay text="Updating client" />}
      {loading && <LoadingOverlay text="Fetching client" />}

      {error && <ErrorBanner text={error.message} />}
      {updateError && <ErrorBanner text={updateError.message} />}
      {data && data.getClient && (
        <ClientForm
          client={{
            name: data.getClient.name,
            address: {
              street: data.getClient.address?.street || undefined,
              extended: data.getClient.address?.extended || undefined,
              city: data.getClient.address?.city || undefined,
              region: data.getClient.address?.region || undefined,
              postalCode: data.getClient.address?.postalCode || undefined,
            },
            contact: {
              firstName: data.getClient.contact?.firstName || undefined,
              lastName: data.getClient.contact?.lastName || undefined,
              title: data.getClient.contact?.title || undefined,
              phone: data.getClient.contact?.phone || undefined,
              email: data.getClient.contact?.email || undefined,
            },
          }}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      )}
    </div>
  );
}

export default EditClient;
