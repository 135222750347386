import Decimal from "decimal.js-light";
import * as R from "ramda";
import {
  BehaviorSubject,
  combineLatest,
  defaultIfEmpty,
  map,
  mergeMap,
  Observable,
  of,
  share,
  tap,
} from "rxjs";

import { Treasury, TreasuryDependencies, TreasuryService } from "../core";
import {
  asObservable as asTreasuryServiceObservable,
  TreasuryServiceModel,
  fromTreasuryService,
} from "./TreasuryServiceModel";
import {
  asObservable as asIncomeStatementObservable,
  ProductOutput,
} from "../../../incomeStatement";

import anyChanged from "../../../util/operators/anyChanged";
import fieldChanged from "../../../util/operators/fieldChanged";
import arrayFieldChanged from "../../../util/operators/arrayFieldChanged";
import mapAllDefined from "../../../util/operators/mapAllDefined";

export type TreasuryInput = {
  investableBalance: BehaviorSubject<Decimal | undefined>;
  earningsCreditRate: BehaviorSubject<Decimal | undefined>;
  discount: BehaviorSubject<Decimal | undefined>;
  treasuryServices: BehaviorSubject<TreasuryServiceModel[]>;
};

export type TreasuryOutput = ProductOutput & {
  earningsCreditAllowance: Observable<Decimal | undefined>;
  discountedAmount: Observable<Decimal | undefined>;
};

export type TreasuryModel = {
  _type: "treasury";
  isDirty: Observable<boolean>;
  input: TreasuryInput;
  output: TreasuryOutput;
};

const trackChanges = (input: TreasuryInput): Observable<boolean> =>
  anyChanged([
    fieldChanged(input.investableBalance),
    fieldChanged(input.earningsCreditRate),
    fieldChanged(input.discount),
    arrayFieldChanged(input.treasuryServices, (tsm) => tsm.input.element.id),
  ]);

export const calculate = (
  input: TreasuryInput,
  dependencies: TreasuryDependencies,
): TreasuryOutput => {
  const { treasuryServices, investableBalance, earningsCreditRate, discount } =
    input;
  const { taxRate } = dependencies;

  const revenues = treasuryServices.pipe(
    mergeMap((models: TreasuryServiceModel[]) =>
      defaultIfEmpty<Decimal[], Decimal[]>([])(
        combineLatest(models.map((model) => model.output.revenue)),
      ),
    ),
  );

  const profits = treasuryServices.pipe(
    mergeMap((models: TreasuryServiceModel[]) =>
      defaultIfEmpty<Decimal[], Decimal[]>([])(
        combineLatest(models.map((model) => model.output.profit)),
      ),
    ),
  );

  const feeIncome = revenues.pipe(
    map(
      R.reduce(
        (acc: Decimal, revenue: Decimal) => acc.plus(revenue),
        new Decimal(0),
      ),
    ),
  );

  const grossProfit = profits.pipe(
    map(
      R.reduce(
        (acc: Decimal, profit: Decimal) => acc.plus(profit),
        new Decimal(0),
      ),
    ),
    // tap((value) => console.log(`Gross Profit: ${value}`)),
  );

  const feeExpense = combineLatest([feeIncome, grossProfit]).pipe(
    map(([feeIncome, grossProfit]) => feeIncome.minus(grossProfit)),
  );

  const netFeeIncome = combineLatest([feeIncome, feeExpense]).pipe(
    map(([feeIncome, feeExpense]) => feeIncome.minus(feeExpense)),
  );

  const earningsCreditAllowance = combineLatest([
    investableBalance,
    earningsCreditRate,
  ]).pipe(
    mapAllDefined(([balance, ecr]) =>
      balance.mul(ecr).dividedBy(12).toDecimalPlaces(6),
    ),
  );

  const discountedAmount = combineLatest([feeIncome, discount]).pipe(
    mapAllDefined(([grossFees, discount]) => grossFees.mul(discount)),
  );

  const pretaxIncome = combineLatest([
    netFeeIncome,
    earningsCreditAllowance,
  ]).pipe(
    mapAllDefined(([netFeeIncome, earningsCreditAllowance]) => {
      const toReturn = netFeeIncome.minus(earningsCreditAllowance);
      return toReturn.lessThan(0) ? new Decimal(0) : toReturn;
    }),
  );

  const taxes = pretaxIncome.pipe(
    map((pretaxIncome) => pretaxIncome?.mul(taxRate)),
  );

  const netIncome = combineLatest([pretaxIncome, taxes]).pipe(
    mapAllDefined(([pretaxIncome, taxes]) => pretaxIncome.minus(taxes)),
  );

  return {
    earningsCreditAllowance,
    discountedAmount,
    incomeStatementOutput: {
      feeOrInterestIncome: feeIncome,
      feeOrInterestExpense: feeExpense,
      netFeeOrInterestIncome: netFeeIncome,
      nonFeeOrInterestExpense: earningsCreditAllowance,
      pretaxIncome,
      taxes,
      netIncome,
    },
  };
};

const defaults: Treasury = {
  _type: "treasury",
  investableBalance: new Decimal(250_000),
  earningsCreditRate: new Decimal(0.045),
  discount: new Decimal(0),
};

export const init = (dependencies: TreasuryDependencies): TreasuryModel => {
  return {
    ...fromTreasury(defaults, dependencies),
    isDirty: of(true),
  };
};

export const fromTreasury = (
  treasury: Treasury,
  dependencies: TreasuryDependencies,
): TreasuryModel => {
  const investableBalance = new BehaviorSubject<Decimal | undefined>(
    treasury.investableBalance || new Decimal(0), // FIXME
  );
  const earningsCreditRate = new BehaviorSubject<Decimal | undefined>(
    treasury.earningsCreditRate || new Decimal(0),
  );
  const discount = new BehaviorSubject<Decimal | undefined>(
    treasury.discount || new Decimal(0),
  );
  const treasuryServices = new BehaviorSubject<TreasuryServiceModel[]>(
    R.map(fromTreasuryService, treasury.treasuryServices || []),
  );

  const input = {
    investableBalance,
    earningsCreditRate,
    discount,
    treasuryServices,
  };

  const output = calculate(input, dependencies);

  return {
    _type: "treasury",
    isDirty: trackChanges(input),
    input,
    output,
  };
};

export const asObservable = (model: TreasuryModel): Observable<Treasury> => {
  const { investableBalance, earningsCreditRate, discount, treasuryServices } =
    model.input;
  const { earningsCreditAllowance, discountedAmount, incomeStatementOutput } =
    model.output;

  const treasuryServices$ = treasuryServices.pipe(
    mergeMap((models) =>
      defaultIfEmpty<TreasuryService[], TreasuryService[]>([])(
        combineLatest(models.map(asTreasuryServiceObservable)),
      ),
    ),
  );

  const incomeStatement = asIncomeStatementObservable(incomeStatementOutput);

  return combineLatest([
    investableBalance,
    earningsCreditRate,
    discount,
    treasuryServices$,
    earningsCreditAllowance,
    discountedAmount,
    incomeStatement,
  ]).pipe(
    map(
      ([
        investableBalance,
        earningsCreditRate,
        discount,
        treasuryServices,
        earningsCreditAllowance,
        discountedAmount,
        incomeStatement,
      ]) =>
        ({
          _type: "treasury",
          investableBalance,
          earningsCreditRate,
          discount,
          treasuryServices,
          earningsCreditAllowance,
          discountedAmount,
          incomeStatement,
        } as Treasury),
    ),
    // tap((value) => console.log("treasury", value)),
    share(),
  );
};
