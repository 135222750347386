import React, { MouseEventHandler } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modifier } from "../../../../../common/Input";

type GroupSelectIconProps = {
  onGroupSelect?: (modifier: Modifier) => void;
};

const GroupSelectIcon = ({ onGroupSelect }: GroupSelectIconProps) => {
  const onMouseDown: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const modifier: Modifier = event.shiftKey
      ? "shift"
      : event.metaKey || event.ctrlKey
      ? "command"
      : "none";
    onGroupSelect && onGroupSelect(modifier);
  };

  return (
    <button onMouseDown={onMouseDown}>
      <FontAwesomeIcon icon="pen-square" size="lg" />
    </button>
  );
};

export default GroupSelectIcon;
