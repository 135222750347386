import { Schema as S } from "@effect/schema";

import {
  CommercialIndustrialSchema,
  CommercialIndustrialDependencies,
} from "../../ci/core";

export const CommercialRealEstateSchema = S.extend(
  CommercialIndustrialSchema.omit("_type"),
  S.Struct({
    _type: S.Literal("cre"),
  }),
);
export type CommercialRealEstate = S.Schema.Type<
  typeof CommercialRealEstateSchema
>;

export type CommercialRealEstateDependencies = CommercialIndustrialDependencies;
