import { Schema as S } from "@effect/schema";
import Decimal from "decimal.js-light";
import { indexOf } from "ramda";
import { DecimalFromString } from "@pricing-tool/lib/util/Decimal";

export const TargetPerformanceSchema = S.Literal(
  "meets",
  "close",
  "neutral",
  "poor",
);
export type TargetPerformance = S.Schema.Type<typeof TargetPerformanceSchema>;

export const PerformanceSchema = S.Struct({
  aggregatePerformance: TargetPerformanceSchema,
  returnOnEquityPerformance: TargetPerformanceSchema,
  returnOnAssetsPerformance: TargetPerformanceSchema,
});
export type Performance = {
  aggregatePerformance: TargetPerformance;
  returnOnEquityPerformance: TargetPerformance;
  returnOnAssetsPerformance: TargetPerformance;
};

export const PerformanceTargetsSchema = S.Struct({
  returnOnEquity: DecimalFromString,
  returnOnAssets: DecimalFromString,
});
export type PerformanceTargets = S.Schema.Type<typeof PerformanceTargetsSchema>;

export const constructPerformance = (
  returnOnEquity: Decimal | null | undefined,
  returnOnAssets: Decimal | null | undefined,
  targets?: PerformanceTargets,
): Performance => {
  let returnOnEquityPerformance: TargetPerformance = "neutral";
  let returnOnAssetsPerformance: TargetPerformance = "neutral";

  const performanceIndices: TargetPerformance[] = [
    "poor",
    "neutral",
    "close",
    "meets",
  ];

  if (targets) {
    const {
      returnOnEquity: returnOnEquityTarget,
      returnOnAssets: returnOnAssetsTarget,
    } = targets;

    if (returnOnEquity) {
      if (returnOnEquity.gt(returnOnEquityTarget)) {
        returnOnEquityPerformance = "meets";
      } else if (returnOnEquity.gt(returnOnEquityTarget.mul(0.8))) {
        returnOnEquityPerformance = "close";
      } else {
        returnOnEquityPerformance = "poor";
      }
    }

    if (returnOnAssets) {
      if (returnOnAssets.gt(returnOnAssetsTarget)) {
        returnOnAssetsPerformance = "meets";
      } else if (returnOnAssets.gt(returnOnAssetsTarget.mul(0.8))) {
        returnOnAssetsPerformance = "close";
      } else {
        returnOnAssetsPerformance = "poor";
      }
    }
  }

  const aggregatePerformance =
    performanceIndices[
      Math.min(
        indexOf(returnOnEquityPerformance, performanceIndices),
        indexOf(returnOnAssetsPerformance, performanceIndices),
      )
    ];

  return {
    aggregatePerformance,
    returnOnEquityPerformance,
    returnOnAssetsPerformance,
  };
};
