import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  all,
  ascend,
  dropWhile,
  filter,
  forEach,
  groupBy,
  head,
  includes,
  indexOf,
  last,
  map,
  mapObjIndexed,
  pipe,
  reverse,
  sortBy,
  sortWith,
  tail,
  takeWhile,
  toPairs,
  uniq,
  unnest,
} from "ramda";
import Decimal from "decimal.js-light";

import { TreasuryServiceModel } from "@pricing-tool/lib/products/treasury/model/TreasuryServiceModel";

import { Modifier } from "../../../../common/Input";
import ProductFamilyRowGroup from "./ProductFamilyRowGroup";
import GroupSelectIcon from "./GroupSelectIcon";
import { TreasuryServiceRowRef } from "./TreasuryServiceRow/component";

import "./styles.scss";

type TreasuryServicesTableProps = {
  treasuryServiceModels: TreasuryServiceModel[] | undefined;
  onTreasuryServiceDelete: (treasuryServiceModel: TreasuryServiceModel) => void;
};

const groupingFunction = (model: TreasuryServiceModel) =>
  model.input.element.productFamily;

const sortCriteria = [
  ascend((model: TreasuryServiceModel) => model.input.element.productFamily),
  ascend((model: TreasuryServiceModel) => model.input.element.afpCode),
  ascend((model: TreasuryServiceModel) => model.input.element.id),
];

const TreasuryServicesTable = ({
  treasuryServiceModels,
  onTreasuryServiceDelete,
}: TreasuryServicesTableProps) => {
  const groupedTreasuryServiceModels: {
    [key in string]: TreasuryServiceModel[];
  } = useMemo(() => {
    const grouped = treasuryServiceModels
      ? groupBy(groupingFunction, treasuryServiceModels)
      : {};
    return mapObjIndexed(sortWith<TreasuryServiceModel>(sortCriteria), grouped);
  }, [treasuryServiceModels]);

  const sortedTreasuryServiceModels = useMemo(() => {
    const pairs = toPairs(groupedTreasuryServiceModels);
    const sortedPairs = sortBy(
      ([productFamily, models]) => productFamily,
      pairs,
    );
    const values = map(([_, values]) => values, sortedPairs);
    return unnest(values);
  }, [groupedTreasuryServiceModels]);

  const allSortedKeys = useMemo(() => {
    return map((model) => model.input.element.id, sortedTreasuryServiceModels);
  }, [sortedTreasuryServiceModels]);

  const getKeysBetween = useCallback(
    (firstKey, lastKey) => {
      if (firstKey === lastKey) return [];

      let keys = map(
        (model) => model.input.element.id,
        sortedTreasuryServiceModels,
      );
      if (indexOf(lastKey, keys) < indexOf(firstKey, keys))
        keys = reverse(keys);

      const keysBetweenExclLast = pipe(
        dropWhile((k) => k !== firstKey),
        tail,
        takeWhile((k) => k !== lastKey),
      )(keys);

      return [...keysBetweenExclLast, lastKey];
    },
    [sortedTreasuryServiceModels],
  );

  const [selectedColumn, setSelectedColumn] = useState<string>();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [activeInput, setActiveInput] = useState<HTMLInputElement>();
  const [activeKey, setActiveKey] = useState<string>();
  const [keySelections, setKeySelections] = useState<string[]>([]);
  const firstRowRef = useRef<TreasuryServiceRowRef>(null);

  // FIXME - small hack to make sure the firstRowRef registers after first render or changes to the model
  const [shouldUpdate, setShouldUpdate] = useState(true);
  useEffect(() => {
    setShouldUpdate(!shouldUpdate);
  }, [treasuryServiceModels]); // eslint-disable-line react-hooks/exhaustive-deps
  // /FIXME

  const onInputSelect = useCallback(
    (
      key: string,
      column: string,
      modifier: Modifier,
      input: HTMLInputElement,
    ) => {
      if (modifier === "none" || !selectedColumn || selectedColumn !== column) {
        input.focus();
        setSelectedColumn(column);
        setSelectedKeys([key]);
        setActiveInput(input);
        setActiveKey(key);
        setKeySelections([key]);
        return;
      }

      if (modifier === "command") {
        if (includes(key, selectedKeys)) {
          if (input !== activeInput) {
            setSelectedKeys(filter((k) => k !== key, selectedKeys));
            setKeySelections(filter((k) => k !== key, keySelections));
          }
        } else {
          setSelectedKeys(uniq([...selectedKeys, key]));
          setKeySelections([key, ...keySelections]);
        }
        return;
      }

      // TODO - figure out if deselection makes sense with shift
      if (modifier === "shift") {
        let keysBetween = getKeysBetween(head(keySelections), key);
        setSelectedKeys(uniq([...selectedKeys, ...keysBetween]));
        setKeySelections([...reverse(keysBetween), ...keySelections]);
      }
    },
    [selectedColumn, selectedKeys, activeInput, keySelections, getKeysBetween],
  );

  const onGroupSelect = useCallback(
    (
      keys: string[],
      column: string,
      modifier: Modifier,
      input: HTMLInputElement,
      newActiveKey: string,
    ) => {
      if (modifier === "none" || !selectedColumn || selectedColumn !== column) {
        input.focus();
        setSelectedColumn(column);
        setSelectedKeys(keys);
        setActiveInput(input);
        setActiveKey(newActiveKey);
        setKeySelections(reverse(keys));
        return;
      }

      if (modifier === "command") {
        // if all the keys are already selected
        if (all((key: string) => includes(key, selectedKeys), keys)) {
          // deselect all but the active key
          setSelectedKeys(
            filter((k) => !includes(k, keys) && k !== activeKey, selectedKeys),
          );
          setKeySelections(
            filter((k) => !includes(k, keys) && k !== activeKey, keySelections),
          );
        } else {
          // select all of them
          setSelectedKeys(uniq([...selectedKeys, ...keys]));
          setKeySelections([...reverse(keys), ...keySelections]);
        }
        return;
      }

      // TODO - figure out if deselection makes sense with shift
      if (modifier === "shift") {
        let keysBetween = getKeysBetween(head(keySelections), last(keys));
        setSelectedKeys(uniq([...selectedKeys, ...keysBetween]));
        setKeySelections([...reverse(keysBetween), ...keySelections]);
      }
    },
    [selectedColumn, selectedKeys, activeKey, keySelections, getKeysBetween],
  );

  const onInputDeselect = useCallback((input: HTMLInputElement) => {
    setSelectedColumn(undefined);
    setSelectedKeys([]);
    setActiveInput(undefined);
    setActiveKey(undefined);
    setKeySelections([]);
  }, []);

  const onInputChange = useCallback(
    (value: Decimal | number) => {
      pipe(
        filter<TreasuryServiceModel>((treasuryServiceModel) =>
          includes(treasuryServiceModel.input.element.id, selectedKeys),
        ),
        forEach<TreasuryServiceModel>((treasuryServiceModel) => {
          if (selectedColumn === "discount") {
            treasuryServiceModel.input.discountInput.next(value as Decimal);
          } else if (selectedColumn === "price") {
            treasuryServiceModel.input.priceInput.next(value as Decimal);
          } else if (selectedColumn === "volume") {
            treasuryServiceModel.input.monthlyVolumeInput.next(value as number);
          }
        }),
      )(treasuryServiceModels);
    },
    [selectedColumn, selectedKeys, treasuryServiceModels],
  );

  const _onGroupSelect = useCallback(
    (column: string) => (modifier: Modifier) => {
      if (!firstRowRef.current) return;
      if (!firstRowRef.current.discount) return;
      if (!firstRowRef.current.volume) return;
      if (!firstRowRef.current.price) return;

      let input: HTMLInputElement = firstRowRef.current.discount;
      if (column === "discount") input = firstRowRef.current.discount;
      if (column === "price") input = firstRowRef.current.price;
      if (column === "volume") input = firstRowRef.current.volume;

      onGroupSelect(allSortedKeys, column, modifier, input, allSortedKeys[0]);
    },
    [onGroupSelect, allSortedKeys],
  );

  const groupedTreasuryServiceModelsPairs = toPairs(
    groupedTreasuryServiceModels,
  );
  const sortedGroupedTreasuryServiceModelsPairs = sortBy(
    ([productFamily, models]) => productFamily,
    groupedTreasuryServiceModelsPairs,
  );
  const [firstPair, ...restOfPairs] = sortedGroupedTreasuryServiceModelsPairs;

  return (
    <table className="treasury-services-table">
      <thead>
        <tr>
          <th>Element</th>
          <th>AFP Code</th>
          <th>Service Description</th>
          <th>
            Discount{" "}
            <GroupSelectIcon onGroupSelect={_onGroupSelect("discount")} />
          </th>
          <th>
            Volume <GroupSelectIcon onGroupSelect={_onGroupSelect("volume")} />
          </th>
          <th>
            Price <GroupSelectIcon onGroupSelect={_onGroupSelect("price")} />
          </th>
          <th className="right">Revenue</th>
          <th className="right">Profit</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      {firstPair && (
        <ProductFamilyRowGroup
          ref={firstRowRef}
          key={firstPair[0]}
          productFamily={firstPair[0]}
          treasuryServiceModels={firstPair[1]}
          selectedColumn={selectedColumn}
          selectedKeys={selectedKeys}
          onTreasuryServiceDelete={onTreasuryServiceDelete}
          onInputSelect={onInputSelect}
          onInputDeselect={onInputDeselect}
          onInputChange={onInputChange}
          onGroupSelect={onGroupSelect}
        />
      )}
      {map(
        ([productFamily, models]) => (
          <ProductFamilyRowGroup
            key={productFamily}
            productFamily={productFamily}
            treasuryServiceModels={models}
            selectedColumn={selectedColumn}
            selectedKeys={selectedKeys}
            onTreasuryServiceDelete={onTreasuryServiceDelete}
            onInputSelect={onInputSelect}
            onInputDeselect={onInputDeselect}
            onInputChange={onInputChange}
            onGroupSelect={onGroupSelect}
          />
        ),
        restOfPairs,
      )}
    </table>
  );
};

export default TreasuryServicesTable;
