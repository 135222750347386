import { v4 as uuidv4 } from "uuid";
import {
  BehaviorSubject,
  combineLatest,
  defaultIfEmpty,
  map,
  mergeMap,
  Observable,
  of,
  share,
  tap,
} from "rxjs";
import * as R from "ramda";
import { match } from "ts-pattern";

import {
  fromTreasury,
  TreasuryModel,
  asObservable as asTreasuryObservable,
} from "../products/treasury/model/TreasuryModel";
import { TreasuryDependencies } from "../products/treasury/core";

import {
  CommercialIndustrialModel,
  fromCommercialIndustrial,
  asObservable as asCommercialIndustrialObservable,
} from "../products/ci/model/CommercialIndustrialModel";
import { CommercialIndustrialDependencies } from "../products/ci/core";

import {
  DepositsModel,
  fromDeposits,
  asObservable as asDepositsObservable,
} from "../products/deposits/model";
import { DepositsDependencies } from "../products/deposits/core";

import {
  CommercialRealEstateModel,
  asObservable as asCommercialRealEstateObservable,
  fromCommercialRealEstate,
} from "../products/cre/model";

import { Scenario } from "./core";

import {
  combine,
  IncomeStatementOutput,
  asObservable as asIncomeStatementObservable,
} from "../incomeStatement";

import anyChanged from "../util/operators/anyChanged";
import fieldChanged from "../util/operators/fieldChanged";
import arrayFieldChanged from "../util/operators/arrayFieldChanged";
import { Product } from "../products/core";

export type ProductModel =
  | TreasuryModel
  | CommercialIndustrialModel
  | DepositsModel
  | CommercialRealEstateModel;

export type ScenarioDependencies = Partial<
  TreasuryDependencies & CommercialIndustrialDependencies & DepositsDependencies
>;

export type ScenarioInput = {
  id: string;
  name: BehaviorSubject<string>;
  sortOrder: BehaviorSubject<number>;
  products: BehaviorSubject<ProductModel[]>;
};

export type ScenarioOutput = {
  incomeStatementOutput: Observable<IncomeStatementOutput>;
};

export type ScenarioModel = {
  isDirty: Observable<boolean>;
  input: ScenarioInput;
  output: ScenarioOutput;
};

const trackChanges = (input: ScenarioInput): Observable<boolean> =>
  anyChanged([
    fieldChanged(input.name),
    fieldChanged(input.sortOrder),
    arrayFieldChanged(input.products, (product) => product._type),
  ]);

export const init = (
  name: string,
  dependencies: ScenarioDependencies,
  sortOrder: number = 0,
): ScenarioModel => {
  const model = fromScenario(
    { id: uuidv4(), name, sortOrder, products: [] },
    dependencies,
  );
  return {
    ...model,
    isDirty: of(true),
  };
};

export const fromScenario = (
  scenario: Scenario,
  dependencies: ScenarioDependencies,
): ScenarioModel => {
  const { id, name, sortOrder, products } = scenario;

  const productModels = R.map(
    (product: Product) =>
      match(product)
        .with({ _type: "treasury" }, (res) =>
          fromTreasury(res, dependencies as TreasuryDependencies),
        )
        .with({ _type: "ci" }, (res) =>
          fromCommercialIndustrial(
            res,
            dependencies as CommercialIndustrialDependencies,
          ),
        )
        .with({ _type: "deposits" }, (res) =>
          fromDeposits(res, dependencies as DepositsDependencies),
        )
        .with({ _type: "cre" }, (res) =>
          fromCommercialRealEstate(
            res,
            dependencies as CommercialIndustrialDependencies,
          ),
        )
        .exhaustive(),
    products || [],
  );

  const input: ScenarioInput = {
    id: id === "" ? uuidv4() : id,
    name: new BehaviorSubject<string>(name),
    sortOrder: new BehaviorSubject<number>(sortOrder),
    products: new BehaviorSubject<ProductModel[]>(productModels),
  };

  const output = calculate(input, dependencies);
  const isDirty = trackChanges(input);

  return {
    isDirty,
    input,
    output,
  };
};

const calculate = (
  input: ScenarioInput,
  dependencies: ScenarioDependencies,
): ScenarioOutput => {
  const { products } = input;

  const incomeStatementOutput = products.pipe(
    map((productModels) => {
      const incomeStatementOutputs = R.map(
        (model) => model.output.incomeStatementOutput,
        productModels,
      );
      return combine(incomeStatementOutputs);
    }),
  );

  return {
    incomeStatementOutput,
  };
};

export const asObservable = (model: ScenarioModel): Observable<Scenario> => {
  const { id, name, sortOrder, products } = model.input;
  const { incomeStatementOutput } = model.output;

  const incomeStatement = incomeStatementOutput.pipe(
    mergeMap((output) => asIncomeStatementObservable(output)),
  );

  const products$ = products.pipe(
    mergeMap((productModels) => {
      const mapped = R.map(
        (model) =>
          match(model)
            .with(
              { _type: "treasury" },
              (res) => asTreasuryObservable(res) as Observable<Product>,
            )
            .with(
              { _type: "ci" },
              (res) =>
                asCommercialIndustrialObservable(res) as Observable<Product>,
            )
            .with(
              { _type: "deposits" },
              (res) => asDepositsObservable(res) as Observable<Product>,
            )
            .with(
              { _type: "cre" },
              (res) =>
                asCommercialRealEstateObservable(res) as Observable<Product>,
            )
            .exhaustive(),
        productModels,
      );

      return defaultIfEmpty<Product[], Product[]>([])(combineLatest(mapped));
    }),
  );

  products$.pipe(tap((value) => console.log("latest products", value)));

  return combineLatest([
    model.isDirty,
    name,
    sortOrder,
    products$,
    incomeStatement,
  ]).pipe(
    map(([isDirty, name, sortOrder, products, incomeStatement]) => ({
      id,
      name,
      sortOrder,
      products,
      incomeStatement,
      isDirty,
    })),
    share(),
  );
};
