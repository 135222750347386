import React from "react";
import { library, IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faPrint,
  faDownload,
  faCube,
  faCubes,
  faSlidersH,
  faEllipsisV,
  faSearch,
  faTimes,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faDollarSign,
  faPercent,
  faMinusSquare,
  faPenSquare,
  faEdit,
  faUser,
  faUserFriends,
  faPlus,
  faExclamationTriangle,
  faCheck,
  faStar as faSolidStar,
  faBatteryQuarter,
  faBatteryThreeQuarters,
  faBatteryFull,
  faBatteryEmpty,
  faMinus,
  faPaperPlane,
  faAngleDoubleRight,
  faFile,
  faFileWord,
  faFileExcel,
  faFileArchive,
  faFileCsv,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFileAlt,
  faShareSquare,
  faSave,
  faDotCircle,
  faTrashAlt,
  faCopy,
  faThumbsUp,
  faThumbsDown,
  faMeh,
  faStar,
  faCommentAlt,
  faFile as farFile,
} from "@fortawesome/free-regular-svg-icons";

import { Amplify, Auth } from "aws-amplify";
import config from "./aws-config";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

// @ts-ignore
import toformat from "toformat";
import Decimal from "decimal.js-light";

import "./App.scss";
import { Opportunity as OpportunityType } from "../../lib/opportunity/core";
import { Element, Package } from "../../lib/products/treasury/core";
import { OpportunityModel } from "../../lib/opportunity/model";
import { ScenarioDependencies } from "../../lib/scenario/model";
import { useApolloClient } from "./hooks/useApolloClient";
import { ApolloProvider } from "@apollo/client";
import Router from "./router";
import { Index } from "@pricing-tool/lib/products/ci/core/IndexRates";

const awsconfig = {
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
};

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

toformat(Decimal);

declare module "decimal.js-light" {
  interface Decimal {
    toFormat: (dp: number) => string;
  }
}

library.add(
  faFileAlt as IconDefinition,
  faPrint as IconDefinition,
  faDownload as IconDefinition,
  faCube as IconDefinition,
  faCubes as IconDefinition,
  faShareSquare as IconDefinition,
  faSave as IconDefinition,
  faSlidersH as IconDefinition,
  faEllipsisV as IconDefinition,
  faSearch as IconDefinition,
  faTimes as IconDefinition,
  faChevronLeft as IconDefinition,
  faChevronRight as IconDefinition,
  faChevronDown as IconDefinition,
  faDollarSign as IconDefinition,
  faPercent as IconDefinition,
  faMinusSquare as IconDefinition,
  faPenSquare as IconDefinition,
  faEdit as IconDefinition,
  faUser as IconDefinition,
  faUserFriends as IconDefinition,
  faPlus as IconDefinition,
  faThumbsUp as IconDefinition,
  faThumbsDown as IconDefinition,
  faMeh as IconDefinition,
  faDotCircle as IconDefinition,
  faTrashAlt as IconDefinition,
  faCopy as IconDefinition,
  faExclamationTriangle as IconDefinition,
  faCheck as IconDefinition,
  faStar as IconDefinition,
  faSolidStar as IconDefinition,
  faBatteryQuarter as IconDefinition,
  faBatteryThreeQuarters as IconDefinition,
  faBatteryFull as IconDefinition,
  faBatteryEmpty as IconDefinition,
  faMinus as IconDefinition,
  faPaperPlane as IconDefinition,
  faCommentAlt as IconDefinition,
  faAngleDoubleRight as IconDefinition,
  faFile as IconDefinition,
  faFileWord as IconDefinition,
  faFileExcel as IconDefinition,
  faFileArchive as IconDefinition,
  faFileCsv as IconDefinition,
  faFileImage as IconDefinition,
  faFilePdf as IconDefinition,
  faFilePowerpoint as IconDefinition,
  farFile as IconDefinition,
  faCog as IconDefinition,
);

export type AppProps = {
  opportunity?: OpportunityType;
  opportunityModel?: OpportunityModel;
  dependencies?: ScenarioDependencies;
  elementCatalog?: Element[];
  packageCatalog?: Package[];
  indexes?: Index[];
};

function App(props: AppProps) {
  const { client } = useApolloClient();

  if (!client) {
    return null;
  } else {
    return (
      <div className="App">
        <Authenticator variation={"modal"} hideSignUp={true}>
          {({ signOut, user }) => (
            <ApolloProvider client={client}>
              <Router user={user} signOut={signOut!} />
            </ApolloProvider>
          )}
        </Authenticator>
      </div>
    );
  }
}

export default App;
