import React, { useEffect, useState } from "react";
import { useObservableState } from "observable-hooks";

import {
  asObservable,
  CollateralModel,
} from "@pricing-tool/lib/products/ci/model/CollateralModel";
import { CollateralRecoveryTable } from "@pricing-tool/lib/products/ci/core/CollateralRecoveryTable";

import CurrencyAmount from "../../../common/CurrencyAmount";
import PercentAmount from "../../../common/PercentAmount";
import CollateralRow from "./CollateralRow";

import "./styles.scss";

export type CollateralTableProps = {
  collateralModel: CollateralModel;
  collateralRecoveryTable: CollateralRecoveryTable;
  onRemoveCollateralItem: (index: number) => void;
};

const CollateralTable = ({
  collateralModel,
  collateralRecoveryTable,
  onRemoveCollateralItem,
}: CollateralTableProps) => {
  const collateral$ = asObservable(collateralModel);
  const [collateral] = useObservableState(() => collateral$);

  const [collateralItemModels] = useObservableState(
    () => collateralModel.input.collateralItems,
  );

  // FIXME - small hack to make sure the rows rerender
  const [updateIndex, setUpdateIndex] = useState(1);
  useEffect(() => {
    setUpdateIndex(updateIndex + 1);
  }, [collateralItemModels]); // eslint-disable-line react-hooks/exhaustive-deps
  // /FIXME

  if (!collateral) return <></>;

  const firstRow = (
    <tr key={"first-collateral"}>
      <td colSpan={2}>&nbsp;</td>
      <td>
        <CurrencyAmount amount={collateral.value} decimalPlaces={0} />
      </td>
      <td>
        <CurrencyAmount amount={collateral.priorLiens} decimalPlaces={0} />
      </td>
      <td>
        <PercentAmount amount={collateral.combinedLoanToValueRatio} />
      </td>
      <td>
        <CurrencyAmount amount={collateral.effectiveValue} />
      </td>
      <td>
        <PercentAmount amount={collateral.effectiveLoanToValueRatio} />
      </td>
      <td>&nbsp;</td>
    </tr>
  );

  const collateralRows: JSX.Element[] = (collateralItemModels || []).map(
    (collateralItemModel, index) => (
      <CollateralRow
        key={`collateral-item-${updateIndex}-${index}`}
        collateralItemModel={collateralItemModel}
        collateralRecoveryTable={collateralRecoveryTable}
        onDelete={() => onRemoveCollateralItem(index)}
      />
    ),
  );

  return (
    <table className="collateral-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Collateral Type</th>
          <th>Value</th>
          <th>Prior Liens</th>
          <th>Combined LTV</th>
          <th>Effective Value</th>
          <th>Effective LTV</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>{[firstRow, ...collateralRows]}</tbody>
    </table>
  );
};

export default CollateralTable;
