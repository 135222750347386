import {
  BehaviorSubject,
  combineLatest,
  defaultIfEmpty,
  map,
  mergeMap,
  Observable,
  share,
  tap,
} from "rxjs";
import * as R from "ramda";

import { Opportunity, OpportunityStatus } from "./core";
import {
  fromScenario,
  ScenarioDependencies,
  ScenarioModel,
  asObservable as asScenarioObservable,
} from "../scenario/model";
import { Scenario } from "../scenario/core";
import { Client } from "../client/core";
import { sortBy } from "ramda";
import anyChanged from "../util/operators/anyChanged";
import fieldChanged from "../util/operators/fieldChanged";
import arrayFieldChanged from "../util/operators/arrayFieldChanged";
import {
  asObservable as asIncomeStatementObservable,
  IncomeStatement,
  IncomeStatementOutput,
} from "../incomeStatement";

export type OpportunityInput = {
  id: string;
  branchId: string;
  name: BehaviorSubject<string>;
  clientId: BehaviorSubject<string>;
  client: BehaviorSubject<Client | undefined>;
  owner: BehaviorSubject<string | undefined>;
  status: BehaviorSubject<OpportunityStatus>;
  pricingDate: BehaviorSubject<string | undefined>;
  closeDate: BehaviorSubject<string | undefined>;
  scenarios: BehaviorSubject<ScenarioModel[]>;
  pipelineScenarioId: BehaviorSubject<string | undefined>;
};

export type OpportunityOutput = {
  incomeStatement: Observable<Observable<IncomeStatementOutput | undefined>>;
};

export type OpportunityModel = {
  isDirty: Observable<boolean>;
  input: OpportunityInput;
  output: OpportunityOutput;
};

const trackChanges = (input: OpportunityInput): Observable<boolean> =>
  anyChanged([
    fieldChanged(input.name),
    fieldChanged(input.clientId),
    fieldChanged(input.owner),
    fieldChanged(input.status),
    fieldChanged(input.pricingDate),
    fieldChanged(input.closeDate),
    fieldChanged(input.pipelineScenarioId),
    arrayFieldChanged(input.scenarios, (scenario) => scenario.input.id),
  ]).pipe(
    tap((val) => {
      if (val) {
        console.log("Opportunity changed");
      }
    }),
  );

export const fromOpportunity = (
  opportunity: Opportunity,
  dependencies: ScenarioDependencies,
): OpportunityModel => {
  const {
    id,
    name,
    branchId,
    clientId,
    client,
    owner,
    status,
    pricingDate,
    closeDate,
    scenarios,
    pipelineScenarioId,
  } = opportunity;

  const scenarioModels = R.map(
    (scenario: Scenario) => fromScenario(scenario, dependencies),
    scenarios,
  );

  const input: OpportunityInput = {
    id,
    branchId,
    name: new BehaviorSubject(name),
    clientId: new BehaviorSubject(clientId),
    client: new BehaviorSubject(client),
    owner: new BehaviorSubject(owner),
    status: new BehaviorSubject(status),
    pricingDate: new BehaviorSubject(pricingDate),
    closeDate: new BehaviorSubject(closeDate),
    scenarios: new BehaviorSubject<ScenarioModel[]>(scenarioModels),
    pipelineScenarioId: new BehaviorSubject(pipelineScenarioId),
  };

  const output = calculate(input, dependencies);
  const isDirty = trackChanges(input);

  return {
    isDirty,
    input,
    output,
  };
};

const calculate = (
  input: OpportunityInput,
  dependencies: ScenarioDependencies,
): OpportunityOutput => {
  const { scenarios, pipelineScenarioId } = input;

  const incomeStatement = combineLatest([scenarios, pipelineScenarioId]).pipe(
    map(([scenarios, pipelineScenarioId]) => {
      if (!pipelineScenarioId)
        return new BehaviorSubject<IncomeStatementOutput | undefined>(
          undefined,
        );

      const pipelineScenario = scenarios.find(
        (scenario) => scenario.input.id === pipelineScenarioId,
      );
      if (!pipelineScenario) {
        return new BehaviorSubject<IncomeStatementOutput | undefined>(
          undefined,
        );
      }
      return pipelineScenario.output.incomeStatementOutput;
    }),
  );

  return {
    incomeStatement,
  };
};

export const asObservable = (
  model: OpportunityModel,
): Observable<Opportunity> => {
  const {
    id,
    branchId,
    name: name$,
    clientId: clientId$,
    client: client$,
    owner: owner$,
    status: status$,
    pricingDate: pricingDate$,
    closeDate: closeDate$,
    scenarios,
    pipelineScenarioId: pipelineScenarioId$,
  } = model.input;

  const { incomeStatement } = model.output;

  const scenarios$ = scenarios.pipe(
    mergeMap((scenarioModels) => {
      const mapped = R.map(
        (model) => asScenarioObservable(model),
        scenarioModels,
      );

      return defaultIfEmpty<Scenario[], Scenario[]>([])(combineLatest(mapped));
    }),
    map((scenarios: Scenario[]) =>
      sortBy((scenario) => scenario.sortOrder, scenarios),
    ),
  );

  scenarios$.pipe(tap((value) => console.log("latest scenarios", value)));

  const incomeStatement$ = incomeStatement.pipe(
    mergeMap((output) => output),
    mergeMap((output) =>
      output
        ? asIncomeStatementObservable(output)
        : new BehaviorSubject<IncomeStatement | undefined>(undefined),
    ),
  );

  return combineLatest([
    model.isDirty,
    name$,
    clientId$,
    client$,
    owner$,
    status$,
    pricingDate$,
    closeDate$,
    scenarios$,
    pipelineScenarioId$,
    incomeStatement$,
  ]).pipe(
    map(
      ([
        isDirty,
        name,
        clientId,
        client,
        owner,
        status,
        pricingDate,
        closeDate,
        scenarios,
        pipelineScenarioId,
        incomeStatement,
      ]) => ({
        id,
        branchId,
        name,
        clientId,
        client,
        owner,
        status,
        pricingDate,
        closeDate,
        scenarios,
        pipelineScenarioId,
        isDirty,
        incomeStatement,
      }),
    ),
    share(),
  );
};
