import * as R from "ramda";
import { map, Observable } from "rxjs";

type DefinedArray<T extends any[]> = T extends [infer U, ...infer Rest]
  ? U extends undefined | null
    ? [...DefinedArray<Rest>]
    : [NonNullable<U>, ...DefinedArray<Rest>]
  : [];

const makeDefined = <T extends unknown | undefined | null>(arg: T) => arg!;

const mapOnlyDefined =
  <TSource extends unknown[], TTarget>(
    mapFn: (args: DefinedArray<TSource>) => TTarget,
  ) =>
  (source: Observable<TSource>) => {
    return source.pipe(
      map((args) => {
        const definedArgs = R.map(
          makeDefined,
          R.filter(R.pipe(R.isNil, R.not), args),
        ) as DefinedArray<TSource>;
        return mapFn(definedArgs);
      }),
    );
  };

export default mapOnlyDefined;
