import React from "react";
import { map, sortBy } from "ramda";
import moment from "moment";

import { TemplateSummaryFragment } from "@pricing-tool/graphql/lib/react";

import "./styles.scss";

export type TemplatesTableProps = {
  templates: TemplateSummaryFragment[];
  onSelect: (template: TemplateSummaryFragment) => void;
};

const TemplatesTable = ({ templates, onSelect }: TemplatesTableProps) => {
  if (!templates || templates.length === 0) {
    return <div className="p-4">No templates</div>;
  }

  const sortedTemplates = sortBy((t) => t?.name || 1, templates);

  return (
    <table className="component templates-table">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Description</th>
          <th scope="col">Last Updated At</th>
          <th scope="col">Last Updated By</th>
        </tr>
      </thead>
      <tbody>
        {map((template) => {
          return (
            <tr key={template.id} onClick={() => onSelect(template)}>
              <td>{template.name}</td>
              <td>{template.description}</td>
              <td>{moment(template.updatedAt).format("LLL")}</td>
              <td>{template.updatedBy}</td>
            </tr>
          );
        }, sortedTemplates)}
      </tbody>
    </table>
  );
};

export default TemplatesTable;
