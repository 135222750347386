import React from "react";

import Modal from "../../../common/Modal";
import AddPackagesForm from "../../forms/AddPackagesForm";
import { AddPackagesModalProps } from "./props";

const AddPackagesModal = (props: AddPackagesModalProps) => {
  return (
    <Modal
      title="Add Packages"
      width={800}
      contentHeight={400}
      onClose={props.onCancel}
    >
      <AddPackagesForm
        packages={props.packages}
        addedElementIds={props.addedElementIds}
        onConfirm={props.onConfirm}
        onCancel={props.onCancel}
      />
    </Modal>
  );
};

export default AddPackagesModal;
