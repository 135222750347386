import {
  BehaviorSubject,
  combineLatest,
  map,
  Observable,
  share,
  tap,
} from "rxjs";
import Decimal from "decimal.js-light";
import { CommercialIndustrialDependencies, Interest } from "../core";
import { compose, isNil, not } from "ramda";
import anyChanged from "../../../util/operators/anyChanged";
import fieldChanged from "../../../util/operators/fieldChanged";
import { Index, IndexRatesSnapshot } from "../core/IndexRates";

export type InterestRateInput = {
  index: BehaviorSubject<Index | undefined>;
  spread: BehaviorSubject<Decimal | undefined>;
};

export type InterestRateOutput = {
  interestRate: Observable<Decimal | undefined>;
};

export type InterestRateModel = {
  input: InterestRateInput;
  output: InterestRateOutput;
};

const isDefined = compose(not, isNil);

export const trackChanges = (input: InterestRateInput): Observable<boolean> =>
  anyChanged([fieldChanged(input.spread), fieldChanged(input.index)]);

export const calculate = (
  input: InterestRateInput,
  indexRatesSnapshot: Observable<IndexRatesSnapshot | undefined>,
  dependencies: Pick<
    CommercialIndustrialDependencies,
    "forwardCurve" | "startDate"
  >,
): InterestRateOutput => {
  const { spread, index } = input;

  const rate = combineLatest([index, indexRatesSnapshot]).pipe(
    map(([index, indexRatesSnapshot]) =>
      isDefined(index) && isDefined(indexRatesSnapshot)
        ? getRateFromSnapshot(indexRatesSnapshot!, index!)
        : undefined,
    ),
    // tap((rate) => console.log("Index Rate", rate?.toFormat(5))),
  );

  const interestRate = combineLatest([spread, rate]).pipe(
    map(([spread, rate]) => spread && rate && spread.plus(rate)),
    // tap((interestRate) =>
    //   console.log("Interest Rate", interestRate?.toFormat(5)),
    // ),
  );

  return {
    interestRate,
  };
};

export const getRateFromSnapshot = (
  indexRatesSnapshot: IndexRatesSnapshot,
  index: Index,
): Decimal | undefined => {
  const { indexRates } = indexRatesSnapshot;
  const rate = indexRates.find((r) => r.index === index)?.rate;
  return rate?.dividedBy(100);
};

export const fromInterest = (
  interest: Interest | undefined,
  indexRatesSnapshot: Observable<IndexRatesSnapshot | undefined>,
  dependencies: Pick<
    CommercialIndustrialDependencies,
    "forwardCurve" | "startDate"
  >,
): InterestRateModel => {
  const input: InterestRateInput = {
    spread: new BehaviorSubject<Decimal | undefined>(interest?.spread),
    index: new BehaviorSubject(interest?.index),
  };

  const output = calculate(input, indexRatesSnapshot, dependencies);

  return {
    input,
    output,
  };
};

export const asObservable = (
  model: InterestRateModel,
): Observable<Interest> => {
  const { spread, index } = model.input;
  const { interestRate } = model.output;

  return combineLatest([interestRate, index, spread]).pipe(
    map(([interestRate, index, spread]) => ({ interestRate, index, spread })),
    share(),
  );
};
