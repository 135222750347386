import React from "react";

import "./styles.scss";

export type LoadingIndicatorProps = {
  size: "sm" | "md" | "lg";
};

const LoadingIndicator = ({ size }: LoadingIndicatorProps) => {
  return (
    <div
      className={`loading-animation ${
        size === "sm" ? "h-4 w-4" : size === "md" ? "h-8 w-8" : "h-12 w-12"
      }`}
    ></div>
  );
};

export default LoadingIndicator;
