import React, { useState } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import Decimal from "decimal.js-light";

import { useGetOpportunitiesByStatusQuery } from "@pricing-tool/graphql/lib/react";

import { OpportunitySummary } from "@pricing-tool/lib/opportunitySummary/core";
import { fromDto } from "@pricing-tool/lib/opportunitySummary/dto";

import OpportunitiesTable from "../components/opportunity/tables/OpportunitiesTable";

// FIXME - don't hardcode
const performanceTargets = {
  returnOnEquity: new Decimal("0.1500"),
  returnOnAssets: new Decimal("0.0100"),
};

function HomePage() {
  const [displayResults, setDisplayResults] = useState<
    OpportunitySummary[] | undefined
  >(undefined);

  const { loading, error } = useGetOpportunitiesByStatusQuery({
    variables: {
      branchId: "branch-1",
      status: "IN_PROGRESS",
      paginationOptions: {
        limit: 100,
      },
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setDisplayResults(data.getOpportunitiesByStatus.items.map(fromDto));
    },
  });

  return (
    <div className="page home">
      <Helmet>
        <title>Pipeline</title>
      </Helmet>
      <div className="text-2xl mb-4">Pipeline</div>

      <div className="my-3 underline decoration-4 decoration-blue-200">
        <Link to="/opportunities/new">Create a new opportunity</Link>
      </div>

      {error && <div>Error: {error.message}</div>}
      {loading && <div>Loading...</div>}

      {displayResults && (
        <OpportunitiesTable
          opportunitySummaries={displayResults}
          performanceTargets={performanceTargets}
        />
      )}
    </div>
  );
}

export default HomePage;
