import { Schema as S } from "@effect/schema";

import { CommercialRealEstate, CommercialRealEstateSchema } from "./index";

export type CommercialRealEstateDto = S.Schema.Encoded<
  typeof CommercialRealEstateSchema
>;

export const fromDto = (dto: CommercialRealEstateDto): CommercialRealEstate => {
  return S.decodeUnknownSync(CommercialRealEstateSchema)(dto);
};

export const toDto = (cre: CommercialRealEstate): CommercialRealEstateDto => {
  return S.encodeSync(CommercialRealEstateSchema)(cre);
};
