import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { map } from "ramda";

import DollarInput from "../../../../common/DollarInput";
import PercentAmount from "../../../../common/PercentAmount";
import CurrencyAmount from "../../../../common/CurrencyAmount";
import InputWithIcon from "../../../../common/InputWithIcon";

import { CollateralRowProps } from "./props";
import "./styles.scss";

const CollateralRow = ({
  collateralItem,
  collateralRecoveryTable,
  onNameChange,
  onCollateralTypeChange,
  onValueChange,
  onPriorLiensChange,
  onDelete,
}: CollateralRowProps) => {
  return (
    <tr className="collateral-row">
      <td className="field w-32">
        <InputWithIcon
          value={collateralItem.name || ""}
          onChange={onNameChange}
        />
      </td>
      <td className="field whitespace-nowrap w-44">
        <select
          className="rounded-md shadow-sm p-2 w-full"
          value={collateralItem.collateralType?.id || "none"}
          onChange={(event) =>
            onCollateralTypeChange(
              event.target.value === "none" ? undefined : event.target.value,
            )
          }
        >
          <option value="none">-</option>
          {map(
            (collateralType) => (
              <option
                key={`collateral-type-${collateralType.id}`}
                value={collateralType.id}
              >
                {collateralType.type}
              </option>
            ),
            collateralRecoveryTable,
          )}
        </select>
      </td>
      <td className="field w-40">
        <DollarInput
          decimalPlaces={0}
          onChange={onValueChange}
          value={collateralItem.value}
        />
      </td>
      <td className="field w-40">
        <DollarInput
          decimalPlaces={0}
          onChange={onPriorLiensChange}
          value={collateralItem.priorLiens}
        />
      </td>
      <td className="field w-28">
        <PercentAmount amount={collateralItem.combinedLoanToValueRatio} />
      </td>
      <td className="field w-32">
        <CurrencyAmount amount={collateralItem.effectiveValue} />
      </td>
      <td className="field px-2 w-20 text-right">
        <PercentAmount amount={collateralItem.effectiveLoanToValueRatio} />
      </td>
      <td className="field px-2 w-12 text-right text-gray-300 hover:text-red-300">
        <button onClick={onDelete}>
          <FontAwesomeIcon icon="minus-square" size="lg" />
        </button>
      </td>
    </tr>
  );
};

export default CollateralRow;
