import React, { useCallback, useState } from "react";

import ScenarioComponent from "./component";
import ScenarioNameModal from "../../../common/ScenarioNameModal";
import CreateDocumentModal from "../modals/CreateDocumentModal";
import { ScenarioProps } from "./props";

const Component = ({
  opportunityId,
  scenario,
  onSelect,
  onRemove,
  onRename,
  onDuplicate,
  onCompareWith,
  onMakePipelineScenario,
}: ScenarioProps) => {
  const [callback, setCallback] = useState<
    "rename" | "duplicate" | undefined
  >();
  const [showCreateDocumentModal, setShowCreateDocumentModal] = useState(false);

  const _onRenameScenario = useCallback(
    (name: string) => {
      setCallback(undefined);
      onRename(name);
    },
    [onRename],
  );

  const _onDuplicateScenario = useCallback(
    (name: string) => {
      setCallback(undefined);
      onDuplicate(name);
    },
    [onDuplicate],
  );

  const _callback = useCallback(
    (name: string) => {
      if (!callback) return;
      if (callback === "rename") return _onRenameScenario(name);
      if (callback === "duplicate") return _onDuplicateScenario(name);
    },
    [callback, _onRenameScenario, _onDuplicateScenario],
  );

  return (
    <>
      <ScenarioComponent
        scenario={scenario}
        onSelect={onSelect}
        onRemove={onRemove}
        onRename={() => setCallback("rename")}
        onDuplicate={() => setCallback("duplicate")}
        onCompareWith={onCompareWith}
        onMakePipelineScenario={onMakePipelineScenario}
        onCreateDocument={() => setShowCreateDocumentModal(true)}
      />

      {callback && (
        <ScenarioNameModal
          onClose={() => setCallback(undefined)}
          onConfirm={_callback}
        />
      )}

      {showCreateDocumentModal && (
        <CreateDocumentModal
          opportunityId={opportunityId}
          scenarioId={scenario.id}
          onCancel={() => setShowCreateDocumentModal(false)}
        />
      )}
    </>
  );
};

export default Component;
