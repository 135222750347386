import { Client as ClientGraphQl } from "@pricing-tool/graphql/lib/react";

import { Client } from "./core";

export type ClientDto = ClientGraphQl;

export const fromDto = (dto: ClientDto): Client => {
  return {
    ...dto,
  };
};

export const toDto = (client: Client): ClientDto => {
  return {
    ...client,
  };
};
