import React from "react";
import { map, sortBy } from "ramda";

import { ClientFragment } from "@pricing-tool/graphql/lib/react";

import "./styles.scss";

export type ClientsTableProps = {
  clients: ClientFragment[];
  onSelect: (client: ClientFragment) => void;
};

const ClientsTable = ({ clients, onSelect }: ClientsTableProps) => {
  if (!clients || clients.length === 0) {
    return <div className="p-4">No clients</div>;
  }

  const sortedClients = sortBy((c) => c?.name || 1, clients);

  return (
    <table className="component clients-table">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Contact</th>
          <th scope="col">Email</th>
          <th scope="col">Phone</th>
        </tr>
      </thead>
      <tbody>
        {map((client) => {
          return (
            <tr key={client.id} onClick={() => onSelect(client)}>
              <td>{client.name}</td>
              <td>
                {client.contact?.firstName} {client.contact?.lastName}
              </td>
              <td>{client.contact?.email}</td>
              <td>{client.contact?.phone}</td>
            </tr>
          );
        }, sortedClients)}
      </tbody>
    </table>
  );
};

export default ClientsTable;
