import React from "react";

import "./styles.scss";
import LoadingIndicator from "../LoadingIndicator";

export type LoadingOverlayProps = {
  text?: string;
  secondary?: string;
};

const LoadingOverlay = ({ text, secondary }: LoadingOverlayProps) => {
  const loadingText = text || "Loading...";
  return (
    <div className="loading-overlay">
      <LoadingIndicator size="lg" />
      <h2 className="text-center text-white text-xl font-semibold mt-4">
        {loadingText}
      </h2>
      {secondary && <p className="w-1/3 text-center text-white">{secondary}</p>}
    </div>
  );
};

export default LoadingOverlay;
