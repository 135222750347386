import React, { useCallback } from "react";

import {
  OpportunitySummaryFragment,
  useGetOpportunityLazyQuery,
} from "@pricing-tool/graphql/lib/react";
import { Opportunity } from "@pricing-tool/lib/opportunity/core";
import { fromDto, OpportunityDto } from "@pricing-tool/lib/opportunity/dto";

import OpportunitySearchForm from "../../../../opportunity/forms/OpportunitySearchForm";
import ErrorBanner from "../../../../common/ErrorBanner";
import LoadingIndicator from "../../../../common/LoadingIndicator";

import "./styles.scss";

export type OpportunityPickerProps = {
  onConfirm: (opportunity: Opportunity) => void;
  onCancel: () => void;
};

const OpportunityPicker = ({ onConfirm, onCancel }: OpportunityPickerProps) => {
  const [getOpportunity, { loading, error }] = useGetOpportunityLazyQuery();

  const onSelectOpportunity = useCallback(
    (opportunity: OpportunitySummaryFragment) => {
      getOpportunity({
        variables: { branchId: "branch-1", opportunityId: opportunity.id },
      }).then(({ data }) =>
        onConfirm(fromDto(data!.getOpportunity! as OpportunityDto)),
      );
    },
    [onConfirm],
  );

  return (
    <div className="opportunity-picker">
      {!loading && (
        <OpportunitySearchForm
          onSelectOpportunity={onSelectOpportunity}
          onCancel={onCancel}
        />
      )}

      {loading && (
        <>
          <div className="body loader">
            <LoadingIndicator size="lg" />
          </div>
          <div className="footer">
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:items-end">
              <div className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-auto sm:w-auto sm:text-sm">
                Cancel
              </div>
            </div>
          </div>
        </>
      )}
      {error && <ErrorBanner text={error.message} />}
    </div>
  );
};

export default OpportunityPicker;
