import React from "react";
import { useObservableState } from "observable-hooks";

import {
  asObservable,
  CommercialIndustrialModel,
} from "@pricing-tool/lib/products/ci/model/CommercialIndustrialModel";
import { CommercialIndustrialDependencies } from "@pricing-tool/lib/products/ci/core";
import { CollateralRecoveryTable } from "@pricing-tool/lib/products/ci/core/CollateralRecoveryTable";

import LoanForm from "../../../common/LoanForm";

export type CommercialIndustrialFormProps = {
  commercialIndustrialModel: CommercialIndustrialModel;
  dependencies: CommercialIndustrialDependencies;
  collateralRecoveryTable: CollateralRecoveryTable;
};

const CommercialIndustrialForm = ({
  commercialIndustrialModel,
  dependencies,
  collateralRecoveryTable,
}: CommercialIndustrialFormProps) => {
  const commercialIndustrial$ = asObservable(commercialIndustrialModel);
  const [commercialIndustrial] = useObservableState(
    () => commercialIndustrial$,
  );

  if (!commercialIndustrial) {
    return <></>;
  }

  return (
    <LoanForm
      model={commercialIndustrialModel}
      product={commercialIndustrial}
      dependencies={dependencies}
      collateralRecoveryTable={collateralRecoveryTable}
    />
  );
};

export default CommercialIndustrialForm;
