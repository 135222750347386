import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import Helmet from "react-helmet";

import Modal from "../Modal";

export type ModalPageProps = {
  title: string;
  width: number;
  contentHeight?: number;
};

const ModalPage = (props: ModalPageProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  let state = location.state as { backgroundLocation?: Location } | null;

  function onDismiss() {
    navigate(state?.backgroundLocation!);
  }

  return state?.backgroundLocation ? (
    <Modal
      title={props.title}
      width={props.width}
      contentHeight={props.contentHeight}
      onClose={onDismiss}
    >
      <Outlet />
    </Modal>
  ) : (
    <>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      <Outlet />
    </>
  );
};

export default ModalPage;
