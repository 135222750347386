import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Modal from "../../common/Modal";

type OpportunityActionsProps = {
  saveButtonDisabled: boolean;
  onSaveOpportunity: () => void;
  onDeleteOpportunity: () => void;
  onShowIncomeStatement: () => void;
  onShowDocuments: () => void;
};

const Component = (props: OpportunityActionsProps) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    React.useState(false);

  return (
    <>
      <div className="flex justify-between">
        <div className="">
          <div className="flex items-center">
            <button
              type="button"
              disabled={props.saveButtonDisabled}
              className={`${
                props.saveButtonDisabled
                  ? "bg-gray-300 border border-gray-300"
                  : "hover:bg-white bg-blue-500 hover:text-blue-500 border border-blue-500"
              }  text-base font-medium text-white py-2 px-4 rounded`}
              onClick={props.onSaveOpportunity}
            >
              <FontAwesomeIcon
                className="w-4 h-4 mr-2"
                icon={["far", "save"]}
              />
              Save
            </button>
            <div className="w-2" />
            <button
              type="button"
              className="bg-transparent hover:bg-red-200 text-red-300 text-base font-medium hover:text-white py-2 px-2 hover:border-transparent rounded"
              onClick={() => setShowDeleteConfirmation(true)}
            >
              <FontAwesomeIcon
                className="w-4 h-4"
                icon={["far", "trash-alt"]}
              />
            </button>
          </div>
        </div>
        <div className="">
          <div className="flex items-center">
            <button
              type="button"
              className="bg-transparent hover:bg-blue-500 text-blue-500 text-base font-medium hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
              onClick={props.onShowIncomeStatement}
            >
              <FontAwesomeIcon
                className="w-4 h-4 mr-2"
                icon={["far", "file-alt"]}
              />
              Income Statement
            </button>
            <div className="w-4" />
            <button
              type="button"
              className="bg-transparent hover:bg-blue-500 text-blue-500 text-base font-medium hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
              onClick={props.onShowDocuments}
            >
              <FontAwesomeIcon
                className="w-4 h-4 mr-2"
                icon={["far", "file"]}
              />
              Documents
            </button>
          </div>
        </div>
      </div>

      {showDeleteConfirmation && (
        <Modal
          title="Delete Opportunity"
          width={400}
          contentHeight={100}
          onClose={() => setShowDeleteConfirmation(false)}
        >
          <div className="confirm-remove-elements-form">
            <div className="body overflow-auto">
              <div className="mx-4 mb-3">
                <p className="mb-2">
                  Are you sure you want to delete this opportunity? This action
                  cannot be undone.
                </p>
              </div>
            </div>
            <div className="footer">
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:items-end">
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-auto sm:w-auto sm:text-sm"
                  onClick={() => setShowDeleteConfirmation(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    setShowDeleteConfirmation(false);
                    props.onDeleteOpportunity();
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Component;
