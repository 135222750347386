import ScenarioComparator from "../../ScenarioComparator";

import Modal from "../../../../common/Modal";
import { ScenarioComparatorModalProps } from "./props";

const ScenarioComparatorModal = ({
  scenarios,
  performanceTargets,
  selectedScenarioId,
  comparisonScenarioIds,
  onClose,
}: ScenarioComparatorModalProps) => {
  return (
    <Modal
      title={"Scenario Comparison"}
      width={600}
      contentHeight={505}
      onClose={onClose}
    >
      <ScenarioComparator
        scenarios={scenarios}
        performanceTargets={performanceTargets}
        selectedScenarioId={selectedScenarioId}
        comparisonScenarioIds={comparisonScenarioIds}
        onClose={onClose}
      />
    </Modal>
  );
};

export default ScenarioComparatorModal;
