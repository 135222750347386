import React from "react";
import { map } from "ramda";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Product from "../Product";
import { ProductsProps } from "./props";

const Component = ({
  products,
  selectedProduct,
  onAddProduct,
  onSelectProduct,
  onRemoveProduct,
}: ProductsProps) => {
  return (
    <div className="py-2 px-4">
      <div className="uppercase text-gray-400 text-xs font-semibold">
        Products
      </div>
      <div className="flex flex-col items-start py-2">
        {map(
          (product) => (
            <div key={product.name} className="pb-4">
              <Product
                product={product}
                selected={selectedProduct === product.productType}
                selectProduct={() => onSelectProduct(product.productType)}
                removeProduct={() => onRemoveProduct(product.productType)}
              />
            </div>
          ),
          products,
        )}
        <button onClick={onAddProduct}>
          <div className="flex w-10 justify-center items-center aspect-square rounded-full border-blue-300 border-2">
            <FontAwesomeIcon className="text-blue-300" icon="plus" size="lg" />
          </div>
        </button>
      </div>
    </div>
  );
};

export default Component;
