import React, { useCallback, useState } from "react";
import Modal from "../../../common/Modal";
import { filter, find, includes, map } from "ramda";

import "./styles.scss";

import { ScenarioSummary } from "../../utils/ScenarioSummary";
import PerformanceMetric from "../../../opportunity/fields/PerformanceMetric";
import { ScenariosPickerProps } from "./props";

const ScenariosPicker = ({
  scenarios,
  selectedScenarioId,
  onConfirm,
  onCancel,
}: ScenariosPickerProps) => {
  const unselectedScenarios = filter(
    (scenario) => scenario.id !== selectedScenarioId,
    scenarios,
  );

  const [selectedScenarioIds, setSelectedScenarioIds] = useState<string[]>([]);
  const [selectedScenario] = useState<ScenarioSummary | undefined>(
    selectedScenarioId
      ? find((scenario) => scenario.id === selectedScenarioId, scenarios || [])
      : undefined,
  );

  const checkScenario = useCallback(
    (scenarioId: string) =>
      setSelectedScenarioIds([...selectedScenarioIds, scenarioId]),
    [selectedScenarioIds],
  );

  const uncheckScenario = useCallback(
    (scenarioId: string) =>
      setSelectedScenarioIds(
        filter(
          (_scenarioId) => _scenarioId !== scenarioId,
          selectedScenarioIds,
        ),
      ),
    [selectedScenarioIds],
  );

  return (
    <Modal
      title={"Compare Scenarios"}
      width={600}
      contentHeight={300}
      onClose={onCancel}
    >
      <div className="body">
        <table className="scenarios-table">
          <thead>
            <tr>
              <th scope="col">&nbsp;</th>
              <th scope="col">Scenario</th>
              <th scope="col">ROE</th>
              <th scope="col">ROA</th>
            </tr>
          </thead>
          <tbody>
            {selectedScenario && (
              <>
                <tr className="instruction">
                  <td colSpan={4}>Compare...</td>
                </tr>
                <tr key={selectedScenario.id}>
                  <td>&nbsp;</td>
                  <td>{selectedScenario.name}</td>
                  <td>
                    <PerformanceMetric
                      metric={selectedScenario.metrics.returnOnEquity}
                      performance={
                        selectedScenario.performance.returnOnEquityPerformance
                      }
                    />
                  </td>
                  <td>
                    <PerformanceMetric
                      metric={selectedScenario.metrics.returnOnAssets}
                      performance={
                        selectedScenario.performance.returnOnAssetsPerformance
                      }
                    />
                  </td>
                </tr>
                <tr className="instruction">
                  <td colSpan={3}>With...</td>
                </tr>
              </>
            )}
            {map((scenario) => {
              const checked = includes(scenario.id, selectedScenarioIds);
              const onCheckChanged = () =>
                checked
                  ? uncheckScenario(scenario.id)
                  : checkScenario(scenario.id);
              return (
                <tr key={scenario.id} onClick={onCheckChanged}>
                  <td>
                    <input
                      type="checkbox"
                      checked={checked}
                      disabled={false}
                      onChange={onCheckChanged}
                    />
                  </td>
                  <td>{scenario.name}</td>
                  <td>
                    <PerformanceMetric
                      metric={scenario.metrics.returnOnEquity}
                      performance={
                        scenario.performance.returnOnEquityPerformance
                      }
                    />
                  </td>
                  <td>
                    <PerformanceMetric
                      metric={scenario.metrics.returnOnAssets}
                      performance={
                        scenario.performance.returnOnAssetsPerformance
                      }
                    />
                  </td>
                </tr>
              );
            }, unselectedScenarios)}
          </tbody>
        </table>
      </div>
      <div className="footer">
        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:items-end">
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-auto sm:w-auto sm:text-sm"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => onConfirm(selectedScenarioIds)}
          >
            Done
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ScenariosPicker;
