import React from "react";

import Modal from "../../../common/Modal";
import { RemoveTreasuryServicesModalProps } from "./props";

const RemoveTreasuryServicesModal = (
  props: RemoveTreasuryServicesModalProps,
) => {
  return (
    <Modal
      title="Remove Treasury Service"
      width={600}
      contentHeight={250}
      onClose={props.onCancel}
    >
      <div className="confirm-remove-elements-form">
        <div className="body overflow-auto">
          <div className="mx-4 mb-3">
            <p className="mb-2">
              You are trying to remove "{props.element.name}" (
              {props.element.id}), but it is a prerequisite for:
            </p>
            <ul className="list-disc list-inside mb-2">
              {props.dependentElements.map((element) => (
                <li key={element.id}>
                  {element.name} ({element.id})
                </li>
              ))}
            </ul>
            <p>Do you want to remove all of them?</p>
          </div>
        </div>
        <div className="footer">
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:items-end">
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-auto sm:w-auto sm:text-sm"
              onClick={props.onCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={props.onConfirm}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveTreasuryServicesModal;
