import "./styles.scss";
import Decimal from "decimal.js-light";
import InputWithIcon from "../InputWithIcon";
import { forwardRef } from "react";
import { PercentInputProps } from "./props";

const PercentInput = forwardRef<HTMLInputElement, PercentInputProps>(
  (props: PercentInputProps, ref) => {
    const value = props.value
      ? props.value.mul(100).toFormat(props.dp || 2)
      : "";
    const onChange = (newValue: string) =>
      props.onChange(new Decimal(newValue.replaceAll(",", "")).dividedBy(100));

    return (
      <div className="component percent-input">
        <InputWithIcon
          ref={ref}
          value={value}
          selected={props.selected}
          onSelect={props.onSelect}
          onDeselect={props.onDeselect}
          onChange={onChange}
          label={props.label}
          textAlign={"right"}
          iconSide={"right"}
          iconName={"percent"}
          className={props.className}
          disabled={props.disabled}
        />
      </div>
    );
  },
);

export default PercentInput;
