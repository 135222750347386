import React from "react";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Editor } from "@tinymce/tinymce-react";

import InputWithIcon from "../../../common/InputWithIcon";
import TextArea from "../../../common/TextArea";

import "./styles.scss";

export interface TemplateFormValue {
  name: string;
  description?: string | null;
  body: string;
}

const defaultTemplateFormValue: TemplateFormValue = {
  name: "",
  description: "",
  body: "",
};

export interface TemplateFormProps {
  template?: TemplateFormValue;
  onCancel: () => void;
  onSubmit: (input: TemplateFormValue) => void;
  onDelete?: () => void;
}

const TemplateForm: React.FC<TemplateFormProps> = (props) => {
  const { template, onCancel, onSubmit, onDelete } = props;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<TemplateFormValue>({
    defaultValues: template || defaultTemplateFormValue,
  });

  return (
    <div className="component template-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-4 p-4">
          <div>
            <Controller
              render={({ field: { onChange, value, ref } }) => (
                <InputWithIcon
                  onChange={onChange}
                  value={value}
                  ref={ref}
                  label="Name"
                />
              )}
              rules={{ required: "Required" }}
              control={control}
              name="name"
            />
            <ErrorMessage
              as={<div className="text-sm text-red-500" />}
              errors={errors}
              name="name"
            />
          </div>
          <div>
            <Controller
              render={({ field: { onChange, value } }) => (
                <TextArea
                  label="Description"
                  onChange={onChange}
                  value={value || ""}
                  className="w-full resize-none"
                  rows={2}
                />
              )}
              control={control}
              rules={{ required: "Required" }}
              name="description"
            />
            <ErrorMessage
              as={<div className="text-sm text-red-500" />}
              errors={errors}
              name="description"
            />
          </div>
          <div>
            <Controller
              render={({ field: { onChange, value } }) => (
                <>
                  {/* @ts-ignore */}
                  <Editor
                    apiKey="nvsti0udg3sdzg0ug3lsba7oou6hy86kzhcc08cscioop2uq"
                    onEditorChange={onChange}
                    init={{
                      content_css: ["document"],
                      plugins:
                        "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount pagebreak preview code print",
                      toolbar:
                        "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap pagebreak | removeformat",
                    }}
                    initialValue={template?.body || ""}
                  />
                </>
              )}
              control={control}
              rules={{ required: "Required" }}
              name="body"
            />
            <ErrorMessage
              as={<div className="text-sm text-red-500" />}
              errors={errors}
              name="body"
            />
          </div>
        </div>
        <div className="mt-4">
          <div className={`flex justify-between px-4 py-3`}>
            <div>
              {props.onDelete && (
                <button
                  type="button"
                  className="text-red-400 mt-3 w-full inline-flex justify-center rounded-md border border-red-400 shadow-sm px-4 py-2 bg-white text-base font-medium hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600 sm:mt-0 sm:ml-auto sm:w-auto sm:text-sm"
                  onClick={onDelete}
                >
                  Delete
                </button>
              )}
            </div>
            <div>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-auto sm:w-auto sm:text-sm"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TemplateForm;
