import { combineLatest, map, Observable, of } from "rxjs";
import Decimal from "decimal.js-light";

import {
  IncomeStatement,
  IncomeStatementOutput,
} from "../../../incomeStatement";
import { DepositsDependencies } from "../core";
import mapAllDefined from "../../../util/operators/mapAllDefined";

export type IncomeStatementInput = {
  collectedBalances: Observable<Decimal | undefined>;
  interestEligibleBalances: Observable<Decimal | undefined>;
  fundTransferPricingRate: Observable<Decimal | undefined>;
  annualizedInterestRate: Observable<Decimal | undefined>;
  earningsCreditAllowance: Observable<Decimal | undefined>;
  annualEcrEligibleCharges: Observable<Decimal | undefined>;
  reserveRequirements: Observable<Decimal | undefined>;
};

export type IncomeStatementModel = {
  input: IncomeStatementInput;
  output: IncomeStatementOutput;
};

export const calculate = (
  input: IncomeStatementInput,
  dependencies: Pick<DepositsDependencies, "taxRate">,
): IncomeStatementOutput => {
  const {
    collectedBalances,
    interestEligibleBalances,
    fundTransferPricingRate,
    annualizedInterestRate,
    earningsCreditAllowance,
    annualEcrEligibleCharges,
    reserveRequirements,
  } = input;
  const { taxRate } = dependencies;

  const feeOrInterestIncome = combineLatest([
    collectedBalances,
    fundTransferPricingRate,
  ]).pipe(
    mapAllDefined(([collectedBalances, fundTransferPricingRate]) =>
      collectedBalances.mul(fundTransferPricingRate),
    ),
  );

  const feeOrInterestExpense = combineLatest([
    interestEligibleBalances,
    annualizedInterestRate,
  ]).pipe(
    mapAllDefined(([interestEligibleBalances, annualizedInterestRate]) =>
      interestEligibleBalances.mul(annualizedInterestRate),
    ),
  );

  const netFeeOrInterestIncome = combineLatest([
    feeOrInterestIncome,
    feeOrInterestExpense,
  ]).pipe(
    mapAllDefined(([feeOrInterestIncome, feeOrInterestExpense]) =>
      feeOrInterestIncome.minus(feeOrInterestExpense),
    ),
  );

  const nonFeeOrInterestExpense = combineLatest([
    earningsCreditAllowance,
    annualEcrEligibleCharges,
  ]).pipe(
    mapAllDefined(([earningsCreditAllowance, annualEcrEligibleCharges]) => {
      const minDecimal = (a: Decimal, b: Decimal) => (a.cmp(b) < 0 ? a : b);
      return minDecimal(earningsCreditAllowance, annualEcrEligibleCharges);
    }),
  );

  const pretaxIncome = combineLatest([
    netFeeOrInterestIncome,
    nonFeeOrInterestExpense,
  ]).pipe(
    mapAllDefined(([netFeeOrInterestIncome, nonFeeOrInterestExpense]) =>
      netFeeOrInterestIncome.minus(nonFeeOrInterestExpense),
    ),
  );

  const taxes = pretaxIncome.pipe(
    map((pretaxIncome) => pretaxIncome?.mul(taxRate)),
  );

  const netIncome = combineLatest([pretaxIncome, taxes]).pipe(
    mapAllDefined(([pretaxIncome, taxes]) => pretaxIncome.minus(taxes)),
  );

  const loanLossReserves = of(undefined);

  const averageBalance = collectedBalances.pipe(
    map((collectedBalances) => collectedBalances),
  );

  const averageEquity = combineLatest([
    collectedBalances,
    reserveRequirements,
  ]).pipe(
    mapAllDefined(([collectedBalances, reserveRequirements]) =>
      collectedBalances.mul(reserveRequirements),
    ),
  );

  const returnOnAssets = combineLatest([netIncome, averageBalance]).pipe(
    mapAllDefined(([netIncome, averageBalance]) =>
      netIncome.dividedBy(averageBalance),
    ),
  );

  const returnOnEquity = combineLatest([netIncome, averageEquity]).pipe(
    mapAllDefined(([netIncome, averageEquity]) =>
      netIncome.dividedBy(averageEquity),
    ),
  );

  return {
    feeOrInterestIncome,
    feeOrInterestExpense,
    netFeeOrInterestIncome,
    nonFeeOrInterestExpense,
    loanLossReserves,
    pretaxIncome,
    taxes,
    netIncome,
    averageBalance,
    averageEquity,
    returnOnAssets,
    returnOnEquity,
  };
};

export const fromIncomeStatement = (
  incomeStatement: IncomeStatement | undefined,
  collectedBalances: Observable<Decimal | undefined>,
  interestEligibleBalances: Observable<Decimal | undefined>,
  fundTransferPricingRate: Observable<Decimal | undefined>,
  annualizedInterestRate: Observable<Decimal | undefined>,
  earningsCreditAllowance: Observable<Decimal | undefined>,
  annualEcrEligibleCharges: Observable<Decimal | undefined>,
  reserveRequirements: Observable<Decimal | undefined>,
  dependencies: Pick<DepositsDependencies, "taxRate">,
): IncomeStatementModel => {
  const input: IncomeStatementInput = {
    collectedBalances,
    interestEligibleBalances,
    fundTransferPricingRate,
    annualizedInterestRate,
    earningsCreditAllowance,
    annualEcrEligibleCharges,
    reserveRequirements,
  };

  const output = calculate(input, dependencies);

  return {
    input,
    output,
  };
};
