import {
  BehaviorSubject,
  combineLatest,
  map,
  merge,
  Observable,
  of,
  share,
  Subject,
  withLatestFrom,
} from "rxjs";
import Decimal from "decimal.js-light";
import { all, isNil, not, pipe } from "ramda";
import { match } from "ts-pattern";

import {
  asObservable as asObservableIncomeStatement,
  ProductOutput,
} from "../../../incomeStatement";
import { Deposits, DepositsDependencies } from "../core";
import anyChanged from "../../../util/operators/anyChanged";
import fieldChanged from "../../../util/operators/fieldChanged";
import {
  IncomeStatementInput,
  fromIncomeStatement,
} from "./IncomeStatementModel";
import mapAllDefined from "../../../util/operators/mapAllDefined";

export type DepositsInput = {
  collectedBalances: BehaviorSubject<Decimal | undefined>;
  ecrEligibleBalancesInput: BehaviorSubject<Decimal>;
  interestEligibleBalancesInput: Subject<Decimal>;
  fundTransferPricingRate: BehaviorSubject<Decimal | undefined>;
  liquidityTransferPricingRate: BehaviorSubject<Decimal | undefined>;
  annualizedEcr: BehaviorSubject<Decimal>;
  annualizedInterestRate: BehaviorSubject<Decimal | undefined>;
  annualEcrEligibleCharges: BehaviorSubject<Decimal | undefined>;
  reserveRequirements: BehaviorSubject<Decimal | undefined>;
  incomeStatementInput: IncomeStatementInput;
};

export type DepositsOutput = ProductOutput & {
  ecrEligibleBalances: Observable<Decimal>;
  interestEligibleBalances: Observable<Decimal>;
  earningsCreditAllowance: Observable<Decimal>;
  netInterestMargin: Observable<Decimal | undefined>;
  netIncome: Observable<Decimal | undefined>;
};

export type DepositsModel = {
  _type: "deposits";
  isDirty: Observable<boolean>;
  input: DepositsInput;
  output: DepositsOutput;
};

const trackChanges = (
  input: Pick<
    DepositsInput,
    | "collectedBalances"
    | "ecrEligibleBalancesInput"
    | "interestEligibleBalancesInput"
  >,
): Observable<boolean> =>
  anyChanged([
    fieldChanged(input.collectedBalances),
    fieldChanged(input.ecrEligibleBalancesInput),
    fieldChanged(input.interestEligibleBalancesInput),
  ]);

const defaultDeposits: Deposits = {
  _type: "deposits",
  collectedBalances: new Decimal(50000),
  ecrEligibleBalances: new Decimal(45000),
  interestEligibleBalances: new Decimal(5000),
};

export const init = (dependencies: DepositsDependencies): DepositsModel => {
  return {
    ...fromDeposits(defaultDeposits, dependencies),
    isDirty: of(true),
  };
};

export const fromDeposits = (
  deposits: Deposits,
  dependencies: DepositsDependencies,
): DepositsModel => {
  const collectedBalances = new BehaviorSubject(deposits.collectedBalances);
  const ecrEligibleBalancesInput = new BehaviorSubject(
    deposits.ecrEligibleBalances || new Decimal(0),
  );
  const interestEligibleBalancesInput = new Subject<Decimal>();

  const fundTransferPricingRate = new BehaviorSubject<Decimal | undefined>(
    dependencies.fundTransferPricingRate,
  );

  const liquidityTransferPricingRate = new BehaviorSubject<Decimal | undefined>(
    dependencies.liquidityTransferPricingRate,
  );

  const annualizedEcr = new BehaviorSubject<Decimal>(
    dependencies.annualizedEcr,
  );

  const annualizedInterestRate = new BehaviorSubject<Decimal | undefined>(
    dependencies.annualizedInterestRate,
  );

  const annualEcrEligibleCharges = new BehaviorSubject<Decimal | undefined>(
    dependencies.annualEcrEligibleCharges,
  );

  const reserveRequirements = new BehaviorSubject<Decimal | undefined>(
    dependencies.reserveRequirements,
  );

  const input: Omit<DepositsInput, "incomeStatementInput"> = {
    collectedBalances,
    ecrEligibleBalancesInput,
    interestEligibleBalancesInput,
    fundTransferPricingRate,
    liquidityTransferPricingRate,
    annualizedEcr,
    annualizedInterestRate,
    annualEcrEligibleCharges,
    reserveRequirements,
  };

  const output = calculate(input, dependencies);

  const incomeStatementModel = fromIncomeStatement(
    deposits.incomeStatement,
    collectedBalances,
    output.interestEligibleBalances,
    fundTransferPricingRate,
    annualizedInterestRate,
    output.earningsCreditAllowance,
    annualEcrEligibleCharges,
    reserveRequirements,
    dependencies,
  );

  const isDirty = trackChanges(input);

  return {
    _type: "deposits",
    isDirty,
    input: {
      ...input,
      incomeStatementInput: incomeStatementModel.input,
    },
    output: {
      ...output,
      incomeStatementOutput: incomeStatementModel.output,
    },
  };
};

enum DepositMixUpdateType {
  ECR,
  Interest,
}

type DepositMixUpdate = [DepositMixUpdateType, Decimal];

export const calculate = (
  input: Omit<DepositsInput, "incomeStatementInput">,
  dependencies: DepositsDependencies,
): Omit<DepositsOutput, "incomeStatementOutput"> => {
  const {
    collectedBalances,
    ecrEligibleBalancesInput,
    interestEligibleBalancesInput,
    annualizedEcr,
    fundTransferPricingRate,
    annualEcrEligibleCharges,
    annualizedInterestRate,
  } = input;

  const depositMixUpdate$: Observable<DepositMixUpdate> = merge(
    ecrEligibleBalancesInput.pipe(
      map((value): DepositMixUpdate => [DepositMixUpdateType.ECR, value]),
    ),
    interestEligibleBalancesInput.pipe(
      map((value): DepositMixUpdate => [DepositMixUpdateType.Interest, value]),
    ),
  );

  const ecrAndInterestBalances$ = depositMixUpdate$.pipe(
    withLatestFrom(collectedBalances),
    map(([[type, value], collectedBalances]) => {
      const calculateNewEcrEligibleBalances = (
        interestEligibleBalance: Decimal,
      ) => collectedBalances?.minus(interestEligibleBalance) || new Decimal(0);

      const calculateNewInterestEligibleBalances = (
        ecrEligibleBalance: Decimal,
      ) => collectedBalances?.minus(ecrEligibleBalance) || new Decimal(0);

      return match(type)
        .with(DepositMixUpdateType.ECR, () => [
          value,
          calculateNewInterestEligibleBalances(value),
        ])
        .with(DepositMixUpdateType.Interest, () => [
          calculateNewEcrEligibleBalances(value),
          value,
        ])
        .run();
    }),
  );

  const ecrEligibleBalances = ecrAndInterestBalances$.pipe(
    map((result) => result[0]),
  );
  const interestEligibleBalances = ecrAndInterestBalances$.pipe(
    map((result) => result[1]),
  );

  const earningsCreditAllowance = combineLatest([
    ecrEligibleBalances,
    annualizedEcr,
  ]).pipe(
    map(([ecrEligibleBalances, annualizedEcr]) =>
      ecrEligibleBalances.mul(annualizedEcr),
    ),
  );

  const netInterestMargin = combineLatest([
    earningsCreditAllowance,
    annualEcrEligibleCharges,
    annualizedInterestRate,
    interestEligibleBalances,
    collectedBalances,
    fundTransferPricingRate,
  ]).pipe(
    mapAllDefined(
      ([
        earningsCreditAllowance,
        annualEcrEligibleCharges,
        annualizedInterestRate,
        interestEligibleBalances,
        collectedBalances,
        fundTransferPricingRate,
      ]) => {
        const minDecimal = (a: Decimal, b: Decimal) => (a.cmp(b) < 0 ? a : b);
        return fundTransferPricingRate.minus(
          minDecimal(annualEcrEligibleCharges, earningsCreditAllowance)
            .add(annualizedInterestRate.mul(interestEligibleBalances))
            .div(collectedBalances),
        );
      },
    ),
  );

  const netIncome = combineLatest([netInterestMargin, collectedBalances]).pipe(
    mapAllDefined(([netInterestMargin, collectedBalances]) =>
      netInterestMargin.mul(collectedBalances),
    ),
  );

  return {
    ecrEligibleBalances,
    interestEligibleBalances,
    earningsCreditAllowance,
    netInterestMargin,
    netIncome,
  };
};

export const asObservable = (model: DepositsModel): Observable<Deposits> => {
  const {
    collectedBalances,
    fundTransferPricingRate,
    liquidityTransferPricingRate,
    annualizedEcr,
    annualizedInterestRate,
    annualEcrEligibleCharges,
    reserveRequirements,
  } = model.input;
  const {
    ecrEligibleBalances,
    interestEligibleBalances,
    earningsCreditAllowance,
    netInterestMargin,
    netIncome,
    incomeStatementOutput,
  } = model.output;

  const incomeStatement = asObservableIncomeStatement(incomeStatementOutput);

  return combineLatest([
    collectedBalances,
    ecrEligibleBalances,
    interestEligibleBalances,
    fundTransferPricingRate,
    liquidityTransferPricingRate,
    annualizedEcr,
    annualizedInterestRate,
    earningsCreditAllowance,
    annualEcrEligibleCharges,
    reserveRequirements,
    netInterestMargin,
    netIncome,
    incomeStatement,
  ]).pipe(
    map(
      ([
        collectedBalances,
        ecrEligibleBalances,
        interestEligibleBalances,
        fundTransferPricingRate,
        liquidityTransferPricingRate,
        annualizedEcr,
        annualizedInterestRate,
        earningsCreditAllowance,
        annualEcrEligibleCharges,
        reserveRequirements,
        netInterestMargin,
        netIncome,
        incomeStatement,
      ]) =>
        ({
          _type: "deposits",
          collectedBalances,
          ecrEligibleBalances,
          interestEligibleBalances,
          fundTransferPricingRate,
          liquidityTransferPricingRate,
          annualizedEcr,
          annualizedInterestRate,
          earningsCreditAllowance,
          annualEcrEligibleCharges,
          reserveRequirements,
          netInterestMargin,
          netIncome,
          incomeStatement,
        } as Deposits),
    ),
    share(),
  );
};
