import { map } from "ramda";
import { Schema as S } from "@effect/schema";

import { CommercialIndustrial, CommercialIndustrialSchema } from "./index";
import { FundingCurve, FundingCurveSchema } from "./FundingCurve";
import { IndexRate, IndexRateSchema } from "./IndexRates";

export type CommercialIndustrialDto = S.Schema.Encoded<
  typeof CommercialIndustrialSchema
>;

export const fromDto = (dto: CommercialIndustrialDto): CommercialIndustrial => {
  return S.decodeUnknownSync(CommercialIndustrialSchema)(dto);
};

export type FundingCurveDto = S.Schema.Encoded<typeof FundingCurveSchema>;

export const fromFundingCurveDto = (dto: FundingCurveDto): FundingCurve => {
  return S.decodeUnknownSync(FundingCurveSchema)(dto);
};

export type IndexRateDto = S.Schema.Encoded<typeof IndexRateSchema>;
export const fromIndexRatesDto = (dtos: IndexRateDto[]): IndexRate[] => {
  return map((dto) => S.decodeUnknownSync(IndexRateSchema)(dto), dtos || []);
};

export const toDto = (
  commercialIndustrial: CommercialIndustrial,
): CommercialIndustrialDto => {
  return S.encodeSync(CommercialIndustrialSchema)(commercialIndustrial);
};
