import {
  Observable,
  pairwise,
  scan,
  mergeMap,
  map,
  startWith,
  combineLatest,
} from "rxjs";
import * as R from "ramda";

import anyChanged from "./anyChanged";

export default <T extends { isDirty: Observable<boolean> }>(
  source: Observable<T[]>,
  idExtractor: (item: T) => string,
): Observable<boolean> => {
  const isDirty$ = source.pipe(
    mergeMap((ts) => anyChanged(R.map((t) => t.isDirty, ts))),
    startWith(false),
  );

  const arrayChanged$ = source.pipe(
    pairwise(),
    map(([a, b]) => {
      // if different length, it's changed
      if (a.length !== b.length) return true;

      // if the elements are different, it's changed
      const aIds = new Set(R.map(idExtractor, a));
      const bIds = new Set(R.map(idExtractor, b));
      if (!R.equals(aIds, bIds)) return true;

      // otherwise, defer to whether any of the elements have changed
      return false;
    }),
    scan((acc, changed) => changed || acc, false),
    startWith(false),
  );

  return combineLatest([isDirty$, arrayChanged$]).pipe(
    map(([isDirty, arrayChanged]) => isDirty || arrayChanged),
  );
};
