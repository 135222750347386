import React from "react";

import Modal from "../../../common/Modal";
import AddElementsForm from "../../forms/AddElementsForm";
import { AddElementsModalProps } from "./props";

const AddElementsModal = (props: AddElementsModalProps) => {
  return (
    <Modal
      title={"Add Elements"}
      width={800}
      contentHeight={400}
      onClose={props.onCancel}
    >
      <AddElementsForm
        elements={props.elements}
        addedElementIds={props.addedElementIds}
        onConfirm={props.onConfirm}
        onCancel={props.onCancel}
      />
    </Modal>
  );
};

export default AddElementsModal;
