import { useOutletContext } from "react-router";

import {
  ScenarioDependencies,
  ScenarioModel,
} from "@pricing-tool/lib/scenario/model";
import { Element, Package } from "@pricing-tool/lib/products/treasury/core";
import { ProductType } from "@pricing-tool/lib/products/core";
import { CollateralRecoveryTable } from "@pricing-tool/lib/products/ci/core/CollateralRecoveryTable";

import ScenarioEditor from "../../../../components/ScenarioEditor";
import { Index } from "@pricing-tool/lib/products/ci/core/IndexRates";

type ContextType = {
  scenarioModel: ScenarioModel;
  onSelectProduct: (scenarioId: string, productType: ProductType) => void;
  onCloneProductFromThisOpportunityClicked: () => void;
  onCloneProductFromOtherOpportunityClicked: () => void;
  selectedProduct: ProductType;
  dependencies: ScenarioDependencies;
  elementCatalog: Element[];
  packageCatalog: Package[];
  collateralRecoveryTable: CollateralRecoveryTable;
};

function Scenario() {
  const {
    scenarioModel,
    onSelectProduct,
    onCloneProductFromThisOpportunityClicked,
    onCloneProductFromOtherOpportunityClicked,
    selectedProduct,
    dependencies,
    elementCatalog,
    packageCatalog,
    collateralRecoveryTable,
  } = useOutletContext<ContextType>();

  if (!scenarioModel) return <></>;

  console.log(`rendering scenario outlet with ${scenarioModel.input.id}`);

  return (
    <ScenarioEditor
      scenarioModel={scenarioModel}
      onSelectProduct={onSelectProduct}
      onCloneProductFromThisOpportunityClicked={
        onCloneProductFromThisOpportunityClicked
      }
      onCloneProductFromOtherOpportunityClicked={
        onCloneProductFromOtherOpportunityClicked
      }
      selectedProduct={selectedProduct}
      dependencies={dependencies}
      elementCatalog={elementCatalog}
      packageCatalog={packageCatalog}
      collateralRecoveryTable={collateralRecoveryTable}
    />
  );
}

export default Scenario;
