import React from "react";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import InputWithIcon from "../../../common/InputWithIcon";

import "./styles.scss";

export interface ClientFormValue {
  name: string;
  address?: {
    street?: string;
    extended?: string;
    city?: string;
    region?: string;
    postalCode?: string;
  };
  contact?: {
    firstName?: string;
    lastName?: string;
    title?: string;
    email?: string;
    phone?: string;
  };
}

const defaultClientFormValue: ClientFormValue = {
  name: "",
  address: {
    street: "",
    extended: "",
    city: "",
    region: "",
    postalCode: "",
  },
  contact: {
    firstName: "",
    lastName: "",
    title: "",
    email: "",
    phone: "",
  },
};

export interface ClientFormProps {
  client?: ClientFormValue;
  onCancel: () => void;
  onSubmit: (input: ClientFormValue) => void;
  onDelete?: () => void;
}

const ClientForm: React.FC<ClientFormProps> = (props) => {
  const { client, onCancel, onSubmit, onDelete } = props;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ClientFormValue>({
    defaultValues: client || defaultClientFormValue,
  });

  return (
    <div className="component client-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="body fields">
          <div className="field">
            <Controller
              render={({ field: { onChange, value } }) => (
                <InputWithIcon onChange={onChange} value={value} label="Name" />
              )}
              rules={{ required: "Required" }}
              control={control}
              name="name"
            />
            <ErrorMessage
              as={<div className="error" />}
              errors={errors}
              name="name"
            />
          </div>
          <div className="section">
            <div className="title">Address</div>
            <div className="field">
              <Controller
                render={({ field: { onChange, value } }) => (
                  <InputWithIcon
                    label="Address"
                    onChange={onChange}
                    value={value || ""}
                  />
                )}
                control={control}
                name="address.street"
              />
              <ErrorMessage
                as={<div className="error" />}
                errors={errors}
                name="address.street"
              />
            </div>
            <div className="field">
              <Controller
                render={({ field: { onChange, value } }) => (
                  <InputWithIcon
                    label="Extended Address"
                    onChange={onChange}
                    value={value || ""}
                  />
                )}
                control={control}
                name="address.extended"
              />
              <ErrorMessage
                as={<div className="error" />}
                errors={errors}
                name="address.extended"
              />
            </div>
            <div className="field">
              <Controller
                render={({ field: { onChange, value } }) => (
                  <InputWithIcon
                    label="City"
                    onChange={onChange}
                    value={value || ""}
                  />
                )}
                control={control}
                name="address.city"
              />
              <ErrorMessage
                as={<div className="error" />}
                errors={errors}
                name="address.city"
              />
            </div>
            <div className="field">
              <Controller
                render={({ field: { onChange, value } }) => (
                  <InputWithIcon
                    label="State"
                    onChange={onChange}
                    value={value || ""}
                  />
                )}
                control={control}
                name="address.region"
              />
              <ErrorMessage
                as={<div className="error" />}
                errors={errors}
                name="address.region"
              />
            </div>
            <div className="field">
              <Controller
                render={({ field: { onChange, value } }) => (
                  <InputWithIcon
                    label="Zip Code"
                    onChange={onChange}
                    value={value || ""}
                  />
                )}
                control={control}
                name="address.postalCode"
              />
              <ErrorMessage
                as={<div className="error" />}
                errors={errors}
                name="address.postalCode"
              />
            </div>
          </div>
          <div className="section">
            <div className="title">Contact</div>
            <div className="field">
              <Controller
                render={({ field: { onChange, value } }) => (
                  <InputWithIcon
                    label="First Name"
                    onChange={onChange}
                    value={value || ""}
                  />
                )}
                control={control}
                name="contact.firstName"
              />
              <ErrorMessage
                as={<div className="error" />}
                errors={errors}
                name="contact.firstName"
              />
            </div>
            <div className="field">
              <Controller
                render={({ field: { onChange, value } }) => (
                  <InputWithIcon
                    label="Last Name"
                    onChange={onChange}
                    value={value || ""}
                  />
                )}
                control={control}
                name="contact.lastName"
              />
              <ErrorMessage
                as={<div className="error" />}
                errors={errors}
                name="contact.lastName"
              />
            </div>
            <div className="field">
              <Controller
                render={({ field: { onChange, value } }) => (
                  <InputWithIcon
                    label="Job Title"
                    onChange={onChange}
                    value={value || ""}
                  />
                )}
                control={control}
                name="contact.title"
              />
              <ErrorMessage
                as={<div className="error" />}
                errors={errors}
                name="contact.title"
              />
            </div>
            <div className="field">
              <Controller
                render={({ field: { onChange, value } }) => (
                  <InputWithIcon
                    label="Email"
                    onChange={onChange}
                    value={value || ""}
                  />
                )}
                control={control}
                name="contact.email"
              />
              <ErrorMessage
                as={<div className="error" />}
                errors={errors}
                name="contact.email"
              />
            </div>
            <div className="field">
              <Controller
                render={({ field: { onChange, value } }) => (
                  <InputWithIcon
                    label="Phone"
                    onChange={onChange}
                    value={value || ""}
                  />
                )}
                control={control}
                name="contact.phone"
              />
              <ErrorMessage
                as={<div className="error" />}
                errors={errors}
                name="contact.phone"
              />
            </div>
          </div>
        </div>
        <div className="footer">
          <div className={`flex justify-between px-4 py-3`}>
            <div>
              {props.onDelete && (
                <button
                  type="button"
                  className="text-red-400 mt-3 w-full inline-flex justify-center rounded-md border border-red-400 shadow-sm px-4 py-2 bg-white text-base font-medium hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600 sm:mt-0 sm:ml-auto sm:w-auto sm:text-sm"
                  onClick={onDelete}
                >
                  Delete
                </button>
              )}
            </div>
            <div>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-auto sm:w-auto sm:text-sm"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ClientForm;
