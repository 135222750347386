import { Schema as S } from "@effect/schema";

import { Treasury, TreasurySchema, TreasuryServiceSchema } from "./index";

export type TreasuryDto = S.Schema.Encoded<typeof TreasurySchema>;
export type TreasuryServiceDto = S.Schema.Encoded<typeof TreasuryServiceSchema>;

export const fromDto = (dto: TreasuryDto): Treasury => {
  return S.decodeUnknownSync(TreasurySchema)(dto);
};

export const toDto = (treasury: Treasury): TreasuryDto => {
  return S.encodeUnknownSync(TreasurySchema)(treasury);
};
