import React, { forwardRef } from "react";
import Decimal from "decimal.js-light";
import { useLayoutObservableState } from "observable-hooks";

import {
  asObservable,
  TreasuryServiceModel,
} from "@pricing-tool/lib/products/treasury/model/TreasuryServiceModel";

import { Modifier } from "../../../../../common/Input";

import TreasuryServiceRowComponent, {
  TreasuryServiceRowRef,
} from "./component";

type TreasuryServiceRowProps = {
  treasuryServiceModel: TreasuryServiceModel;
  selectedColumn?: string;
  selectedKeys: string[];
  onInputSelect: (
    key: string,
    column: string,
    modifier: Modifier,
    input: HTMLInputElement,
  ) => void;
  onInputDeselect: (input: HTMLInputElement) => void;
  onInputChange: (value: Decimal | number) => void;
  onDelete: () => void;
};

const TreasuryServiceRow = forwardRef<
  TreasuryServiceRowRef,
  TreasuryServiceRowProps
>(
  (
    {
      treasuryServiceModel,
      selectedColumn,
      selectedKeys,
      onDelete,
      onInputSelect,
      onInputDeselect,
      onInputChange,
    }: TreasuryServiceRowProps,
    ref,
  ) => {
    const treasuryService$ = asObservable(treasuryServiceModel);
    const [treasuryService] = useLayoutObservableState(() => treasuryService$);

    if (!treasuryService) return <></>;

    return (
      <TreasuryServiceRowComponent
        ref={ref}
        key={treasuryService.element.id}
        treasuryService={treasuryService}
        selectedColumn={selectedColumn}
        selectedKeys={selectedKeys}
        onDiscountChange={onInputChange}
        onPriceChange={onInputChange}
        onMonthlyVolumeChange={onInputChange}
        onDelete={onDelete}
        onInputSelect={onInputSelect}
        onInputDeselect={onInputDeselect}
      />
    );
  },
);

export default TreasuryServiceRow;
