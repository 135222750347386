import React, {
  useState,
  useEffect,
  MouseEventHandler,
  useCallback,
  forwardRef,
  PropsWithChildren,
} from "react";

import useShareForwardedRef from "../../../hooks/useSharedForwardedRef";

import "./styles.scss";

export type Modifier = "shift" | "command" | "none";

type InputProps = {
  value: string;
  onChange: (value: string) => void;
  onSelect?: (modifier: Modifier, input: HTMLInputElement) => void;
  onDeselect?: (input: HTMLInputElement) => void;
  className?: string;
  disabled?: boolean;
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      value,
      onSelect,
      onDeselect,
      onChange,
      className,
      disabled,
    }: PropsWithChildren<InputProps>,
    forwardedRef,
  ) => {
    const [entry, setEntry] = useState(value);
    const [focused, setFocused] = useState(false);
    const inputRef = useShareForwardedRef<HTMLInputElement>(forwardedRef);

    useEffect(() => {
      setEntry(value);
    }, [value]);

    const onBlur = useCallback(() => {
      if (value !== entry) onChange(entry);
      setFocused(false);
      onDeselect &&
        inputRef &&
        inputRef.current &&
        onDeselect(inputRef.current);
    }, [entry, value, onChange, onDeselect, inputRef]);

    const onFocus = useCallback(() => {
      if (!inputRef.current) return;
      if (!onSelect) return;
      if (focused) return;

      setFocused(true);
      onSelect("none", inputRef.current);
      inputRef.current.select();
    }, [onSelect, inputRef, focused]);

    const onMouseDown: MouseEventHandler<HTMLDivElement> = useCallback(
      (event) => {
        const modifier = event.shiftKey
          ? "shift"
          : event.metaKey || event.ctrlKey
          ? "command"
          : "none";
        if (modifier !== "none") {
          event.stopPropagation();
          event.preventDefault();
          onSelect && inputRef.current && onSelect(modifier, inputRef.current);
        }
      },
      [onSelect, inputRef],
    );

    return (
      <div className="component input">
        <input
          ref={inputRef}
          onMouseDown={onMouseDown}
          className={className}
          value={entry}
          onChange={({ currentTarget }) => setEntry(currentTarget.value)}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
        />
      </div>
    );
  },
);

export default Input;
