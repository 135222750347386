import { includes, find, isEmpty, isNil } from "ramda";
import { Schema as S } from "@effect/schema";

import { DecimalFromString } from "../../../util/Decimal";

export const ElementSchema = S.Struct({
  id: S.String,
  afpCode: S.String,
  productFamily: S.String,
  name: S.String,
  description: S.optional(S.String),
  monthlyVolume: S.Number.pipe(S.int()),
  margin: DecimalFromString,
  rackRate: DecimalFromString,
  prerequisiteElementIds: S.optional(S.Array(S.String)),
});

export type Element = S.Schema.Type<typeof ElementSchema>;
export type ElementDto = S.Schema.Encoded<typeof ElementSchema>;

export const fromDto = (dto: ElementDto): Element =>
  S.decodeUnknownSync(ElementSchema)(dto);

export const toDto = (element: Element): ElementDto =>
  S.encodeSync(ElementSchema)(element);

export const getPrerequisiteElements = (
  elementCatalog: Element[],
  element: Element,
  prerequisiteElements: Element[] = [],
): Element[] => {
  if (
    !element.prerequisiteElementIds ||
    isNil(element) ||
    isEmpty(element.prerequisiteElementIds)
  )
    return [];

  const prerequisites: Element[] = [];
  for (const id of element.prerequisiteElementIds) {
    const el = find((e) => e.id === id, elementCatalog);
    if (el && !includes(el, prerequisiteElements)) {
      prerequisites.push(el);
      prerequisites.push(
        ...getPrerequisiteElements(elementCatalog, el, [
          ...prerequisites,
          ...prerequisiteElements,
        ]),
      );
    }
  }

  return prerequisites;
};

export const hasElementAsPrerequisite = (
  elementCatalog: Element[],
  element: Element,
  prerequisiteElement: Element,
): boolean => {
  return includes(
    prerequisiteElement,
    getPrerequisiteElements(elementCatalog, element),
  );
};
