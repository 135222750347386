import Decimal from "decimal.js-light";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const CurrencyAmount = ({
  amount,
  decimalPlaces,
}: {
  amount?: Decimal;
  decimalPlaces?: number;
}) => {
  return (
    <div className="flex justify-between pl-2">
      <span className="pr-2">
        <FontAwesomeIcon
          className="text-gray-500"
          icon="dollar-sign"
          size="sm"
        />
      </span>
      <span>
        {amount?.toFormat(decimalPlaces === undefined ? 2 : decimalPlaces)}
      </span>
    </div>
  );
};

export default CurrencyAmount;
