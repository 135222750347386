import React, { useEffect } from "react";
import moment from "moment";
import { groupWith, reduce, reverse } from "ramda";
import { useIntersectionObserver } from "@uidotdev/usehooks";

import { Message } from "@pricing-tool/graphql/lib/types";

import "./styles.scss";

export type MessageListProps = {
  messages: Message[];
  moreMessages?: boolean;
  onLoadMore?: () => void;
  isLoadingMore?: boolean;
};

const MessageList = ({
  messages,
  moreMessages,
  isLoadingMore,
  onLoadMore,
}: MessageListProps) => {
  const [ref, entry] = useIntersectionObserver({
    threshold: 0,
  });

  useEffect(() => {
    if (entry?.isIntersecting && onLoadMore) {
      onLoadMore();
    }
  }, [entry]);

  if (messages.length === 0)
    return <div className="component message-list empty">No Messages</div>;

  // group the messages by day
  const messagesGroupedByDay = groupWith((a, b) => {
    return moment(a.createdAt).isSame(b.createdAt, "day");
  }, messages);

  return (
    <div className="component message-list">
      {messagesGroupedByDay.map((messages, index) => {
        const messagesGroupedByTime = reduce(
          (acc, message) => {
            if (acc.length === 0) return [[message]];

            const lastGroup = acc[acc.length - 1];
            const firstMessageInLastGroup = lastGroup[0] as Message;
            const diff = moment(message.createdAt).diff(
              firstMessageInLastGroup.createdAt,
              "minutes",
            );

            if (diff < 5 && message.userId === firstMessageInLastGroup.userId) {
              return [...acc.slice(0, acc.length - 1), [...lastGroup, message]];
            } else {
              return [...acc, [message]];
            }
          },
          [] as Message[][],
          reverse(messages),
        );

        const firstMessage = messages[0];
        return (
          <div className="day" key={firstMessage.id}>
            <div className="date">
              {moment(moment(firstMessage.createdAt).format("LL")).calendar(
                null,
                {
                  sameDay: "[Today]",
                  lastDay: "[Yesterday]",
                  lastWeek: "[Last] dddd",
                  sameElse: "LL",
                },
              )}
            </div>
            {messagesGroupedByTime.map((messages) =>
              messages.map((message, index) => (
                <div className="message" key={message.id}>
                  {index === 0 && (
                    <div className="header">
                      <span className="user">{message.user}</span>
                      <span className="timestamp">
                        {moment(message.createdAt).format("LT")}
                      </span>
                    </div>
                  )}
                  <div className="text">{message.text}</div>
                </div>
              )),
            )}
          </div>
        );
      })}

      {moreMessages && !isLoadingMore && (
        <div ref={ref} className="load-more">
          Load more messages
        </div>
      )}
      {isLoadingMore && <div className="loading-more">Loading...</div>}
    </div>
  );
};

export default MessageList;
