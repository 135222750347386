import React, { PropsWithChildren } from "react";

import "./styles.scss";

export type TitledPanelProps = {
  title: string;
  className?: string;
};

const TitledPanel = ({
  title,
  className,
  children,
}: PropsWithChildren<TitledPanelProps>) => {
  return (
    <div className={`titled-panel ${className}`}>
      <div className="title">{title}</div>
      {children}
    </div>
  );
};

export default TitledPanel;
