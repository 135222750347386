import React, { useEffect, useRef } from "react";
import { debounceTime, Subject } from "rxjs";

const useDebounce = (
  debounce: number,
  onDebouncedChange: ((value: string) => void) | undefined,
) => {
  const inputValue$ = useRef<Subject<string>>(new Subject<string>());

  useEffect(() => {
    const inputValue = inputValue$.current;
    if (!inputValue) return;

    const subscription = inputValue
      .pipe(debounceTime(debounce || 1000))
      .subscribe((value) => {
        onDebouncedChange && onDebouncedChange(value);
      });

    return () => subscription.unsubscribe();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      inputValue$.current?.next(event.target.value);
    },
  };
};

export default useDebounce;
