import * as R from "ramda";
import { map, Observable } from "rxjs";

type DefinedArray<T extends any[]> = T extends [infer U, ...infer Rest]
  ? [NonNullable<U>, ...DefinedArray<Rest>]
  : [];

const makeDefined = <T extends unknown | undefined | null>(arg: T) => arg!;

const mapAllDefined =
  <TSource extends unknown[], TTarget>(
    mapFn: (args: DefinedArray<TSource>) => TTarget,
  ) =>
  (source: Observable<TSource>) => {
    return source.pipe(
      map((args) => {
        if (!R.all(R.pipe(R.isNil, R.not), args)) return undefined;
        const definedArgs = R.map(makeDefined, args) as DefinedArray<TSource>;
        return mapFn(definedArgs);
      }),
    );
  };

export default mapAllDefined;
