import { ApolloClient } from "@apollo/client";
import link from "./links";
import cache from "./cache";

const client = new ApolloClient({
  link,
  cache,
  connectToDevTools: true, // process.env.NODE_ENV === "development",
});

export default client;
