import React from "react";
import { all, includes, map } from "ramda";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Element, Package } from "@pricing-tool/lib/products/treasury/core";

import "./styles.scss";
import { PackageTreeProps } from "./props";

const PackageTree = (props: PackageTreeProps) => {
  const [localExpanded, setLocalExpanded] = React.useState(false);

  // FIXME - should set to expanded on props.expanded change but then
  //         let localExpanded be toggled
  const isExpanded = React.useMemo(
    () => (props.expanded === undefined ? localExpanded : props.expanded),
    [localExpanded, props.expanded],
  );

  const toggleExpanded = () => setLocalExpanded(!localExpanded);

  const elementWasPreviouslyAdded = (element: Element) =>
    includes(element.id, props.previouslyAddedElementIds);

  const elementCurrentlyAdded = (element: Element) =>
    includes(element.id, props.addedElementIds);

  const isElementChecked = (element: Element) =>
    elementWasPreviouslyAdded(element) || elementCurrentlyAdded(element);

  const element = (element: Element) => {
    const checked = isElementChecked(element);
    return (
      <div key={`${props.package.id}-${element.id}`} className="element">
        <input
          type="checkbox"
          checked={checked}
          disabled={elementWasPreviouslyAdded(element)}
          onChange={() =>
            checked
              ? props.onElementUncheck(element)
              : props.onElementCheck(element)
          }
        />
        <span>
          {element.name} ({element.id})
        </span>
      </div>
    );
  };

  const renderPackage = (pkg: Package) => {
    const checked = all(isElementChecked, props.package.elements);
    return (
      <div className="package">
        <input
          type="checkbox"
          checked={checked}
          onChange={() =>
            checked ? props.onPackageUncheck(pkg) : props.onPackageCheck(pkg)
          }
        />
        <FontAwesomeIcon
          className="icon"
          icon={isExpanded ? "chevron-down" : "chevron-right"}
          onClick={toggleExpanded}
        />
        <span>{pkg.name}</span>
      </div>
    );
  };

  const elements = map(element, props.package.elements);

  return (
    <div>
      {renderPackage(props.package)}
      {isExpanded && elements}
    </div>
  );
};

export default PackageTree;
