import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Opportunity } from "@pricing-tool/lib/opportunity/core";
import { Client } from "@pricing-tool/graphql/lib/react";

import InputWithIcon from "../../../common/InputWithIcon";
import ClientSearch from "../../../client/fields/ClientSearch";
import DateField from "../../fields/DateField";

import "./styles.scss";

export type OpportunityFormProps = {
  opportunity?: Opportunity;
  client?: Client;
  submitButtonLabel?: string;
  onCancel: () => void;
  onSubmit: (data: Opportunity) => void;
  onCreateClient: () => void;
};

const OpportunityForm = (props: OpportunityFormProps) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Opportunity>({
    defaultValues: props.opportunity || {
      name: "",
      client: undefined,
      status: "IN_PROGRESS",
      pricingDate: moment().format("YYYY-MM-DD"),
      closeDate: undefined,
    },
  });

  useEffect(() => {
    if (!props.client) return;
    setValue("client", props.client);
  }, [setValue, props.client]);

  return (
    <div className="opportunity-form">
      <form onSubmit={handleSubmit(props.onSubmit)}>
        <div className="body">
          <table>
            <tbody>
              <tr>
                <td>Name:</td>
                <td>
                  <Controller
                    render={({ field: { onChange, value, ref } }) => (
                      <InputWithIcon
                        onChange={onChange}
                        value={value}
                        ref={ref}
                      />
                    )}
                    rules={{ required: "Required" }}
                    control={control}
                    name="name"
                  />
                  <ErrorMessage errors={errors} name="name" />
                </td>
              </tr>
              <tr>
                <td>Client:</td>
                <td>
                  <div className="flex">
                    <div className="w-full">
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <ClientSearch onChange={onChange} value={value} />
                        )}
                        control={control}
                        rules={{ required: "Required" }}
                        name="client"
                      />
                    </div>
                    <button
                      type="button"
                      onClick={props.onCreateClient}
                      title="Create client"
                    >
                      <div className="flex ml-2 w-8 justify-center items-center aspect-square rounded-full border-blue-300 border-2">
                        <FontAwesomeIcon
                          className="text-blue-300"
                          icon="plus"
                        />
                      </div>
                    </button>
                  </div>
                  <ErrorMessage errors={errors} name="client" />
                </td>
              </tr>
              <tr>
                <td>Status:</td>
                <td>
                  <div className="flex">
                    <div className="w-full">
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <select
                            className="rounded-md shadow-sm py-1 px-2 w-full focus:ring-2 focus:ring-blue-500 border border-gray-300"
                            onChange={onChange}
                            value={value}
                          >
                            <option value="IN_PROGRESS">In Progress</option>
                            <option value="LOST">Lost</option>
                            <option value="WON">Won</option>
                          </select>
                        )}
                        control={control}
                        rules={{ required: "Required" }}
                        name="status"
                      />
                    </div>
                  </div>
                  <ErrorMessage errors={errors} name="status" />
                </td>
              </tr>
              <tr>
                <td>Pricing Date:</td>
                <td>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <DateField
                        onChange={onChange}
                        value={value}
                        minDate={new Date()}
                      />
                    )}
                    control={control}
                    name="pricingDate"
                  />
                </td>
              </tr>
              <tr>
                <td>Close Date:</td>
                <td>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <DateField
                        onChange={onChange}
                        value={value}
                        minDate={new Date()}
                      />
                    )}
                    control={control}
                    name="closeDate"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="footer">
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:items-end">
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-auto sm:w-auto sm:text-sm"
              onClick={props.onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              {props.submitButtonLabel || "Done"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default OpportunityForm;
