import React from "react";
import { includes, map, prop } from "ramda";

import { Element } from "@pricing-tool/lib/products/treasury/core";

import "./styles.scss";
import { ElementsTableProps } from "./props";

const ElementsTable = (props: ElementsTableProps) => {
  if (!props.elements || props.elements.length === 0) {
    return <div className="p-4">No elements to match your search criteria</div>;
  }

  const elementWasPreviouslyAdded = (element: Element) =>
    includes(prop("id", element), props.previouslyAddedElementIds);

  const elementHasBeenAdded = (element: Element) =>
    includes(prop("id", element), props.addedElementIds) ||
    elementWasPreviouslyAdded(element);

  return (
    <table className="elements-table">
      <thead>
        <tr>
          <th scope="col">&nbsp;</th>
          <th scope="col">Element ID</th>
          <th scope="col">Name</th>
          <th scope="col">Product Family</th>
          <th scope="col">AFP Code</th>
        </tr>
      </thead>
      <tbody>
        {map((element) => {
          const checked = elementHasBeenAdded(element);
          const onCheckChanged = () =>
            checked ? props.onUnselect(element) : props.onSelect(element);
          return (
            <tr key={element.id} onClick={onCheckChanged}>
              <td>
                <input
                  type="checkbox"
                  checked={checked}
                  disabled={elementWasPreviouslyAdded(element)}
                  onChange={onCheckChanged}
                />
              </td>
              <td>{element.id}</td>
              <td>{element.name}</td>
              <td>{element.productFamily}</td>
              <td>{element.afpCode}</td>
            </tr>
          );
        }, props.elements)}
      </tbody>
    </table>
  );
};

export default ElementsTable;
