import React from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import { Outlet } from "react-router";

function SettingsPage() {
  return (
    <div className="page settings p-4 flex flex-row">
      <Helmet>
        <title>Settings</title>
      </Helmet>

      <div className="pt-4">
        <div className="border-r-2 pr-4">
          <div className="font-semibold mb-4">Settings</div>
          <div className="underline decoration-4 decoration-blue-200">
            <Link to="/settings/templates">Templates</Link>
          </div>
        </div>
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  );
}

export default SettingsPage;
