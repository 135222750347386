import React, { useCallback, useMemo, useState } from "react";
import { filter, find, includes, isEmpty, map } from "ramda";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Opportunity } from "@pricing-tool/lib/opportunity/core";
import { ProductType } from "@pricing-tool/lib/products/core";

import { toProductSummary } from "../../../utils/ProductSummary";

import "./styles.scss";

export type ProductsPickerProps = {
  opportunity: Opportunity;
  selectMultiple: boolean;
  selectedScenarioId: string;
  onConfirm: (productTypes: ProductType[]) => void;
  onCancel: () => void;
  onBack?: () => void;
};

const ProductsPicker = ({
  opportunity,
  selectMultiple,
  selectedScenarioId,
  onConfirm,
  onBack,
  onCancel,
}: ProductsPickerProps) => {
  const [selectedProductTypes, setSelectedProductTypes] = useState<
    ProductType[]
  >([]);
  const multiSelectAllowed = selectMultiple || false;

  const productSummaries = useMemo(() => {
    if (!selectedScenarioId) return [];

    const selectedScenario = find(
      (scenario) => scenario.id === selectedScenarioId,
      opportunity?.scenarios || [],
    );
    if (!selectedScenario) return [];

    return map(toProductSummary, selectedScenario.products || []);
  }, [opportunity, selectedScenarioId]);

  const checkProduct = useCallback(
    (productType: ProductType) =>
      setSelectedProductTypes([...selectedProductTypes, productType]),
    [selectedProductTypes],
  );

  const uncheckProductType = useCallback(
    (productType: ProductType) =>
      setSelectedProductTypes(
        filter(
          (_productType) => _productType !== productType,
          selectedProductTypes,
        ),
      ),
    [selectedProductTypes],
  );

  const _onConfirm = useCallback(() => {
    if (!selectedScenarioId) return;
    if (isEmpty(selectedProductTypes)) return;

    onConfirm(selectedProductTypes);
  }, [selectedProductTypes, onConfirm, selectedScenarioId]);

  return (
    <div className="products-picker">
      <div className="body">
        <>
          {onBack !== undefined && (
            <button onClick={onBack} className="back-button">
              <FontAwesomeIcon icon="chevron-left" /> Back
            </button>
          )}
          {isEmpty(productSummaries) && (
            <div className="empty">No products to display</div>
          )}
          {!isEmpty(productSummaries) && (
            <table className="products-table">
              <thead>
                <tr>
                  {multiSelectAllowed && <th>&nbsp;</th>}
                  <th scope="col">Product</th>
                  <th scope="col">Performance</th>
                </tr>
              </thead>
              <tbody>
                {map(
                  ({
                    name,
                    productType,
                    keyMetricName,
                    keyMetric,
                    keyMetricUnit,
                  }) => {
                    const checked = includes(productType, selectedProductTypes);
                    const onCheckChanged = () =>
                      checked
                        ? uncheckProductType(productType)
                        : checkProduct(productType);
                    return (
                      <tr
                        key={productType}
                        onClick={
                          multiSelectAllowed
                            ? onCheckChanged
                            : () => onConfirm([productType])
                        }
                      >
                        {multiSelectAllowed && (
                          <td className="check">
                            <input
                              type="checkbox"
                              checked={checked}
                              disabled={false}
                              onChange={onCheckChanged}
                            />
                          </td>
                        )}
                        <td>{name}</td>
                        <td>
                          {keyMetricName}:{" "}
                          {keyMetric && (
                            <b>
                              {keyMetricUnit === "dollar"
                                ? `$${keyMetric.toFormat(0)}`
                                : `${keyMetric.mul(100).toFormat(2)}%`}
                            </b>
                          )}
                        </td>
                      </tr>
                    );
                  },
                  productSummaries,
                )}
              </tbody>
            </table>
          )}
        </>
      </div>
      <div className="footer">
        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:items-end">
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-auto sm:w-auto sm:text-sm"
            onClick={onCancel}
          >
            Cancel
          </button>
          {multiSelectAllowed && (
            <>
              {isEmpty(selectedProductTypes) ? (
                <div className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 text-base font-medium text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100 sm:ml-3 sm:w-auto sm:text-sm">
                  Done
                </div>
              ) : (
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={_onConfirm}
                >
                  Done
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductsPicker;
