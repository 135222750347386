import React, { useCallback, useEffect, useState } from "react";

import { Opportunity } from "@pricing-tool/lib/opportunity/core";
import { Product } from "@pricing-tool/lib/products/core";

import OpportunityPicker from "../../fields/OpportunityPicker";
import ScenarioProductsPickerWorkflow from "../ScenarioProductsPickerWorkflow";

import "./styles.scss";

export type OpportunityScenarioProductsPickerWorkflowProps = {
  onConfirm: (scenarioId: string, products: Product[]) => void;
  onCancel: () => void;
};

const OpportunityScenarioProductsPickerWorkflow = ({
  onConfirm,
  onCancel,
}: OpportunityScenarioProductsPickerWorkflowProps) => {
  const [workflowState, setWorkflowState] =
    useState<"opportunity" | "scenario_products">("opportunity");
  const [selectedOpportunity, setSelectedOpportunity] =
    useState<Opportunity | undefined>();

  useEffect(() => {
    if (!selectedOpportunity) {
      setWorkflowState("opportunity");
      return;
    }

    setWorkflowState("scenario_products");
  }, [selectedOpportunity]);

  const _onConfirm = useCallback(
    (scenarioId, products) => {
      if (!selectedOpportunity) return;
      onConfirm(scenarioId, products);
    },
    [selectedOpportunity, onConfirm],
  );

  return (
    <div className="opportunity-scenario-products-picker-workflow">
      {workflowState === "opportunity" && (
        <OpportunityPicker
          onConfirm={setSelectedOpportunity}
          onCancel={onCancel}
        />
      )}

      {workflowState === "scenario_products" && (
        <>
          <ScenarioProductsPickerWorkflow
            opportunity={selectedOpportunity!}
            onConfirm={_onConfirm}
            onBack={() => setSelectedOpportunity(undefined)}
            onCancel={onCancel}
          />
        </>
      )}
    </div>
  );
};

export default OpportunityScenarioProductsPickerWorkflow;
