import React, { PropsWithChildren } from "react";

import { IncomeStatement } from "@pricing-tool/lib/incomeStatement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CurrencyAmount from "../CurrencyAmount";
import PercentAmount from "../PercentAmount";

import "./styles.scss";

export type IncomeStatementTableProps = {
  incomeStatement?: IncomeStatement;
  feeOrInterest: "fee" | "interest";
};

const IncomeStatementTable = ({
  incomeStatement,
  feeOrInterest,
  children,
}: PropsWithChildren<IncomeStatementTableProps>) => {
  const label = feeOrInterest === "fee" ? "Fee" : "Interest";
  return (
    <table className="income-statement-table">
      <tbody>
        <tr>
          <td>{label} Income</td>
          <td>&nbsp;</td>
          <td>
            <CurrencyAmount amount={incomeStatement?.feeOrInterestIncome} />
          </td>
        </tr>
        <tr>
          <td>{label} Expense</td>
          <td>
            <FontAwesomeIcon className="text-gray-500" icon="minus" size="lg" />
          </td>
          <td>
            <CurrencyAmount amount={incomeStatement?.feeOrInterestExpense} />
          </td>
        </tr>
        <tr className="total">
          <td>Net {label} Income</td>
          <td>&nbsp;</td>
          <td>
            <CurrencyAmount amount={incomeStatement?.netFeeOrInterestIncome} />
          </td>
        </tr>
        <tr>
          <td>
            {feeOrInterest === "fee"
              ? "ECA (non-fee expense)"
              : "Non-Interest Expense"}
          </td>
          <td>
            <FontAwesomeIcon className="text-gray-500" icon="minus" size="lg" />
          </td>
          <td>
            <CurrencyAmount amount={incomeStatement?.nonFeeOrInterestExpense} />
          </td>
        </tr>
        {incomeStatement?.loanLossReserves && (
          <tr>
            <td>Loan Loss Reserves</td>
            <td>
              <FontAwesomeIcon
                className="text-gray-500"
                icon="minus"
                size="lg"
              />
            </td>
            <td>
              <CurrencyAmount amount={incomeStatement?.loanLossReserves} />
            </td>
          </tr>
        )}
        <tr className="total">
          <td>Pretax Income</td>
          <td>&nbsp;</td>
          <td>
            <CurrencyAmount amount={incomeStatement?.pretaxIncome} />
          </td>
        </tr>
        <tr>
          <td>Taxes</td>
          <td>
            <FontAwesomeIcon className="text-gray-500" icon="minus" size="lg" />
          </td>
          <td>
            <CurrencyAmount amount={incomeStatement?.taxes} />
          </td>
        </tr>
        <tr className="total">
          <td>
            <strong>Net Income</strong>
          </td>
          <td>&nbsp;</td>
          <td>
            <CurrencyAmount amount={incomeStatement?.netIncome} />
          </td>
        </tr>
        {incomeStatement?.returnOnAssets && (
          <tr>
            <td>
              <strong>ROA</strong>
            </td>
            <td>&nbsp;</td>
            <td>
              <PercentAmount amount={incomeStatement?.returnOnAssets} />
            </td>
          </tr>
        )}
        {incomeStatement?.returnOnEquity && (
          <tr>
            <td>
              <strong>ROE</strong>
            </td>
            <td>&nbsp;</td>
            <td>
              <PercentAmount amount={incomeStatement?.returnOnEquity} />
            </td>
          </tr>
        )}
        {children}
      </tbody>
    </table>
  );
};

export default IncomeStatementTable;
