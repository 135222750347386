import { useCallback, useState } from "react";

import {
  Client,
  useSearchClientsByNameLazyQuery,
} from "@pricing-tool/graphql/lib/react";

import ClientSearchComponent from "./component";

import "./styles.scss";

export type ClientSearchProps = {
  value?: Client;
  onChange: (client: Client) => void;
};

const ClientSearch = (props: ClientSearchProps) => {
  const [searchTerm, setSearchTerm] = useState("");

  const [searchClients, { data, loading }] = useSearchClientsByNameLazyQuery();

  const onSearch = useCallback(
    (searchTerm) => {
      console.log("searching", searchTerm);

      if (!searchTerm || searchTerm === "") return;

      void searchClients({
        variables: {
          branchId: "branch-1",
          searchTerm: searchTerm,
        },
      });
    },
    [searchClients],
  );

  return (
    <ClientSearchComponent
      selectedClient={props.value}
      searchTerm={searchTerm}
      searching={loading}
      searchResults={data?.searchClientsByName?.items || []}
      onSearchTermUpdated={setSearchTerm}
      onSearch={onSearch}
      onSelectClient={props.onChange}
    />
  );
};

export default ClientSearch;
