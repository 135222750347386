import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { includes } from "ramda";

import DollarInput from "../../../../../common/DollarInput";
import PercentInput from "../../../../../common/PercentInput";
import InputWithIcon from "../../../../../common/InputWithIcon";
import { Modifier } from "../../../../../common/Input";

import "./styles.scss";
import { TreasuryServiceRowProps } from "./props";

export interface TreasuryServiceRowRef {
  discount: HTMLInputElement | null;
  price: HTMLInputElement | null;
  volume: HTMLInputElement | null;
}

const TreasuryServiceRow = forwardRef<
  TreasuryServiceRowRef,
  TreasuryServiceRowProps
>(
  (
    {
      treasuryService,
      selectedColumn,
      selectedKeys,
      onDiscountChange,
      onPriceChange,
      onMonthlyVolumeChange,
      onInputSelect,
      onInputDeselect,
      onDelete,
    }: TreasuryServiceRowProps,
    ref,
  ) => {
    const key = treasuryService.element.id;
    const onSelect =
      (column: string) => (modifier: Modifier, input: HTMLInputElement) =>
        onInputSelect(key, column, modifier, input);

    const selected = includes(key, selectedKeys);

    const discountInputRef = useRef<HTMLInputElement>(null);
    const priceInputRef = useRef<HTMLInputElement>(null);
    const volumeInputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(
      ref,
      () => ({
        get discount() {
          return discountInputRef.current;
        },
        get price() {
          return priceInputRef.current;
        },
        get volume() {
          return volumeInputRef.current;
        },
      }),
      [discountInputRef, priceInputRef, volumeInputRef],
    );

    // @ts-ignore
    return (
      <tr className="treasury-service">
        <td className="field whitespace-nowrap w-20 pr-4">
          {treasuryService.element.id}
        </td>
        <td className="field whitespace-nowrap w-20 pr-4">
          {treasuryService.element.afpCode}
        </td>
        <td className="field lg:w-1/2 pr-4">{treasuryService.element.name}</td>
        <td className="field w-32 pr-4">
          <PercentInput
            ref={discountInputRef}
            onSelect={onSelect("discount")}
            onDeselect={onInputDeselect}
            value={treasuryService.discount}
            selected={selected && selectedColumn === "discount"}
            onChange={onDiscountChange}
            className="w-32"
          />
        </td>
        <td className="field w-20 pr-4">
          <InputWithIcon
            ref={volumeInputRef}
            onSelect={onSelect("volume")}
            onDeselect={onInputDeselect}
            value={treasuryService.monthlyVolume.toString()}
            selected={selected && selectedColumn === "volume"}
            onChange={(value) => onMonthlyVolumeChange(+value)}
            className="w-20"
          />
        </td>
        <td className="field w-36 pr-4">
          <DollarInput
            ref={priceInputRef}
            onSelect={onSelect("price")}
            onDeselect={onInputDeselect}
            value={treasuryService.price}
            selected={selected && selectedColumn === "price"}
            onChange={onPriceChange}
            decimalPlaces={6}
            className="w-36"
          />
        </td>
        <td className="field whitespace-nowrap px-2 max-w-30 text-right">
          ${treasuryService.revenue.toFormat(6)}
        </td>
        <td className="field whitespace-nowrap px-2 max-w-30 text-right">
          ${treasuryService.profit.toFormat(6)}
        </td>
        <td className="field whitespace-nowrap px-2 max-w-30 text-right text-gray-300 hover:text-red-300">
          <button onClick={onDelete}>
            <FontAwesomeIcon icon="minus-square" size="lg" />
          </button>
        </td>
      </tr>
    );
  },
);

export default TreasuryServiceRow;
