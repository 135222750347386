import { Ref, useEffect, useRef } from "react";

// attribution - https://gist.github.com/pie6k/b4717f392d773a71f67e110b42927fea
export default function useShareForwardedRef<T>(forwardedRef: Ref<T>) {
  // final ref that will share value with forward ref. this is the one we will attach to components
  const innerRef = useRef<T>(null);

  useEffect(() => {
    // after every render - try to share current ref value with forwarded ref
    if (!forwardedRef) {
      return;
    }
    if (typeof forwardedRef === "function") {
      forwardedRef(innerRef.current);
      return;
    } else {
      // by default forwardedRef.current is readonly. Let's ignore it
      // @ts-ignore
      forwardedRef.current = innerRef.current;
    }
  });

  return innerRef;
}
