import { Schema as S } from "@effect/schema";

import { Deposits, DepositsSchema } from "./index";

export type DepositsDto = S.Schema.Encoded<typeof DepositsSchema>;

export const fromDto = (dto: DepositsDto): Deposits => {
  return S.decodeUnknownSync(DepositsSchema)(dto);
};

export const toDto = (deposits: Deposits): DepositsDto => {
  return S.encodeUnknownSync(DepositsSchema)(deposits);
};
