import React from "react";
import { Observable } from "rxjs";
import Decimal from "decimal.js-light";

import { CollateralModel } from "@pricing-tool/lib/products/ci/model/CollateralModel";
import { CollateralRecoveryTable } from "@pricing-tool/lib/products/ci/core/CollateralRecoveryTable";

import Modal from "../../../common/Modal";
import CollateralForm from "../../forms/CollateralForm";

export type CollateralModalProps = {
  collateralModel: CollateralModel;
  collateralRecoveryTable: CollateralRecoveryTable;
  loanAmount: Observable<Decimal | undefined>;
  onCancel: () => void;
  onConfirm: () => void;
};

const CollateralModal = ({
  collateralModel,
  collateralRecoveryTable,
  loanAmount,
  onCancel,
  onConfirm,
}: CollateralModalProps) => {
  return (
    <Modal
      title={"Collateral"}
      width={950}
      contentHeight={400}
      onClose={onCancel}
    >
      <CollateralForm
        collateralModel={collateralModel}
        collateralRecoveryTable={collateralRecoveryTable}
        loanAmount={loanAmount}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </Modal>
  );
};

export default CollateralModal;
