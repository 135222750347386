import React from "react";

import { Product } from "@pricing-tool/lib/products/core";
import { Opportunity } from "@pricing-tool/lib/opportunity/core";

import Modal from "../../../../common/Modal";
import ScenarioProductsPickerWorkflow from "../../workflows/ScenarioProductsPickerWorkflow";

import "./styles.scss";

export type ScenarioProductsPickerModalProps = {
  opportunity: Opportunity;
  excludedScenarioId?: string;
  onCancel: () => void;
  onConfirm: (scenarioId: string, products: Readonly<Product[]>) => void;
};

const ScenarioProductsPickerModal = ({
  opportunity,
  excludedScenarioId,
  onCancel,
  onConfirm,
}: ScenarioProductsPickerModalProps) => {
  return (
    <div className="scenario-products-picker-modal">
      <Modal
        title="Select Products"
        width={500}
        contentHeight={300}
        onClose={onCancel}
      >
        <ScenarioProductsPickerWorkflow
          opportunity={opportunity}
          excludedScenarioId={excludedScenarioId}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      </Modal>
    </div>
  );
};

export default ScenarioProductsPickerModal;
