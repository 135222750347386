import { useEffect } from "react";
import { Combobox } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Client } from "@pricing-tool/graphql/lib/react";

import useDebounce from "../../../../hooks/useDebounce";

import "./styles.scss";

export type ClientSearchProps = {
  selectedClient?: Client;
  searchTerm?: string;
  searching: boolean;
  searchResults: Client[];
  onSearchTermUpdated: (searchTerm: string) => void;
  onSearch: (searchTerm: string) => void;
  onSelectClient: (client: Client) => void;
};

const ClientSearch = ({
  selectedClient,
  searchTerm,
  searching,
  searchResults,
  onSearchTermUpdated,
  onSearch,
  onSelectClient,
}: ClientSearchProps) => {
  const { onChange: debouncedInputChanged } = useDebounce(300, onSearch);

  useEffect(() => {
    console.log("selectedClient", selectedClient);
  }, [selectedClient]);

  return (
    <div className="component client-search">
      <Combobox
        value={selectedClient || null}
        onChange={onSelectClient}
        nullable
      >
        {({ open }) => (
          <div className="relative">
            <div
              className={`relative w-full cursor-default overflow-hidden rounded-md bg-white text-left shadow-sm focus:ring-2 focus:ring-blue-500 ${
                open ? "ring-2 ring-blue-400" : ""
              }`}
            >
              <Combobox.Input
                className="w-full border border-gray-300 rounded-md py-1 pl-2 pr-2 text-gray-900"
                displayValue={(value: Client) => {
                  console.log("displayValue", value);
                  return value?.name;
                }}
                onChange={(event) => {
                  onSearchTermUpdated(event.target.value);
                  debouncedInputChanged(event);
                }}
              />
              <Combobox.Button className="absolute w-full inset-y-0 left-0 right-0">
                &nbsp;
              </Combobox.Button>
            </div>
            <Combobox.Options className="absolute z-10 w-full mt-1 max-h-52 overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:ring-2 focus:ring-blue-500">
              {searchResults.length === 0 && searchTerm === "" ? (
                <Combobox.Option
                  key="instr"
                  disabled={true}
                  className="relative cursor-default select-none py-2 px-4"
                  value=""
                >
                  Search by name
                </Combobox.Option>
              ) : searching ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-300">
                  Searching
                </div>
              ) : searchResults.length === 0 && searchTerm !== "" ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                searchResults.map((client) => (
                  <Combobox.Option
                    key={client.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-blue-600 text-white" : "text-gray-900"
                      }`
                    }
                    value={client}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {client.name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-white" : "text-blue-600"
                            }`}
                          >
                            <FontAwesomeIcon
                              icon="check"
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </div>
        )}
      </Combobox>
    </div>
  );
};

export default ClientSearch;
