import { useEffect, useState } from "react";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";

import apolloClient from "../apollo/client";

export const useApolloClient = () => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();

  useEffect(() => {
    async function init() {
      setClient(apolloClient);
    }

    init()
      .then(() => console.log("Apollo initialization completed"))
      .catch((err) => console.error(err));
  }, []);

  return {
    client,
  };
};
