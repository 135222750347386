import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import InputWithIcon from "../../../common/InputWithIcon";
import TextArea from "../../../common/TextArea";

import "./styles.scss";

export interface DocumentFormValue {
  name: string;
  description?: string | null;
  file?: File;
}

const defaultDocumentFormValue: DocumentFormValue = {
  name: "",
  description: "",
  file: undefined,
};

export interface DocumentFormProps {
  document?: DocumentFormValue;
  onCancel: () => void;
  onSubmit: (input: DocumentFormValue) => void;
  onDelete?: () => void;
  onDownloadFile?: () => void;
}

const DocumentForm: React.FC<DocumentFormProps> = (props) => {
  const { document, onCancel, onSubmit, onDelete, onDownloadFile } = props;

  useEffect(() => {
    if (!document) return;
    setValue("name", document?.name);
    setValue("description", document?.description);
  }, [props.document]);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<DocumentFormValue>({
    defaultValues: document || defaultDocumentFormValue,
  });

  return (
    <div className="component document-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="body flex flex-col gap-4 p-4">
          <div>
            <Controller
              render={({ field: { onChange, value, ref } }) => (
                <InputWithIcon
                  onChange={onChange}
                  value={value}
                  ref={ref}
                  label="Name"
                />
              )}
              rules={{ required: "Required" }}
              control={control}
              name="name"
            />
            <ErrorMessage
              as={<div className="text-sm text-red-500" />}
              errors={errors}
              name="name"
            />
          </div>
          <div>
            <Controller
              render={({ field: { onChange, value } }) => (
                <TextArea
                  label="Description"
                  onChange={onChange}
                  value={value || ""}
                  className="w-full resize-none"
                  rows={2}
                />
              )}
              control={control}
              rules={{ required: "Required" }}
              name="description"
            />
            <ErrorMessage
              as={<div className="text-sm text-red-500" />}
              errors={errors}
              name="description"
            />
          </div>
          <div>
            <label className="block" htmlFor="file">
              File
            </label>
            {onDownloadFile && (
              <div>
                <button type="button" onClick={onDownloadFile}>
                  Download current file
                </button>
              </div>
            )}
            <input
              onChange={(event) => {
                setValue(
                  "file",
                  event.target.files ? event.target.files[0] : undefined,
                );
              }}
              type="file"
              id="file"
            />
            <ErrorMessage
              as={<div className="text-sm text-red-500" />}
              errors={errors}
              name="file"
            />
          </div>
        </div>
        <div className="footer">
          <div className={`flex justify-between px-4 py-3`}>
            <div>
              {props.onDelete && (
                <button
                  type="button"
                  className="text-red-400 mt-3 w-full inline-flex justify-center rounded-md border border-red-400 shadow-sm px-4 py-2 bg-white text-base font-medium hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600 sm:mt-0 sm:ml-auto sm:w-auto sm:text-sm"
                  onClick={onDelete}
                >
                  Delete
                </button>
              )}
            </div>
            <div>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-auto sm:w-auto sm:text-sm"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DocumentForm;
