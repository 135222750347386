import Decimal from "decimal.js-light";
import * as moment from "moment";
import { IndexSchema } from "../core/IndexRates";

const AMERIBOR = IndexSchema.make("AMERIBOR");
const DFF = IndexSchema.make("DFF");
const SOFR = IndexSchema.make("SOFR");

export default [
  {
    date: moment("2022-07-01").toDate(),
    indexRates: [
      { index: AMERIBOR, rate: new Decimal(0.027) },
      { index: DFF, rate: new Decimal(0.0319) },
      { index: SOFR, rate: new Decimal(0.0228) },
    ],
  },
  {
    date: moment("2022-08-01").toDate(),
    indexRates: [
      { index: AMERIBOR, rate: new Decimal(0.027) },
      { index: DFF, rate: new Decimal(0.0319) },
      { index: SOFR, rate: new Decimal(0.0228) },
    ],
  },
  {
    date: moment("2022-09-01").toDate(),
    indexRates: [
      { index: AMERIBOR, rate: new Decimal(0.027) },
      { index: DFF, rate: new Decimal(0.0319) },
      { index: SOFR, rate: new Decimal(0.0228) },
    ],
  },
  {
    date: moment("2022-10-01").toDate(),
    indexRates: [
      { index: AMERIBOR, rate: new Decimal(0.027) },
      { index: DFF, rate: new Decimal(0.0319) },
      { index: SOFR, rate: new Decimal(0.0228) },
    ],
  },
];
