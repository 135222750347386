import React, { useEffect } from "react";

import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Conversation from "../forms/Conversation";

export type RightDockProps = {
  branchId: string;
  opportunityId: string;
  onStatusChange: (expanded: boolean) => void;
};

const RightDock = ({
  branchId,
  opportunityId,
  onStatusChange,
}: RightDockProps) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  useEffect(() => {
    onStatusChange(isExpanded);
  }, [isExpanded]);

  return (
    <div className="component right-dock">
      <div className="button-panel">
        <div className="top-buttons">
          <button onClick={() => setIsExpanded(true)}>
            <FontAwesomeIcon icon={["far", "comment-alt"]} />
          </button>
        </div>
        <div className="bottom-buttons">
          {isExpanded && (
            <button onClick={() => setIsExpanded(false)}>
              <FontAwesomeIcon icon={["fas", "angle-double-right"]} />
            </button>
          )}
        </div>
      </div>
      {isExpanded && (
        <div className="expanded-panel">
          <Conversation branchId={branchId} opportunityId={opportunityId} />
        </div>
      )}
    </div>
  );
};

export default RightDock;
