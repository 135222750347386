import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  anyPass,
  ascend,
  complement,
  filter,
  includes,
  map,
  pipe,
  prop,
  sortWith,
  uniqBy,
} from "ramda";

import { Element } from "@pricing-tool/lib/products/treasury/core";
import {
  getPrerequisiteElements,
  hasElementAsPrerequisite,
} from "@pricing-tool/lib/products/treasury/core/Element";

import ElementsTable from "../../tables/ElementsTable";

import "./styles.scss";
import { AddElementsFormProps } from "./props";

const AddElementsForm = (props: AddElementsFormProps) => {
  const sortElements = (elements: Element[]) =>
    sortWith([ascend(prop("afpCode")), ascend(prop("id"))], elements);

  const [searchText, setSearchText] = React.useState("");
  const [filteredElements, setFilteredElements] = React.useState(
    sortElements(props.elements),
  );
  const [addedElements, setAddedElements] = React.useState<Element[]>([]);
  const searchFieldRef = React.useRef<HTMLInputElement>(null);

  const elementMatchesSearchText = (searchText: string) => (element: Element) =>
    searchText.length === 0 ||
    element.id.startsWith(searchText) ||
    element.afpCode.startsWith(searchText) ||
    element.name.toUpperCase().includes(searchText.toUpperCase()) ||
    element.productFamily.toUpperCase().includes(searchText.toUpperCase());

  const filterElements = () => {
    pipe(
      filter(elementMatchesSearchText(searchText)),
      sortElements,
      setFilteredElements,
    )(props.elements);
  };

  const focusSearchField = () => {
    if (searchFieldRef.current) {
      (searchFieldRef.current as HTMLInputElement).focus();
    }
  };

  React.useEffect(focusSearchField, [searchFieldRef]);
  React.useEffect(filterElements, [searchText, addedElements, props.elements]);

  const addElement = (element: Element) => {
    const prerequisiteElements = getPrerequisiteElements(
      props.elements,
      element,
    );
    const newAddedElements = pipe(
      filter(
        (element: Element) => !includes(element.id, props.addedElementIds),
      ),
      uniqBy(prop("id")),
    )([...addedElements, element, ...prerequisiteElements] as Element[]);
    setAddedElements(newAddedElements);
    focusSearchField();
  };

  const removeElement = (element: Element) => {
    const isElement = (e: Element) => e.id === element.id;
    const hasAsPrerequisite = (e: Element) =>
      hasElementAsPrerequisite(props.elements, e, element);
    const predicate = complement(anyPass([isElement, hasAsPrerequisite]));
    setAddedElements(filter(predicate, addedElements));
    focusSearchField();
  };

  return (
    <div className="add-elements-form">
      <div className="header relative mb-3 mx-3">
        <span className="absolute inset-y-0 left-0 flex items-center pl-2">
          <FontAwesomeIcon icon="search" className="text-gray-500" />
        </span>
        <input
          className="search-input w-full pl-7 py-1 border rounded-md bg-gray-100 text-sm"
          type="text"
          ref={searchFieldRef}
          onChange={(field) => setSearchText(field.target.value)}
          placeholder="Search for elements"
          value={searchText}
        />
        {searchText.length > 0 && (
          <button
            className="absolute top-0 right-0 rounded-l-none btn btn-primary pr-2 py-1"
            onClick={() => setSearchText("")}
          >
            <FontAwesomeIcon icon="times" />
          </button>
        )}
      </div>
      <div className="body overflow-auto">
        <div className="mx-3 mb-3">
          <div className="rounded-md border">
            <ElementsTable
              elements={filteredElements}
              previouslyAddedElementIds={props.addedElementIds}
              addedElementIds={map(prop("id"), addedElements)}
              onSelect={addElement}
              onUnselect={removeElement}
            />
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:items-end">
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-auto sm:w-auto sm:text-sm"
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => props.onConfirm(addedElements)}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddElementsForm;
