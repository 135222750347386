import React from "react";

import { Message } from "@pricing-tool/graphql/lib/react";

import MessageList from "../MessageList";
import MessageComposer from "../MessageComposer";

import "./styles.scss";

export type ConversationProps = {
  messages: Message[];
  moreMessages?: boolean;
  onLoadMore?: () => void;
  isLoadingMore?: boolean;
  onSend: (message: string) => Promise<any>;
  isSending?: boolean;
};

const Conversation = ({
  messages,
  moreMessages,
  onLoadMore,
  isLoadingMore,
  onSend,
  isSending,
}: ConversationProps) => {
  const [message, setMessage] = React.useState("");

  const handleSend = React.useCallback(() => {
    onSend(message).then(() => {
      setMessage("");
    });
  }, [onSend, message]);

  const handleLoadMore = React.useCallback(() => {
    if (onLoadMore) {
      onLoadMore();
    }
  }, [onLoadMore]);

  return (
    <div className="component conversation">
      <MessageList
        messages={messages}
        moreMessages={moreMessages}
        onLoadMore={handleLoadMore}
        isLoadingMore={isLoadingMore}
      />
      <MessageComposer
        message={message}
        disabled={isSending}
        onChange={setMessage}
        onSend={handleSend}
      />
    </div>
  );
};

export default Conversation;
