import { Disclosure } from "@headlessui/react";
import React, { useCallback, useMemo, useState } from "react";
import moment from "moment/moment";
import { filter, map, pipe } from "ramda";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Collateral,
  CommercialIndustrial,
  CommercialIndustrialDependencies,
  PaymentFrequency,
  PaymentType,
} from "@pricing-tool/lib/products/ci/core";
import { CommercialIndustrialModel } from "@pricing-tool/lib/products/ci/model/CommercialIndustrialModel";
import { CommercialRealEstateModel } from "@pricing-tool/lib/products/cre/model";
import {
  CommercialRealEstate,
  CommercialRealEstateDependencies,
} from "@pricing-tool/lib/products/cre/core";
import { CollateralRecoveryTable } from "@pricing-tool/lib/products/ci/core/CollateralRecoveryTable";
import { fromCollateral } from "@pricing-tool/lib/products/ci/model/CollateralModel";

import TitledPanel from "../TitledPanel";
import DollarInput from "../DollarInput";
import PercentInput from "../PercentInput";
import InputWithIcon from "../InputWithIcon";
import CurrencyAmount from "../CurrencyAmount";
import IncomeStatementTable from "../IncomeStatement";
import CollateralModal from "../../ci/modals/CollateralModal";

import "./styles.scss";
import {
  Index,
  IndexRate,
  IndexSchema,
} from "@pricing-tool/lib/products/ci/core/IndexRates";

const INDEXES: Index[] = [
  IndexSchema.make("AMERIBOR"),
  IndexSchema.make("DFF"),
  IndexSchema.make("SOFR"),
];
const INDEX_NAMES: Record<string, string> = {
  AMERIBOR: "Overnight Unsecured AMERIBOR Benchmark Interest Rate",
  DFF: "Federal Funds Effective Rate",
  DPRIME: "Bank Prime Loan Rate",
  SOFR: "Secured Overnight Financing Rate",
  IUDSOIA: "Daily Sterling Overnight Index Average (SONIA) Rate",
};

type LoanFormProps = {
  model: CommercialIndustrialModel | CommercialRealEstateModel;
  product: CommercialIndustrial | CommercialRealEstate;
  dependencies:
    | CommercialIndustrialDependencies
    | CommercialRealEstateDependencies;
  collateralRecoveryTable: CollateralRecoveryTable;
};

const LoanForm = ({
  model,
  product,
  dependencies,
  collateralRecoveryTable,
}: LoanFormProps) => {
  const [shouldShowCollateralModal, setShouldShowCollateralModal] =
    useState(false);
  // used to "reset" the collateral when the modal is canceled
  const [capturedCollateral, setCapturedCollateral] = useState<
    Collateral | undefined
  >();

  const shouldShowUpdateRatesButton = useMemo(() => {
    const latestIndexRates = dependencies.latestIndexRates;
    const latestFundingCurve = dependencies.latestFundingCurve;

    const currentIndexRates = product?.indexRatesSnapshot?.indexRates;
    if (!currentIndexRates || !latestIndexRates) return false;

    for (const latestIndexRate of latestIndexRates) {
      const currentIndexRate = currentIndexRates.find(
        ({ index }) => index === latestIndexRate.index,
      );
      if (!currentIndexRate) return true;
      if (moment(latestIndexRate.date).isAfter(currentIndexRate.date))
        return true;
    }

    const latestFundingCurveDate = latestFundingCurve.date;
    const currentFundingCurveDate =
      product?.fundingCurveSnapshot?.fundingCurve.date;
    if (!currentFundingCurveDate) return true;
    if (moment(latestFundingCurveDate).isAfter(currentFundingCurveDate))
      return true;

    return false;
  }, [dependencies.latestIndexRates, product?.indexRatesSnapshot]);

  const updateRates = useCallback(() => {
    model.input.indexRatesSnapshot.next({
      indexRates: dependencies.latestIndexRates,
      createdAt: new Date(),
    });
    model.input.fundingCurveSnapshot.next({
      fundingCurve: dependencies.latestFundingCurve,
      createdAt: new Date(),
    });
  }, [dependencies.latestIndexRates]);

  const showCollateralModal = () => {
    setCapturedCollateral(product?.collateral);
    setShouldShowCollateralModal(true);
  };

  const cancelCollateralModal = () => {
    const collateralModel = fromCollateral(
      capturedCollateral,
      model.input.loanAmount,
      { collateralRecoveryTable },
    );
    model.input.collateralInput.collateralItems.next(
      collateralModel.input.collateralItems.getValue(),
    );
    setShouldShowCollateralModal(false);
    setCapturedCollateral(undefined);
  };

  const confirmCollateralModal = () => {
    setShouldShowCollateralModal(false);
    setCapturedCollateral(undefined);
  };

  return (
    <div className="component loan-form">
      <div className="two-column">
        <div className="col">
          <TitledPanel title="Inputs">
            <table className="inputs-table">
              <tbody>
                <tr>
                  <td>Loan Amount:</td>
                  <td>
                    <DollarInput
                      value={product.loanAmount}
                      onChange={(value) => model.input.loanAmount.next(value)}
                      decimalPlaces={0}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Index:</td>
                  <td>
                    <div className="flex text-nowrap">
                      <select
                        className="index rounded-md shadow-sm p-2"
                        value={product.interest?.index}
                        onChange={(event) =>
                          model.input.interestInput.index.next(
                            event.target.value === "none"
                              ? undefined
                              : IndexSchema.make(event.target.value),
                          )
                        }
                      >
                        <option value="none">-</option>
                        {pipe(
                          filter(({ index }) => INDEXES.includes(index)), // TODO - support more
                          map(({ index, date, rate }: IndexRate) => (
                            <option key={index} id={index} value={index}>
                              [{index}] {rate.toFormat(5)}% (
                              {moment(date).format("LL")}) -{" "}
                              {INDEX_NAMES[index]}
                            </option>
                          )),
                        )(product?.indexRatesSnapshot?.indexRates || [])}
                      </select>
                      {shouldShowUpdateRatesButton && (
                        <button
                          onClick={updateRates}
                          className="bg-transparent text-blue-500 text-sm py-2 px-2 rounded"
                        >
                          Update rates
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Spread:</td>
                  <td>
                    <PercentInput
                      dp={5}
                      value={product.interest?.spread}
                      onChange={(value) =>
                        model.input.interestInput.spread.next(value)
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Interest Rate:</td>
                  <td>
                    <PercentInput
                      dp={5}
                      disabled={true}
                      onChange={() => {}}
                      value={product.interest?.interestRate}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Payment Type:</td>
                  <td>
                    <select
                      className="rounded-md shadow-sm p-2"
                      value={product.payments?.paymentType}
                      onChange={(event) =>
                        model.input.amortizationInput.paymentType.next(
                          event.target.value === "none"
                            ? undefined
                            : (event.target.value as PaymentType),
                        )
                      }
                    >
                      <option value="none">-</option>
                      <option value="mortgage_amortization">
                        Mortgage Amortization
                      </option>
                      {/*<option value="straight_line">Straight Line</option>*/}
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Payment Frequency:</td>
                  <td>
                    <select
                      className="rounded-md shadow-sm p-2"
                      value={product.payments?.paymentFrequency}
                      onChange={(event) =>
                        model.input.amortizationInput.paymentFrequency.next(
                          event.target.value === "none"
                            ? undefined
                            : (event.target.value as PaymentFrequency),
                        )
                      }
                    >
                      <option value="none">-</option>
                      <option value="monthly">Monthly</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>Term (years):</td>
                  <td>
                    <InputWithIcon
                      value={
                        product.payments?.termYears
                          ? `${product.payments.termYears}`
                          : ""
                      }
                      onChange={(value) =>
                        !isNaN(parseInt(value))
                          ? model.input.amortizationInput.termYears.next(
                              parseInt(value),
                            )
                          : model.input.amortizationInput.termYears.next(
                              undefined,
                            )
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Amortization (years):</td>
                  <td>
                    <InputWithIcon
                      value={
                        product.payments?.amortizationYears
                          ? `${product.payments.amortizationYears}`
                          : ""
                      }
                      onChange={(value) =>
                        !isNaN(parseInt(value))
                          ? model.input.amortizationInput.amortizationYears.next(
                              parseInt(value),
                            )
                          : model.input.amortizationInput.amortizationYears.next(
                              undefined,
                            )
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>Collateral:</td>
                  <td>
                    <div className="flex w-full">
                      <button
                        className="mr-2 bg-transparent text-blue-500 text-base rounded"
                        onMouseDown={showCollateralModal}
                      >
                        <FontAwesomeIcon icon="pen-square" size="lg" />
                      </button>
                      <CurrencyAmount
                        amount={product.collateral?.effectiveValue}
                        decimalPlaces={0}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </TitledPanel>
        </div>

        <div className="col">
          <TitledPanel title="Outputs">
            <IncomeStatementTable
              incomeStatement={product.incomeStatement}
              feeOrInterest="interest"
            />
          </TitledPanel>
        </div>
      </div>

      <div className="mx-auto w-full">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`w-full flex justify-start items-center p-2 bg-blue-100 ${
                  open ? "rounded-t-md" : "rounded-md"
                }`}
              >
                <span className="inline-block w-6 text-sm">
                  <FontAwesomeIcon
                    icon={open ? "chevron-down" : "chevron-right"}
                  />
                </span>
                <span>Amortization Summary</span>
              </Disclosure.Button>
              <Disclosure.Panel className="p-2 pl-8 border-2 border-solid border-blue-100 rounded-b-md">
                <table className="inputs-table">
                  <tbody>
                    <tr>
                      <td>Effective Interest Rate:</td>
                      <td>
                        {product.payments?.amortizationSummary?.effectiveInterestRate
                          .mul(100)
                          .toFormat(2)}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>Number of Payments:</td>
                      <td>
                        {
                          product.payments?.amortizationSummary
                            ?.numberOfPayments
                        }
                      </td>
                    </tr>
                    <tr>
                      <td>Total Paid:</td>
                      <td>
                        $
                        {product.payments?.amortizationSummary?.totalPaid.toFormat(
                          2,
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Interest Paid:</td>
                      <td>
                        $
                        {product.payments?.amortizationSummary?.interestPaid.toFormat(
                          2,
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Balloon Payments:</td>
                      <td>
                        $
                        {product.payments?.amortizationSummary?.balloonPaid.toFormat(
                          2,
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Average Balance:</td>
                      <td>
                        $
                        {product.payments?.amortizationSummary?.averageBalance.toFormat(
                          2,
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>

      <div className="mx-auto my-4 w-full">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`w-full flex justify-start items-center p-2 bg-blue-100 ${
                  open ? "rounded-t-md" : "rounded-md"
                }`}
              >
                <span className="inline-block w-6 text-sm">
                  <FontAwesomeIcon
                    icon={open ? "chevron-down" : "chevron-right"}
                  />
                </span>
                <span>Amortization Schedule</span>
              </Disclosure.Button>
              <Disclosure.Panel className="p-2 pl-8 border-2 border-solid border-blue-100 rounded-b-md">
                <table className="w-full">
                  <thead>
                    <tr>
                      <td>Date</td>
                      <td>Days Since Last</td>
                      <td>Interest Rate</td>
                      <td>Beginning Balance</td>
                      <td>Payment</td>
                      <td>Interest</td>
                      <td>Principal</td>
                      <td>Ending Balance</td>
                    </tr>
                  </thead>
                  <tbody>
                    {map(
                      (payment) => (
                        <tr key={`payment-${payment.date}`}>
                          <td>{moment(payment.date).format("LL")}</td>
                          <td>{payment.daysSinceLastPayment}</td>
                          <td>{payment.interestRate.mul(100).toFormat(2)}%</td>
                          <td>${payment.beginningBalance.toFormat(2)}</td>
                          <td>${payment.payment.toFormat(2)}</td>
                          <td>${payment.interest.toFormat(2)}</td>
                          <td>${payment.principal.toFormat(2)}</td>
                          <td>${payment.endingBalance.toFormat(2)}</td>
                        </tr>
                      ),
                      product.payments?.amortizationSchedule || [],
                    )}
                  </tbody>
                </table>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>

      <>
        {shouldShowCollateralModal && (
          <CollateralModal
            collateralModel={{
              input: model.input.collateralInput,
              output: model.output.collateralOutput,
            }}
            collateralRecoveryTable={collateralRecoveryTable}
            loanAmount={model.input.loanAmount}
            onConfirm={confirmCollateralModal}
            onCancel={cancelCollateralModal}
          />
        )}
      </>

      {/*<pre className="text-gray-300">*/}
      {/*  {commercialIndustrial && JSON.stringify(commercialIndustrial, null, 2)}*/}
      {/*</pre>*/}
    </div>
  );
};

export default LoanForm;
