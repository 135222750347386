import { map } from "ramda";
import { Schema as S } from "@effect/schema";

import { Product, ProductSchema } from "./core";

import {
  fromDto as fromTreasuryDto,
  toDto as toTreasuryDto,
} from "./treasury/core/dto";
import {
  fromDto as fromCommercialIndustrialDto,
  toDto as toCommercialIndustrialDto,
} from "./ci/core/dto";
import {
  fromDto as fromDepositsDto,
  toDto as toDepositsDto,
} from "./deposits/core/dto";
import {
  fromDto as fromCommercialRealEstate,
  toDto as toCommercialRealEstate,
} from "./cre/core/dto";

export type ProductDto = S.Schema.Encoded<typeof ProductSchema>;

export const fromDto = (dto: string): Product[] => {
  const productsDto = JSON.parse(dto);

  return map((product: ProductDto) => {
    if (product._type === "treasury") {
      return fromTreasuryDto(product);
    } else if (product._type === "ci") {
      return fromCommercialIndustrialDto(product);
    } else if (product._type === "deposits") {
      return fromDepositsDto(product);
    } else if (product._type === "cre") {
      return fromCommercialRealEstate(product);
    } else {
      throw Error(
        // @ts-ignore
        `fromDto of product type ${product._type} not implemented yet`,
      );
    }
  }, productsDto || []);
};

export const toDto = (products: Product[]): string => {
  const productsDto = map((product) => {
    if (product._type === "treasury") {
      return toTreasuryDto(product);
    } else if (product._type === "ci") {
      return toCommercialIndustrialDto(product);
    } else if (product._type === "deposits") {
      return toDepositsDto(product);
    } else if (product._type === "cre") {
      return toCommercialRealEstate(product);
    } else {
      // @ts-ignore
      throw Error(`toDto of product type ${product._type} not implemented yet`);
    }
  }, products);

  return JSON.stringify(productsDto || []);
};
