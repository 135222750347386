import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProductProps } from "./props";

const Component = ({
  product,
  selected,
  selectProduct,
  removeProduct,
}: ProductProps) => {
  const { name, keyMetricName, keyMetricUnit, keyMetric } = product;
  return (
    <div
      className={`flex flex-none border-l-2 ${
        selected ? "border-blue-600" : "border-gray-200"
      } w-min`}
    >
      <div
        className="flex px-2 flex-col text-sm justify-evenly w-32"
        onClick={selectProduct}
      >
        <button className="hover:underline decoration-4 decoration-blue-200">
          <div className="text-left truncate">{name}</div>
        </button>
        <div className="whitespace-nowrap">
          {keyMetricName}:{" "}
          {keyMetric && (
            <b>
              {keyMetricUnit === "dollar"
                ? `$${keyMetric.toFormat(0)}`
                : `${keyMetric.mul(100).toFormat(2)}%`}
            </b>
          )}
        </div>
      </div>
      <button
        onClick={(e) => {
          e.preventDefault();
          removeProduct();
        }}
        className="flex flex-none w-8 aspect-square justify-center items-center rounded-md text-gray-300 hover:text-red-300"
      >
        <FontAwesomeIcon icon="minus-square" />
      </button>
    </div>
  );
};

export default Component;
