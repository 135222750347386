import React from "react";
import { map, sortBy } from "ramda";
import { match } from "ts-pattern";
import moment from "moment/moment";
import { useNavigate } from "react-router";

import { OpportunitySummary } from "@pricing-tool/lib/opportunitySummary/core";

import {
  constructPerformance,
  PerformanceTargets,
} from "../../../../utils/constructPerformance";
import PerformanceMetric from "../../fields/PerformanceMetric";

import "./styles.scss";

export type OpportunitiesTableProps = {
  opportunitySummaries: OpportunitySummary[];
  performanceTargets: PerformanceTargets;
  onSelect?: (opportunity: OpportunitySummary) => void;
};

const OpportunitiesTable = ({
  opportunitySummaries,
  performanceTargets,
  onSelect,
}: OpportunitiesTableProps) => {
  const navigate = useNavigate();

  if (!opportunitySummaries || opportunitySummaries.length === 0) {
    return <div className="p-4">No opportunities</div>;
  }

  const sortedOpportunities = sortBy(
    (o) => o?.pricingDate || 1,
    opportunitySummaries,
  );

  const getRowProps = (opp: OpportunitySummary) => {
    return {
      onClick: () =>
        onSelect ? onSelect(opp) : navigate(`/opportunities/${opp.id}`),
    };
  };

  return (
    <table className="component opportunities-table">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Client</th>
          <th scope="col">Status</th>
          <th scope="col">Pricing Date</th>
          <th scope="col">Close Date</th>
          <th scope="col">ROE</th>
          <th scope="col">ROA</th>
        </tr>
      </thead>
      <tbody>
        {map((opp) => {
          const performance = constructPerformance(
            opp.incomeStatement?.returnOnEquity,
            opp.incomeStatement?.returnOnAssets,
            performanceTargets,
          );

          return (
            <tr key={opp.id} {...getRowProps(opp)}>
              <td>{opp.name}</td>
              <td>{opp.client?.name}</td>
              <td>
                {match(opp.status)
                  .with("IN_PROGRESS", () => "In Progress")
                  .with("LOST", () => "Lost")
                  .with("WON", () => "Won")
                  .exhaustive()}
              </td>
              <td>
                {opp.pricingDate ? moment(opp.pricingDate).format("LL") : "--"}
              </td>
              <td>
                {opp.closeDate ? moment(opp.closeDate).format("LL") : "--"}
              </td>
              <td>
                <PerformanceMetric
                  metric={opp.incomeStatement?.returnOnEquity}
                  performance={performance.returnOnEquityPerformance}
                />
              </td>
              <td>
                <PerformanceMetric
                  metric={opp.incomeStatement?.returnOnAssets}
                  performance={performance.returnOnAssetsPerformance}
                />
              </td>
            </tr>
          );
        }, sortedOpportunities)}
      </tbody>
    </table>
  );
};

export default OpportunitiesTable;
