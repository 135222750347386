import React, { useCallback } from "react";
import Helmet from "react-helmet";
import { useNavigate } from "react-router";
import { ApolloError } from "@apollo/client";

import { useCreateClientMutation } from "@pricing-tool/graphql/lib/react";

import LoadingOverlay from "../../../components/common/LoadingOverlay";
import ErrorBanner from "../../../components/common/ErrorBanner";
import ClientForm, {
  ClientFormValue,
} from "../../../components/client/forms/ClientForm";

function CreateClient() {
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<ApolloError | null>(null);

  let [createClient] = useCreateClientMutation();

  const onSubmit = useCallback(
    (client: ClientFormValue) => {
      setLoading(true);
      createClient({
        variables: {
          branchId: "branch-1",
          input: {
            branchId: "branch-1",
            ...client,
          },
        },
      })
        .then((createdClient) => {
          if (!createdClient.data?.createClient) {
            setError(
              new ApolloError({
                errorMessage: "Failed to create client",
              }),
            );
            setLoading(false);
            return;
          }

          navigate(`/clients`);
        })
        .catch((err) => {
          console.log(err);
          setError(
            new ApolloError({
              errorMessage: "Failed to create client",
            }),
          );
          setLoading(false);
        });
    },
    [createClient, navigate],
  );

  const onCancel = () => {
    navigate("/clients");
  };

  return (
    <div className="create-client-page p-4">
      <Helmet>
        <title>Create Client</title>
      </Helmet>
      <div className="text-2xl">Create New Client</div>
      <ClientForm onCancel={onCancel} onSubmit={onSubmit} />

      {loading && <LoadingOverlay text="Creating client" />}
      {error && <ErrorBanner text={error.message} />}
    </div>
  );
}

export default CreateClient;
