import Decimal from "decimal.js-light";
import { Schema as S } from "@effect/schema";

import { DecimalFromString, OptDecimalFromString } from "../../../util/Decimal";

import { IncomeStatementSchema } from "../../../incomeStatement";
import { ElementSchema } from "./Element";
export { ElementSchema } from "./Element";
export { PackageSchema } from "./Package";

export type { Element } from "./Element";
export type { Package } from "./Package";

export type TreasuryDependencies = {
  taxRate: Decimal;
};

export const TreasuryServiceSchema = S.Struct({
  element: ElementSchema,
  discount: DecimalFromString,
  price: DecimalFromString,
  monthlyVolume: S.Number.pipe(S.int()),
  revenue: DecimalFromString,
  profit: DecimalFromString,
});

export type TreasuryService = S.Schema.Type<typeof TreasuryServiceSchema>;

export const TreasurySchema = S.Struct({
  _type: S.Literal("treasury"),
  investableBalance: OptDecimalFromString,
  earningsCreditRate: OptDecimalFromString,
  discount: OptDecimalFromString,
  treasuryServices: S.optional(S.Array(TreasuryServiceSchema)),
  earningsCreditAllowance: OptDecimalFromString,
  discountedAmount: OptDecimalFromString,
  incomeStatement: S.optional(IncomeStatementSchema),
});

export type Treasury = S.Schema.Type<typeof TreasurySchema>;
