import React, { useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";

import {
  DocumentSummaryFragment,
  useDeleteDocumentMutation,
  useGetDocumentLazyQuery,
  useGetDocumentsQuery,
} from "@pricing-tool/graphql/lib/react";

import DocumentsTable from "../../../components/documents/tables/DocumentsTable";
import LoadingOverlay from "../../../components/common/LoadingOverlay";

function Documents() {
  const navigate = useNavigate();
  const { opportunityId } = useParams();
  const location = useLocation();

  let state = location.state as { backgroundLocation?: Location } | null;
  let renderedInModal = !!state?.backgroundLocation;

  const { data, loading, error } = useGetDocumentsQuery({
    variables: {
      branchId: "branch-1",
      opportunityId: opportunityId!,
    },
    fetchPolicy: "network-only",
  });

  const [getDocument, { loading: documentLoading, error: documentError }] =
    useGetDocumentLazyQuery();

  const [deleteDocument, { loading: deleteLoading, error: deleteError }] =
    useDeleteDocumentMutation();

  const downloadDocument = useCallback(
    (document: DocumentSummaryFragment) => {
      void getDocument({
        variables: {
          branchId: "branch-1",
          opportunityId: opportunityId!,
          documentId: document.id,
        },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
          const url = data?.getDocument?.downloadSignedUrl;
          if (url) {
            window.open(url, "_blank");
          }
        },
      });
    },
    [getDocument, opportunityId],
  );

  const _deleteDocument = useCallback(
    (document: DocumentSummaryFragment) => {
      void deleteDocument({
        variables: {
          branchId: "branch-1",
          opportunityId: opportunityId!,
          documentId: document.id,
        },
        update: (cache) => {
          cache.evict({ id: "Document:" + document.id });
        },
      });
    },
    [deleteDocument, opportunityId],
  );

  return (
    <div className="page documents">
      <div className="body overflow-auto p-4">
        {!renderedInModal && <div className="text-2xl mb-4">Documents</div>}

        <div className="my-3 underline decoration-4 decoration-blue-200">
          <Link to="new" state={state}>
            Upload document
          </Link>
        </div>

        {loading && <div>Loading...</div>}
        {documentLoading && <LoadingOverlay text="Fetching document" />}
        {deleteLoading && <LoadingOverlay text="Deleting document" />}

        {error && <div>Error: {error.message}</div>}
        {documentError && <div>Error: {documentError.message}</div>}
        {deleteError && <div>Error: {deleteError.message}</div>}

        {data && data.getDocuments && (
          <DocumentsTable
            documents={data.getDocuments as DocumentSummaryFragment[]}
            onDownload={downloadDocument}
            onDelete={_deleteDocument}
          />
        )}
      </div>
      {renderedInModal && (
        <div className="footer">
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:items-end">
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-auto sm:w-auto sm:text-sm"
              onClick={() => navigate(state?.backgroundLocation!)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Documents;
