import React, { useCallback } from "react";
import { useLayoutObservableState } from "observable-hooks";

import {
  asObservable,
  CollateralItemModel,
} from "@pricing-tool/lib/products/ci/model/CollateralItemModel";

import CollateralRowComponent from "./component";
import { CollateralRecoveryTable } from "@pricing-tool/lib/products/ci/core/CollateralRecoveryTable";
import { find } from "ramda";

type CollateralRowProps = {
  collateralItemModel: CollateralItemModel;
  collateralRecoveryTable: CollateralRecoveryTable;
  onDelete: () => void;
};

const CollateralRow = ({
  collateralItemModel,
  collateralRecoveryTable,
  onDelete,
}: CollateralRowProps) => {
  const collateralItem$ = asObservable(collateralItemModel);
  const [collateralItem] = useLayoutObservableState(() => collateralItem$);

  const onCollateralTypeChange = useCallback(
    (value) =>
      collateralItemModel.input.collateralType.next(
        find((x) => x.id === value, collateralRecoveryTable),
      ),
    [collateralItemModel, collateralRecoveryTable],
  );

  if (!collateralItem) return <></>;

  return (
    <CollateralRowComponent
      collateralItem={collateralItem}
      collateralRecoveryTable={collateralRecoveryTable}
      onNameChange={(value) => collateralItemModel.input.name.next(value)}
      onCollateralTypeChange={onCollateralTypeChange}
      onValueChange={(value) => collateralItemModel.input.value.next(value)}
      onPriorLiensChange={(value) =>
        collateralItemModel.input.priorLiens.next(value)
      }
      onDelete={onDelete}
    />
  );
};

export default CollateralRow;
