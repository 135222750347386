import React, { useCallback } from "react";
import Decimal from "decimal.js-light";
import { Observable } from "rxjs";
import { useObservableState } from "observable-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { CollateralModel } from "@pricing-tool/lib/products/ci/model/CollateralModel";
import { CollateralRecoveryTable } from "@pricing-tool/lib/products/ci/core/CollateralRecoveryTable";
import { fromCollateralType } from "@pricing-tool/lib/products/ci/model/CollateralItemModel";

import CollateralTable from "../CollateralTable";

import "./styles.scss";

export type CollateralFormProps = {
  collateralModel: CollateralModel;
  collateralRecoveryTable: CollateralRecoveryTable;
  loanAmount: Observable<Decimal | undefined>;
  onCancel: () => void;
  onConfirm: () => void;
};

const CollateralForm = ({
  collateralModel,
  collateralRecoveryTable,
  loanAmount,
  onCancel,
  onConfirm,
}: CollateralFormProps) => {
  const [collateralItemModels] = useObservableState(
    () => collateralModel.input.collateralItems,
  );

  const addCollateralItem = useCallback(() => {
    if (!collateralItemModels) return;
    collateralModel.input.collateralItems.next([
      ...collateralItemModels,
      fromCollateralType(undefined, loanAmount, collateralRecoveryTable),
    ]);
  }, [
    collateralItemModels,
    loanAmount,
    collateralRecoveryTable,
  ]); /* eslint-disable-line react-hooks/exhaustive-deps */

  const removeCollateralItem = useCallback(
    (index: number) => {
      if (!collateralItemModels) return;
      // remove the item at index from collateralItemModels
      const newCollateralItemModels = collateralItemModels.filter(
        (_, i) => i !== index,
      );
      collateralModel.input.collateralItems.next(newCollateralItemModels);
    },
    [
      collateralItemModels,
    ] /* eslint-disable-line react-hooks/exhaustive-deps */,
  );

  return (
    <div className="collateral-form">
      <div className="flex ml-3 mb-3">
        <button
          type="button"
          className="bg-transparent hover:bg-blue-500 text-blue-500 text-base font-medium hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
          onClick={addCollateralItem}
        >
          <FontAwesomeIcon className="w-4 h-4 mr-2" icon="plus" />
          Add Collateral
        </button>
      </div>
      <div className="body overflow-auto">
        <div className="collateral-table-wrapper">
          <CollateralTable
            collateralModel={collateralModel}
            collateralRecoveryTable={collateralRecoveryTable}
            onRemoveCollateralItem={removeCollateralItem}
          />
        </div>
      </div>
      <div className="footer">
        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:items-end">
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-auto sm:w-auto sm:text-sm"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => onConfirm()} // TODO
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default CollateralForm;
