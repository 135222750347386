import React from "react";

import {
  TemplateSummaryFragment,
  useGetTemplatesQuery,
} from "@pricing-tool/graphql/lib/react";

import ErrorBanner from "../../../../common/ErrorBanner";
import LoadingIndicator from "../../../../common/LoadingIndicator";
import TemplatesTable from "../../../../templates/tables/TemplatesTable";

import "./styles.scss";

export type TemplatePickerProps = {
  onConfirm: (template: TemplateSummaryFragment) => void;
  onCancel: () => void;
};

const TemplatePicker = ({ onConfirm, onCancel }: TemplatePickerProps) => {
  const { data, loading, error } = useGetTemplatesQuery({
    variables: { branchId: "branch-1" },
  });

  return (
    <div className="component template-picker">
      {!loading && data?.getTemplates && (
        <>
          <div className="body">
            <TemplatesTable
              templates={data.getTemplates as TemplateSummaryFragment[]}
              onSelect={onConfirm}
            />
          </div>
          <div className="footer">
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:items-end">
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-auto sm:w-auto sm:text-sm"
                onClick={onCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      )}

      {loading && (
        <>
          <div className="body loader">
            <LoadingIndicator size="lg" />
          </div>
          <div className="footer">
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:items-end">
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-auto sm:w-auto sm:text-sm"
                onClick={onCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      )}
      {error && <ErrorBanner text={error.message} />}
    </div>
  );
};

export default TemplatePicker;
