import { Schema as S } from "@effect/schema";

import { ElementSchema } from "./Element";

/* NOTES:
   - If elements within a package have prerequisite elements, those elements must also be in the package!
 */

export const PackageSchema = S.Struct({
  id: S.String,
  name: S.String,
  elements: S.Array(ElementSchema),
});

export type Package = S.Schema.Type<typeof PackageSchema>;
export type PackageDto = S.Schema.Encoded<typeof PackageSchema>;

export const parse = (pkg: PackageDto): Package =>
  S.decodeUnknownSync(PackageSchema)(pkg);
