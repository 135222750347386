import React from "react";
import { useNavigate, useParams } from "react-router";
import Helmet from "react-helmet";
import Decimal from "decimal.js-light";
import { Link } from "react-router-dom";

import {
  useGetClientQuery,
  useGetClientOpportunitiesQuery,
  Address,
} from "@pricing-tool/graphql/lib/react";

import { fromDto } from "@pricing-tool/lib/opportunitySummary/dto";
import { OpportunitySummary } from "@pricing-tool/lib/opportunitySummary/core";

import LoadingOverlay from "../../../components/common/LoadingOverlay";
import ErrorBanner from "../../../components/common/ErrorBanner";
import OpportunitiesTable from "../../../components/opportunity/tables/OpportunitiesTable";

import formatAddress from "../../../utils/formatAddress";
import formatName from "../../../utils/formatName";

// FIXME - don't hardcode
const performanceTargets = {
  returnOnEquity: new Decimal("0.1500"),
  returnOnAssets: new Decimal("0.0100"),
};

function ClientDetailsPage() {
  const { clientId } = useParams();
  const navigate = useNavigate();

  const { data, loading, error } = useGetClientQuery({
    variables: {
      branchId: "branch-1",
      clientId: clientId!,
    },
    fetchPolicy: "network-only",
  });

  const [opportunitySummaries, setOpportunitySummaries] = React.useState<
    OpportunitySummary[] | undefined
  >(undefined);
  const { loading: opportunitiesLoading, error: opportunitiesError } =
    useGetClientOpportunitiesQuery({
      variables: {
        branchId: "branch-1",
        clientId: clientId!,
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        setOpportunitySummaries(data.getClientOpportunities.items.map(fromDto));
      },
    });

  return (
    <div className="page client-details">
      <Helmet>
        <title>Client Details</title>
      </Helmet>
      <div className="text-2xl">Client Details</div>
      <div className="my-3 underline decoration-4 decoration-blue-200">
        <Link to={`/clients/${clientId}/edit`}>Edit client</Link>
      </div>

      {loading && <LoadingOverlay text="Fetching client" />}
      {error && <ErrorBanner text={error.message} />}
      {data && data.getClient && (
        <div>
          <table>
            <tbody>
              <tr>
                <td className="pr-3">Client Name:</td>
                <td>{data.getClient.name}</td>
              </tr>
              <tr>
                <td className="pr-3">Address:</td>
                <td>{formatAddress(data.getClient.address as Address)}</td>
              </tr>
              <tr>
                <td className="pr-3">Contact:</td>
                <td>
                  {formatName(
                    data.getClient.contact?.firstName,
                    data.getClient.contact?.lastName,
                  )}
                </td>
              </tr>
              <tr>
                <td className="pr-3">Phone:</td>
                <td>{data.getClient.contact?.phone}</td>
              </tr>
              <tr>
                <td className="pr-3">Email:</td>
                <td>{data.getClient.contact?.email}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      <div className="text-xl mt-4">Opportunities</div>
      <div className="my-3 underline decoration-4 decoration-blue-200">
        <Link to="/opportunities/new">Create a new opportunity</Link>
      </div>

      {opportunitiesLoading && <div>Loading opportunities...</div>}
      {opportunitiesError && <ErrorBanner text={opportunitiesError.message} />}
      {!opportunitiesLoading && !opportunitiesError && opportunitySummaries && (
        <div>
          <OpportunitiesTable
            opportunitySummaries={opportunitySummaries}
            performanceTargets={performanceTargets}
          />
        </div>
      )}
    </div>
  );
}

export default ClientDetailsPage;
