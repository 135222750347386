import { Schema as S } from "@effect/schema";

import { DecimalFromString } from "../../../util/Decimal";

export const IndexSchema = S.String.pipe(S.brand("Index"));
export type Index = S.Schema.Type<typeof IndexSchema>;

export const IndexRateSchema = S.Struct({
  index: IndexSchema,
  date: S.Date,
  rate: DecimalFromString,
  createdAt: S.Date,
});
export type IndexRate = S.Schema.Type<typeof IndexRateSchema>;

export const IndexRatesSnapshotSchema = S.Struct({
  indexRates: S.Array(IndexRateSchema),
  createdAt: S.Date,
});
export type IndexRatesSnapshot = S.Schema.Type<typeof IndexRatesSnapshotSchema>;
