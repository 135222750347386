import { FieldFunctionOptions, InMemoryCache } from "@apollo/client";
import { uniq } from "ramda";
import { SafeReadonly } from "@apollo/client/cache/core/types/common";

import { StrictTypedTypePolicies } from "@pricing-tool/graphql/lib/apollo-helpers";

type MergeStrategy = (
  existing: SafeReadonly<any> | undefined,
  incoming: SafeReadonly<any>,
  options: FieldFunctionOptions<Record<string, any>, Record<string, any>>,
) => SafeReadonly<any>;

const appendNextPageMergeStrategy: MergeStrategy = (
  existing,
  incoming,
  { variables },
) => {
  if (!existing) {
    return incoming;
  }

  if (variables?.page?.pageKey === existing.nextPageKey) {
    return Object.assign({}, existing, {
      items: uniq([...existing.items, ...incoming.items]),
      nextPageKey: incoming.nextPageKey,
      hasMore: incoming.hasMore,
    });
  }

  return incoming;
};

const typePolicies: StrictTypedTypePolicies = {
  Query: {
    fields: {},
  },
};

export default new InMemoryCache({
  typePolicies,
});
