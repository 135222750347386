import React from "react";
import { useObservableState } from "observable-hooks";

import {
  asObservable,
  CommercialRealEstateModel,
} from "@pricing-tool/lib/products/cre/model";
import { CommercialRealEstateDependencies } from "@pricing-tool/lib/products/cre/core";
import { CollateralRecoveryTable } from "@pricing-tool/lib/products/ci/core/CollateralRecoveryTable";

import LoanForm from "../../../common/LoanForm";

export type CommercialRealEstateFormProps = {
  commercialRealEstateModel: CommercialRealEstateModel;
  dependencies: CommercialRealEstateDependencies;
  collateralRecoveryTable: CollateralRecoveryTable;
};

const CommercialRealEstateForm = ({
  commercialRealEstateModel,
  dependencies,
  collateralRecoveryTable,
}: CommercialRealEstateFormProps) => {
  const commercialRealEstate$ = asObservable(commercialRealEstateModel);
  const [commercialRealEstate] = useObservableState(
    () => commercialRealEstate$,
  );

  if (!commercialRealEstate) {
    return <></>;
  }

  return (
    <LoanForm
      model={commercialRealEstateModel}
      product={commercialRealEstate}
      dependencies={dependencies}
      collateralRecoveryTable={collateralRecoveryTable}
    />
  );
};

export default CommercialRealEstateForm;
