import React, { useCallback, useMemo, useState } from "react";
import { filter, includes, isEmpty, map, pipe } from "ramda";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Opportunity } from "@pricing-tool/lib/opportunity/core";

import {
  ScenarioSummary,
  toScenarioSummary,
} from "../../../utils/ScenarioSummary";

import "./styles.scss";
import { Scenario } from "@pricing-tool/lib/scenario/core";
import PerformanceMetric from "../../../../opportunity/fields/PerformanceMetric";
import { PerformanceTargets } from "../../../../../utils/constructPerformance";

export type ScenariosPickerProps = {
  opportunity: Opportunity;
  performanceTargets?: PerformanceTargets;
  excludedScenarioId?: string;
  selectMultiple: boolean;
  onConfirm: (scenarioIds: string[]) => void;
  onCancel: () => void;
  onBack?: () => void;
};

const ScenariosPicker = ({
  opportunity,
  performanceTargets,
  excludedScenarioId,
  selectMultiple,
  onConfirm,
  onCancel,
  onBack,
}: ScenariosPickerProps) => {
  const [selectedScenarioIds, setSelectedScenarioIds] = useState<string[]>([]);
  const multiSelectAllowed = selectMultiple || false;

  const scenarioSummaries = useMemo(
    () =>
      pipe(
        map((scenario: Scenario) =>
          toScenarioSummary(scenario, performanceTargets),
        ),
        filter((summary: ScenarioSummary) => summary.id !== excludedScenarioId),
      )(opportunity?.scenarios || []),
    [opportunity, excludedScenarioId],
  );

  const checkScenario = useCallback(
    (scenarioId) =>
      setSelectedScenarioIds([...selectedScenarioIds, scenarioId]),
    [selectedScenarioIds],
  );

  const uncheckScenario = useCallback(
    (scenarioId) =>
      setSelectedScenarioIds(
        filter(
          (_scenarioId) => _scenarioId !== scenarioId,
          selectedScenarioIds,
        ),
      ),
    [selectedScenarioIds],
  );

  const _onConfirm = useCallback(() => {
    if (isEmpty(selectedScenarioIds)) return;
    onConfirm(selectedScenarioIds);
  }, [selectedScenarioIds, onConfirm]);

  return (
    <div className="scenarios-picker">
      <div className="body">
        <>
          {onBack !== undefined && (
            <button onClick={onBack} className="back-button">
              <FontAwesomeIcon icon="chevron-left" /> Back
            </button>
          )}
          {isEmpty(scenarioSummaries) && (
            <div className="empty">No scenarios to display</div>
          )}
          {!isEmpty(scenarioSummaries) && (
            <table className="scenarios-table">
              <thead>
                <tr>
                  {multiSelectAllowed && <th>&nbsp;</th>}
                  <th scope="col">Scenario</th>
                  <th scope="col">ROE</th>
                  <th scope="col">ROA</th>
                </tr>
              </thead>
              <tbody>
                {map((scenario) => {
                  const checked = includes(scenario.id, selectedScenarioIds);
                  const onCheckChanged = () =>
                    checked
                      ? uncheckScenario(scenario.id)
                      : checkScenario(scenario.id);
                  return (
                    <tr
                      key={scenario.id}
                      onClick={
                        multiSelectAllowed
                          ? onCheckChanged
                          : () => onConfirm([scenario.id])
                      }
                    >
                      {multiSelectAllowed && (
                        <td className="check">
                          <input
                            type="checkbox"
                            checked={checked}
                            disabled={false}
                            onChange={onCheckChanged}
                          />
                        </td>
                      )}
                      <td>{scenario.name}</td>
                      <td>
                        <PerformanceMetric
                          metric={scenario.metrics.returnOnEquity}
                          performance={
                            scenario.performance.returnOnEquityPerformance
                          }
                        />
                      </td>
                      <td>
                        <PerformanceMetric
                          metric={scenario.metrics.returnOnAssets}
                          performance={
                            scenario.performance.returnOnAssetsPerformance
                          }
                        />
                      </td>
                    </tr>
                  );
                }, scenarioSummaries)}
              </tbody>
            </table>
          )}
        </>
      </div>
      <div className="footer">
        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:items-end">
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-auto sm:w-auto sm:text-sm"
            onClick={onCancel}
          >
            Cancel
          </button>
          {multiSelectAllowed && (
            <>
              {isEmpty(selectedScenarioIds) ? (
                <div className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 text-base font-medium text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100 sm:ml-3 sm:w-auto sm:text-sm">
                  Done
                </div>
              ) : (
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={_onConfirm}
                >
                  Done
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScenariosPicker;
