import React, { useCallback, useReducer, useState } from "react";
import { isNil } from "ramda";

import {
  Message,
  useCreateMessageMutation,
  useGetMessagesForOpportunityLazyQuery,
  useGetMessagesForOpportunityQuery,
  useOnCreateMessageSubscription,
} from "@pricing-tool/graphql/lib/react";

import ConversationComponent from "../../../messenger/Conversation";

export type ConversationProps = {
  branchId: string;
  opportunityId: string;
};

const PAGE_SIZE = 50;

const Conversation = ({ branchId, opportunityId }: ConversationProps) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [nextToken, setNextToken] = useState<string | null | undefined>();
  const [loadingMoreMessages, setLoadingMoreMessages] =
    useState<boolean>(false);

  const { loading, error } = useGetMessagesForOpportunityQuery({
    variables: {
      branchId,
      opportunityId,
      paginationOptions: { limit: PAGE_SIZE },
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (!data || !data.getMessagesForOpportunity) return;
      setMessages(data.getMessagesForOpportunity.items);
      setNextToken(data.getMessagesForOpportunity.nextToken);
    },
  });

  const [getMessages, { error: lazyError }] =
    useGetMessagesForOpportunityLazyQuery({
      onCompleted: (data) => {
        if (!data) return;
        if (!data.getMessagesForOpportunity) return;
        if (!data.getMessagesForOpportunity.items) return;

        setMessages((messages) => [
          ...messages,
          ...data.getMessagesForOpportunity!.items,
        ]);
        setNextToken(data.getMessagesForOpportunity.nextToken);
        setLoadingMoreMessages(false);
      },
    });

  const [createMessage, { loading: createLoading }] = useCreateMessageMutation(
    {},
  );

  const onLoadMore = useCallback(() => {
    setLoadingMoreMessages(true);
    void getMessages({
      variables: {
        branchId,
        opportunityId,
        paginationOptions: {
          limit: PAGE_SIZE,
          pageToken: nextToken,
        },
      },
    });
  }, [getMessages, nextToken, branchId, opportunityId]);

  const onSendMessage = useCallback(
    (text: string) => {
      return createMessage({
        variables: {
          branchId,
          opportunityId,
          input: {
            branchId,
            opportunityId,
            text,
          },
        },
      });
    },
    [createMessage, branchId, opportunityId],
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        Loading messages...
      </div>
    );
  }

  if (error) {
    return <div>Error loading messages: {error.message}</div>;
  }

  if (lazyError) {
    return <div>Error loading messages: {lazyError.message}</div>;
  }

  return (
    <>
      <ConversationComponent
        messages={messages}
        moreMessages={!isNil(nextToken)}
        onLoadMore={onLoadMore}
        isLoadingMore={loadingMoreMessages}
        onSend={onSendMessage}
        isSending={createLoading}
      />
      <MessageSubscriber
        branchId={branchId}
        opportunityId={opportunityId}
        onNewMessage={(message) => {
          setMessages((messages) => [message, ...messages]);
        }}
        onSubscriptionClosed={() => {
          console.error("Subscription closed");
        }}
      />
    </>
  );
};

type MessageSubscriberProps = {
  branchId: string;
  opportunityId: string;
  onNewMessage: (message: Message) => void;
  onSubscriptionClosed: () => void;
};

const MessageSubscriber = (props: MessageSubscriberProps) => {
  const { branchId, opportunityId, onNewMessage, onSubscriptionClosed } = props;

  useOnCreateMessageSubscription({
    variables: {
      branchId,
      opportunityId,
    },
    shouldResubscribe: true,
    onData: ({ data }) => {
      if (!data.data) return;
      const newMessage = data.data.onCreateMessage;
      onNewMessage(newMessage as Message);
    },
    onError: (error) => {
      console.error("Error in message subscription:", error.message);
      onSubscriptionClosed();
    },
  });

  return null;
};

export default Conversation;
