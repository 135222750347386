import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  RenderedTemplate,
  useCreateRenderedTemplateMutation,
} from "@pricing-tool/graphql/lib/react";

import ErrorBanner from "../../../../common/ErrorBanner";
import LoadingIndicator from "../../../../common/LoadingIndicator";

import "./styles.scss";

export type CreateRenderedTemplateProps = {
  templateId: string;
  opportunityId: string;
  scenarioId: string;
  onBack?: () => void;
  onCancel: () => void;
  onComplete: (renderedTemplate: RenderedTemplate) => void;
};

const CreateRenderedTemplate = ({
  templateId,
  opportunityId,
  scenarioId,
  onBack,
  onCancel,
  onComplete,
}: CreateRenderedTemplateProps) => {
  const [createRenderedTemplate, { loading, error }] =
    useCreateRenderedTemplateMutation({
      variables: {
        branchId: "branch-1",
        templateId,
        opportunityId,
        scenarioId,
      },
    });

  useEffect(() => {
    createRenderedTemplate().then((result) => {
      if (result.data) {
        onComplete(result.data.createRenderedTemplate as RenderedTemplate);
      }
    });
  }, []);

  return (
    <div className="component create-rendered-template">
      <div className="body">
        {onBack !== undefined && (
          <button onClick={onBack} className="back-button">
            <FontAwesomeIcon icon="chevron-left" /> Back
          </button>
        )}
        <>
          <div className="loader">
            <LoadingIndicator size="lg" />
          </div>
          <h2 className="text-center font-semibold mt-4">
            Generating document...
          </h2>
        </>
        {error && <ErrorBanner text={error.message} />}
      </div>
      <div className="footer">
        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:items-end">
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-auto sm:w-auto sm:text-sm"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateRenderedTemplate;
