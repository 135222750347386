import "./styles.scss";
import Input, { Modifier } from "../Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { forwardRef } from "react";

type InputWithIconProps = {
  value: string;
  onChange: (value: string) => void;
  onSelect?: (modifier: Modifier, input: HTMLInputElement) => void;
  onDeselect?: (input: HTMLInputElement) => void;
  selected?: boolean;
  label?: string;
  iconSide?: "left" | "right";
  iconText?: string;
  iconName?: IconProp;
  textAlign?: "left" | "right";
  className?: string;
  disabled?: boolean;
};

const InputWithIcon = forwardRef<HTMLInputElement, InputWithIconProps>(
  (props: InputWithIconProps, ref) => {
    const icon = props.iconText || props.iconName;
    const textAlign = props.textAlign || "left";
    const iconSide = props.iconSide || "left";
    const selected = props.selected || false;

    return (
      <div className="component input-with-icon">
        {props.label && (
          <label
            htmlFor="input-box"
            className="block text-md font-medium text-gray-700"
          >
            {props.label}
          </label>
        )}
        <div
          className={`${
            props.label ? "mt-1" : ""
          } relative rounded-md shadow-sm`}
        >
          <Input
            ref={ref}
            onSelect={props.onSelect}
            onDeselect={props.onDeselect}
            className={`${props.className} focus:ring-2 focus:ring-blue-500 ${
              selected ? "ring-2 ring-blue-400" : ""
            } block w-full py-1 border border-gray-300 rounded-md text-${textAlign} ${
              icon && iconSide === "left" ? "pl-10" : "pl-2"
            } ${icon && iconSide === "right" ? "pr-10" : "pr-2"}`}
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled}
          />
          {icon && (
            <div
              className={`absolute inset-y-0 ${iconSide}-0 w-8 flex justify-center items-center pointer-events-none bg-gray-300 ${
                iconSide === "left" ? "rounded-l-md" : "rounded-r-md"
              }`}
            >
              {props.iconText && (
                <span className="text-gray-700">{props.iconText}</span>
              )}
              {props.iconName && (
                <FontAwesomeIcon
                  icon={props.iconName}
                  size="sm"
                  className="text-gray-500"
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  },
);

export default InputWithIcon;
