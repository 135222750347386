import Decimal from "decimal.js-light";
import {
  BehaviorSubject,
  combineLatest,
  defaultIfEmpty,
  map,
  Observable,
  share,
} from "rxjs";
import { filter, isNil, not, pipe, pluck } from "ramda";
import * as R from "ramda";
import { Schema as S } from "@effect/schema";

import mapAllDefined from "../util/operators/mapAllDefined";
import { OptDecimalFromString } from "../util/Decimal";

export type ProductOutput = {
  incomeStatementOutput: IncomeStatementOutput;
};

export const IncomeStatementSchema = S.Struct({
  feeOrInterestIncome: OptDecimalFromString,
  feeOrInterestExpense: OptDecimalFromString,
  netFeeOrInterestIncome: OptDecimalFromString,
  nonFeeOrInterestExpense: OptDecimalFromString,
  loanLossReserves: OptDecimalFromString,
  pretaxIncome: OptDecimalFromString,
  taxes: OptDecimalFromString,
  netIncome: OptDecimalFromString,
  averageBalance: OptDecimalFromString,
  averageEquity: OptDecimalFromString,
  returnOnAssets: OptDecimalFromString,
  returnOnEquity: OptDecimalFromString,
});

export type IncomeStatement = S.Schema.Type<typeof IncomeStatementSchema>;

export type IncomeStatementOutput = {
  feeOrInterestIncome: Observable<Decimal | undefined>;
  feeOrInterestExpense: Observable<Decimal | undefined>;
  netFeeOrInterestIncome: Observable<Decimal | undefined>;
  nonFeeOrInterestExpense: Observable<Decimal | undefined>;
  loanLossReserves?: Observable<Decimal | undefined>;
  pretaxIncome: Observable<Decimal | undefined>;
  taxes: Observable<Decimal | undefined>;
  netIncome: Observable<Decimal | undefined>;
  averageBalance?: Observable<Decimal | undefined>;
  averageEquity?: Observable<Decimal | undefined>;
  returnOnAssets?: Observable<Decimal | undefined>;
  returnOnEquity?: Observable<Decimal | undefined>;
};

export const asObservable = (
  output: IncomeStatementOutput,
): Observable<IncomeStatement> => {
  const {
    feeOrInterestIncome,
    feeOrInterestExpense,
    netFeeOrInterestIncome,
    nonFeeOrInterestExpense,
    loanLossReserves,
    pretaxIncome,
    taxes,
    netIncome,
    averageBalance,
    averageEquity,
    returnOnAssets,
    returnOnEquity,
  } = output;

  return combineLatest([
    feeOrInterestIncome,
    feeOrInterestExpense,
    netFeeOrInterestIncome,
    nonFeeOrInterestExpense,
    loanLossReserves || new BehaviorSubject(undefined),
    pretaxIncome,
    taxes,
    netIncome,
    averageBalance || new BehaviorSubject(undefined),
    averageEquity || new BehaviorSubject(undefined),
    returnOnAssets || new BehaviorSubject(undefined),
    returnOnEquity || new BehaviorSubject(undefined),
  ]).pipe(
    map(
      ([
        feeOrInterestIncome,
        feeOrInterestExpense,
        netFeeOrInterestIncome,
        nonFeeOrInterestExpense,
        loanLossReserves,
        pretaxIncome,
        taxes,
        netIncome,
        averageBalance,
        averageEquity,
        returnOnAssets,
        returnOnEquity,
      ]) => ({
        feeOrInterestIncome,
        feeOrInterestExpense,
        netFeeOrInterestIncome,
        nonFeeOrInterestExpense,
        loanLossReserves,
        pretaxIncome,
        taxes,
        netIncome,
        averageBalance,
        averageEquity,
        returnOnAssets,
        returnOnEquity,
      }),
    ),
    share(),
  );
};

export const combine = (
  incomeStatementOutputs: IncomeStatementOutput[],
): IncomeStatementOutput => {
  const filterOutUndefined = (args: any[]) => filter(pipe(isNil, not), args);

  const add = R.reduce(
    (acc: Decimal, num: Decimal) => (num ? acc.plus(num) : acc),
    new Decimal(0),
  );

  const addOrUndefined = (args: Array<Decimal | undefined>) => {
    const allDefined = filterOutUndefined(args);
    if (allDefined && allDefined.length) return add(allDefined as Decimal[]);
    return undefined;
  };

  const feeOrInterestIncome = defaultIfEmpty<
    Array<Decimal | undefined>,
    Array<Decimal | undefined>
  >([])(
    combineLatest(pluck("feeOrInterestIncome", incomeStatementOutputs)),
  ).pipe(map(addOrUndefined));
  const feeOrInterestExpense = defaultIfEmpty<
    Array<Decimal | undefined>,
    Array<Decimal | undefined>
  >([])(
    combineLatest(pluck("feeOrInterestExpense", incomeStatementOutputs)),
  ).pipe(map(addOrUndefined));
  const netFeeOrInterestIncome = defaultIfEmpty<
    Array<Decimal | undefined>,
    Array<Decimal | undefined>
  >([])(
    combineLatest(pluck("netFeeOrInterestIncome", incomeStatementOutputs)),
  ).pipe(map(addOrUndefined));
  const nonFeeOrInterestExpense = defaultIfEmpty<
    Array<Decimal | undefined>,
    Array<Decimal | undefined>
  >([])(
    combineLatest(pluck("nonFeeOrInterestExpense", incomeStatementOutputs)),
  ).pipe(map(addOrUndefined));
  const loanLossReserves = defaultIfEmpty<
    Array<Decimal | undefined>,
    Array<Decimal | undefined>
  >([])(
    combineLatest(
      filterOutUndefined(pluck("loanLossReserves", incomeStatementOutputs)),
    ),
  ).pipe(map(addOrUndefined));
  const pretaxIncome = defaultIfEmpty<
    Array<Decimal | undefined>,
    Array<Decimal | undefined>
  >([])(combineLatest(pluck("pretaxIncome", incomeStatementOutputs))).pipe(
    map(addOrUndefined),
  );
  const taxes = defaultIfEmpty<
    Array<Decimal | undefined>,
    Array<Decimal | undefined>
  >([])(combineLatest(pluck("taxes", incomeStatementOutputs))).pipe(
    map(addOrUndefined),
  );
  const netIncome = defaultIfEmpty<
    Array<Decimal | undefined>,
    Array<Decimal | undefined>
  >([])(combineLatest(pluck("netIncome", incomeStatementOutputs))).pipe(
    map(addOrUndefined),
  );
  const averageBalance = defaultIfEmpty<
    Array<Decimal | undefined>,
    Array<Decimal | undefined>
  >([])(
    combineLatest(
      filterOutUndefined(pluck("averageBalance", incomeStatementOutputs)),
    ),
  ).pipe(map(addOrUndefined));
  const averageEquity = defaultIfEmpty<
    Array<Decimal | undefined>,
    Array<Decimal | undefined>
  >([])(
    combineLatest(
      filterOutUndefined(pluck("averageEquity", incomeStatementOutputs)),
    ),
  ).pipe(map(addOrUndefined));

  const returnOnAssets = combineLatest([netIncome, averageBalance]).pipe(
    mapAllDefined(([netIncome, averageBalance]) =>
      netIncome.dividedBy(averageBalance),
    ),
  );

  const returnOnEquity = combineLatest([netIncome, averageEquity]).pipe(
    mapAllDefined(([netIncome, averageEquity]) =>
      netIncome.dividedBy(averageEquity),
    ),
  );

  return {
    feeOrInterestIncome,
    feeOrInterestExpense,
    netFeeOrInterestIncome,
    nonFeeOrInterestExpense,
    loanLossReserves,
    pretaxIncome,
    taxes,
    netIncome,
    averageBalance,
    averageEquity,
    returnOnAssets,
    returnOnEquity,
  };
};
