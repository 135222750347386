import React, { useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { ApolloError } from "@apollo/client";

import {
  useCreateDocumentMutation,
  useCreateDocumentUploadUrlMutation,
} from "@pricing-tool/graphql/lib/react";

import LoadingOverlay from "../../../../components/common/LoadingOverlay";
import ErrorBanner from "../../../../components/common/ErrorBanner";
import DocumentForm, {
  DocumentFormValue,
} from "../../../../components/documents/forms/DocumentForm";

function CreateDocument() {
  const navigate = useNavigate();
  const { opportunityId } = useParams();
  const location = useLocation();

  let state = location.state as { backgroundLocation?: Location } | null;
  const isRenderedInModal = !!state?.backgroundLocation;

  const [createDocumentUploadUrl] = useCreateDocumentUploadUrlMutation();
  const [createDocument] = useCreateDocumentMutation();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<ApolloError | null>(null);

  const onSubmit = useCallback(
    (documentFormValue: DocumentFormValue) => {
      setLoading(true);
      createDocumentUploadUrl({
        variables: {
          branchId: "branch-1",
          input: {
            branchId: "branch-1",
            opportunityId: opportunityId!,
            documentName: documentFormValue.file!.name,
            documentType: documentFormValue.file!.type,
          },
        },
      })
        .then((res) => {
          if (!res.data?.createDocumentUploadUrl) {
            setError(
              new ApolloError({
                errorMessage: "Failed to create document upload url",
              }),
            );
            setLoading(false);
            return;
          }

          const { preSignedUrl, documentS3Pointer, fields } =
            res.data?.createDocumentUploadUrl;

          const parsedFields: Record<string, string> = JSON.parse(fields);
          const formData = new FormData();
          Object.entries(parsedFields).forEach(([key, value]) => {
            formData.append(key, value);
          });
          formData.append("file", documentFormValue.file!);

          fetch(preSignedUrl, {
            method: "POST",
            body: formData,
          })
            .then((res) => {
              if (!res.ok) {
                setError(
                  new ApolloError({
                    errorMessage: "Failed to upload document",
                  }),
                );
                setLoading(false);
                return;
              }

              createDocument({
                variables: {
                  branchId: "branch-1",
                  input: {
                    branchId: "branch-1",
                    opportunityId: opportunityId!,
                    name: documentFormValue.name,
                    description: documentFormValue.description,
                    documentType: documentFormValue.file!.type,
                    documentS3Pointer,
                  },
                },
              }).then((createdDocument) => {
                if (!createdDocument.data?.createDocument) {
                  setError(
                    new ApolloError({
                      errorMessage: "Failed to create document",
                    }),
                  );
                  setLoading(false);
                  return;
                }

                navigate(`/opportunities/${opportunityId!}/documents`, {
                  state,
                });
              });
            })
            .catch((err) => {
              console.log(err);
              setError(
                new ApolloError({
                  errorMessage: "Failed to upload document",
                }),
              );
              setLoading(false);
            });
        })
        .catch((err) => {
          console.log(err);
          setError(
            new ApolloError({
              errorMessage: "Failed to create document upload url",
            }),
          );
          setLoading(false);
        });
    },
    [createDocument, createDocumentUploadUrl, navigate, opportunityId, state],
  );

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <div className="create-document-page">
      {!isRenderedInModal && (
        <div className="text-2xl p-2">Create New Document</div>
      )}
      <DocumentForm onCancel={onCancel} onSubmit={onSubmit} />

      {loading && <LoadingOverlay text="Creating document" />}
      {error && <ErrorBanner text={error.message} />}
    </div>
  );
}

export default CreateDocument;
