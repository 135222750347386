import React, { useEffect, useState } from "react";

import {
  RenderedTemplate,
  TemplateSummaryFragment,
} from "@pricing-tool/graphql/lib/react";

import TemplatePicker from "../../fields/TemplatePicker";
import CreateRenderedTemplate from "../../fields/CreateRenderedTemplate";

import "./styles.scss";

export type CreateDocumentWorkflowProps = {
  opportunityId: string;
  scenarioId: string;
  onCancel: () => void;
};

const CreateDocumentWorkflow = ({
  opportunityId,
  scenarioId,
  onCancel,
}: CreateDocumentWorkflowProps) => {
  const [workflowState, setWorkflowState] = useState<
    "template" | "create" | "download"
  >("template");
  const [selectedTemplate, setSelectedTemplate] = useState<
    TemplateSummaryFragment | undefined
  >();
  const [renderedTemplate, setRenderedTemplate] = useState<
    RenderedTemplate | undefined
  >();

  useEffect(() => {
    if (!selectedTemplate) {
      setWorkflowState("template");
      return;
    }
    setWorkflowState("create");
  }, [selectedTemplate]);

  useEffect(() => {
    if (workflowState !== "create") return;
    if (!renderedTemplate) {
      setWorkflowState("template");
      return;
    }
    setWorkflowState("download");
  }, [renderedTemplate]);

  return (
    <div className="component create-document-workflow">
      {workflowState === "template" && (
        <TemplatePicker onConfirm={setSelectedTemplate} onCancel={onCancel} />
      )}

      {workflowState === "create" && (
        <CreateRenderedTemplate
          templateId={selectedTemplate!.id}
          opportunityId={opportunityId}
          scenarioId={scenarioId}
          onCancel={onCancel}
          onBack={() => {
            setWorkflowState("template");
            setSelectedTemplate(undefined);
          }}
          onComplete={setRenderedTemplate}
        />
      )}

      {workflowState === "download" && (
        <div className="download">
          <div className="body flex justify-center items-center">
            <a
              href={renderedTemplate!.documentSignedUrl}
              target="_blank"
              rel="noreferrer"
            >
              Download
            </a>
          </div>
          <div className="footer">
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:items-end">
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-auto sm:w-auto sm:text-sm"
                onClick={onCancel}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateDocumentWorkflow;
