import Decimal from "decimal.js-light";
import { Schema as S } from "@effect/schema";

import { IncomeStatementSchema } from "../../../incomeStatement";
import { OptDecimalFromString } from "../../../util/Decimal";

export type DepositsDependencies = {
  taxRate: Decimal;
  ecrEligibleRatio: Decimal;
  fundTransferPricingRate: Decimal;
  liquidityTransferPricingRate: Decimal;
  annualizedEcr: Decimal;
  annualizedInterestRate: Decimal;
  annualEcrEligibleCharges: Decimal;
  reserveRequirements: Decimal;
};

export const DepositsSchema = S.Struct({
  _type: S.Literal("deposits"),
  collectedBalances: OptDecimalFromString,
  ecrEligibleBalances: OptDecimalFromString,
  interestEligibleBalances: OptDecimalFromString,
  fundTransferPricingRate: OptDecimalFromString,
  liquidityTransferPricingRate: OptDecimalFromString,
  annualizedEcr: OptDecimalFromString,
  annualizedInterestRate: OptDecimalFromString,
  earningsCreditAllowance: OptDecimalFromString,
  annualEcrEligibleCharges: OptDecimalFromString,
  reserveRequirements: OptDecimalFromString,
  netInterestMargin: OptDecimalFromString,
  netIncome: OptDecimalFromString,
  incomeStatement: IncomeStatementSchema,
});

export type Deposits = S.Schema.Type<typeof DepositsSchema>;
