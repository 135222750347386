import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./styles.scss";

export type ErrorBannerProps = {
  text?: string;
};

const ErrorBanner = ({ text }: ErrorBannerProps) => {
  const displayText = text || "An unknown error occurred";
  return (
    <div className="error-banner">
      <div className="centered">
        <div className="icon">
          <FontAwesomeIcon icon="exclamation-triangle" size="2x" />
        </div>
        <h2 className="text-left text-white text-lg font-semibold">
          {displayText}
        </h2>
      </div>
    </div>
  );
};

export default ErrorBanner;
