import React, { useCallback, useReducer } from "react";
import { useObservableState } from "observable-hooks";
import { filter, includes, map } from "ramda";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Element,
  TreasuryService,
} from "@pricing-tool/lib/products/treasury/core";
import { asObservable } from "@pricing-tool/lib/products/treasury/model/TreasuryModel";
import {
  fromElement,
  TreasuryServiceModel,
} from "@pricing-tool/lib/products/treasury/model/TreasuryServiceModel";
import { hasElementAsPrerequisite } from "@pricing-tool/lib/products/treasury/core/Element";

import AddElementsModal from "../../modals/AddElementsModal";
import AddPackagesModal from "../../modals/AddPackagesModal";

import DollarInput from "../../../common/DollarInput";
import PercentInput from "../../../common/PercentInput";

import RemoveTreasuryServicesModal from "../../modals/RemoveTreasuryServicesModal";
import TreasuryServicesTable from "../fields/TreasuryServicesTable";
import IncomeStatementTable from "../../../common/IncomeStatement";
import TitledPanel from "../../../common/TitledPanel";
import CurrencyAmount from "../../../common/CurrencyAmount";

import "./styles.scss";
import { TreasuryFormProps } from "./props";

interface ConfirmRemoveTreasuryServiceState {
  treasuryServiceBeingRemoved: TreasuryServiceModel;
  additionalTreasuryServicesToRemove: TreasuryServiceModel[];
}

interface StateDefinition {
  isAddElementsModalShowing: boolean;
  isAddPackagesModalShowing: boolean;

  isConfirmRemoveTreasuryServiceModalShowing: boolean;
  confirmRemoveTreasuryServiceState?: ConfirmRemoveTreasuryServiceState;
}

enum ActionTypes {
  ShowAddElementsModal,
  HideAddElementsModal,
  ShowAddPackagesModal,
  HideAddPackagesModal,
  SetTreasuryServices,
  RemoveTreasuryService,
  CompleteRemoveTreasuryService,
}

type Actions =
  | { type: ActionTypes.ShowAddElementsModal }
  | { type: ActionTypes.HideAddElementsModal }
  | { type: ActionTypes.ShowAddPackagesModal }
  | { type: ActionTypes.HideAddPackagesModal }
  | { type: ActionTypes.SetTreasuryServices }
  | {
      type: ActionTypes.RemoveTreasuryService;
      payload: ConfirmRemoveTreasuryServiceState;
    }
  | { type: ActionTypes.CompleteRemoveTreasuryService };

const reducer = (state: StateDefinition, action: Actions) => {
  switch (action.type) {
    case ActionTypes.ShowAddElementsModal:
      return { ...state, isAddElementsModalShowing: true };
    case ActionTypes.HideAddElementsModal:
      return { ...state, isAddElementsModalShowing: false };
    case ActionTypes.ShowAddPackagesModal:
      return { ...state, isAddPackagesModalShowing: true };
    case ActionTypes.HideAddPackagesModal:
      return { ...state, isAddPackagesModalShowing: false };
    case ActionTypes.SetTreasuryServices:
      return {
        ...state,
        isAddElementsModalShowing: false,
        isAddPackagesModalShowing: false,
      };
    case ActionTypes.RemoveTreasuryService:
      return {
        ...state,
        isConfirmRemoveTreasuryServiceModalShowing: true,
        confirmRemoveTreasuryServiceState: action.payload,
      };
    case ActionTypes.CompleteRemoveTreasuryService:
      return {
        ...state,
        isConfirmRemoveTreasuryServiceModalShowing: false,
        confirmRemoveTreasuryServiceState: undefined,
      };
    default:
      return state;
  }
};

const TreasuryForm = ({
  elementCatalog,
  packageCatalog,
  treasuryModel,
}: TreasuryFormProps) => {
  const treasury$ = asObservable(treasuryModel);
  const [treasuryServiceModels] = useObservableState(
    () => treasuryModel.input.treasuryServices,
  );
  const [treasury] = useObservableState(() => treasury$);

  const [state, dispatch] = useReducer(reducer, {
    isAddElementsModalShowing: false,
    isAddPackagesModalShowing: false,
    isConfirmRemoveTreasuryServiceModalShowing: false,
  } as StateDefinition);

  const getAddedElementIds = useCallback(() => {
    if (!treasury || !treasury.treasuryServices) return [];
    return map(
      (treasuryService: TreasuryService) => treasuryService.element.id,
      treasury.treasuryServices,
    );
  }, [treasury]);

  const addElements = useCallback(
    (elements: Element[]) => {
      const oldTreasuryServiceModels = treasuryServiceModels || [];
      const newTreasuryServiceModels = map(fromElement, elements);
      const allTreasuryServiceModels = [
        ...oldTreasuryServiceModels,
        ...newTreasuryServiceModels,
      ];
      treasuryModel.input.treasuryServices.next(allTreasuryServiceModels);
      dispatch({ type: ActionTypes.SetTreasuryServices });
    },
    [treasuryModel, treasuryServiceModels],
  );

  const removeTreasuryService = (
    treasuryServiceModel: TreasuryServiceModel,
  ) => {
    if (!treasuryServiceModels) return;

    const elementOf = (treasuryServiceModel: TreasuryServiceModel) =>
      treasuryServiceModel.input.element;

    const notElement = (model: TreasuryServiceModel) =>
      elementOf(model).id !== elementOf(treasuryServiceModel).id;
    const newTreasuryServiceModels = filter(notElement, treasuryServiceModels);

    // determine if it is a prerequisite for any other services, which would also need to be removed
    const elementCatalog = map(elementOf, treasuryServiceModels);
    const restOfElements = map(elementOf, newTreasuryServiceModels);
    const dependentElements = filter(
      (element) =>
        hasElementAsPrerequisite(
          elementCatalog,
          element,
          elementOf(treasuryServiceModel),
        ),
      restOfElements,
    );

    if (dependentElements && dependentElements.length > 0) {
      const dependentTreasuryServices = filter(
        (treasuryServiceModel) =>
          includes(elementOf(treasuryServiceModel), dependentElements),
        treasuryServiceModels,
      );
      dispatch({
        type: ActionTypes.RemoveTreasuryService,
        payload: {
          treasuryServiceBeingRemoved: treasuryServiceModel,
          additionalTreasuryServicesToRemove: dependentTreasuryServices,
        },
      });
    } else {
      treasuryModel.input.treasuryServices.next(newTreasuryServiceModels);
    }
  };

  const confirmRemoveTreasuryService = useCallback(() => {
    if (!state.confirmRemoveTreasuryServiceState) return;
    if (!treasuryServiceModels) return;

    const getId = (treasuryServiceModel: TreasuryServiceModel) =>
      treasuryServiceModel.input.element.id;
    const idsToRemove = [
      getId(
        state.confirmRemoveTreasuryServiceState.treasuryServiceBeingRemoved,
      ),
      ...map(
        getId,
        state.confirmRemoveTreasuryServiceState
          .additionalTreasuryServicesToRemove,
      ),
    ];
    const notToRemove = (treasuryServiceModel: TreasuryServiceModel) =>
      !includes(treasuryServiceModel.input.element.id, idsToRemove);
    const newTreasuryServiceModels = filter(notToRemove, treasuryServiceModels);

    treasuryModel.input.treasuryServices.next(newTreasuryServiceModels);
    dispatch({ type: ActionTypes.CompleteRemoveTreasuryService });
  }, [treasuryModel, state, treasuryServiceModels]);

  const modals = (
    <>
      {state.isAddElementsModalShowing && (
        <AddElementsModal
          elements={elementCatalog}
          addedElementIds={getAddedElementIds()}
          onConfirm={addElements}
          onCancel={() => dispatch({ type: ActionTypes.HideAddElementsModal })}
        />
      )}
      {state.isAddPackagesModalShowing && (
        <AddPackagesModal
          packages={packageCatalog}
          addedElementIds={getAddedElementIds()}
          onConfirm={addElements}
          onCancel={() => dispatch({ type: ActionTypes.HideAddPackagesModal })}
        />
      )}
      {state.isConfirmRemoveTreasuryServiceModalShowing &&
        state.confirmRemoveTreasuryServiceState && (
          <RemoveTreasuryServicesModal
            element={
              state.confirmRemoveTreasuryServiceState
                .treasuryServiceBeingRemoved.input.element
            }
            dependentElements={map(
              (treasuryServiceModels) => treasuryServiceModels.input.element,
              state.confirmRemoveTreasuryServiceState
                .additionalTreasuryServicesToRemove,
            )}
            onConfirm={confirmRemoveTreasuryService}
            onCancel={() =>
              dispatch({ type: ActionTypes.CompleteRemoveTreasuryService })
            }
          />
        )}
    </>
  );

  return (
    <div className="treasury-form">
      <div className="flex justify-between">
        <div className="">
          <span className="uppercase text-gray-400 text-xs font-semibold">
            Elements
          </span>
          <div className="flex items-center">
            <button
              type="button"
              className="bg-transparent hover:bg-blue-500 text-blue-500 text-base font-medium hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
              onClick={() =>
                dispatch({ type: ActionTypes.ShowAddElementsModal })
              }
            >
              <FontAwesomeIcon className="w-4 h-4 mr-2" icon="cube" />
              Add Elements
            </button>
            <div className="w-4" />
            <button
              type="button"
              className="bg-transparent hover:bg-blue-500 text-blue-500 text-base font-medium hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
              onClick={() =>
                dispatch({ type: ActionTypes.ShowAddPackagesModal })
              }
            >
              <FontAwesomeIcon className="w-4 h-4 mr-2" icon="cubes" />
              Add Packages
            </button>
          </div>
        </div>
      </div>
      {treasury && (
        <>
          <div className="two-column">
            <div className="col">
              <TitledPanel title="Inputs">
                <table className="inputs-table">
                  <tbody>
                    <tr>
                      <td>Investable Balance:</td>
                      <td>
                        <DollarInput
                          value={treasury.investableBalance}
                          onChange={(value) =>
                            treasuryModel.input.investableBalance.next(value)
                          }
                          decimalPlaces={0}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>ECR:</td>
                      <td>
                        <PercentInput
                          value={treasury.earningsCreditRate}
                          onChange={(value) =>
                            treasuryModel.input.earningsCreditRate.next(value)
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Discount:</td>
                      <td>
                        <PercentInput
                          value={treasury.discount}
                          onChange={(value) =>
                            treasuryModel.input.discount.next(value)
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </TitledPanel>
            </div>

            <div className="col">
              <TitledPanel title="Outputs">
                <IncomeStatementTable
                  feeOrInterest="fee"
                  incomeStatement={treasury.incomeStatement}
                >
                  <tr>
                    <td>Discounted Amount:</td>
                    <td>&nbsp;</td>
                    <td>
                      <CurrencyAmount amount={treasury.discountedAmount} />
                    </td>
                  </tr>
                </IncomeStatementTable>
              </TitledPanel>
            </div>
          </div>

          <div className="treasury-services-table-container">
            <TreasuryServicesTable
              treasuryServiceModels={treasuryServiceModels}
              onTreasuryServiceDelete={removeTreasuryService}
            />
          </div>
        </>
      )}

      {/*<pre className="text-gray-50">*/}
      {/*  {treasury && JSON.stringify(treasury, null, 2)}*/}
      {/*</pre>*/}

      {modals}
    </div>
  );
};

export default TreasuryForm;
