import {
  BehaviorSubject,
  combineLatest,
  map,
  Observable,
  of,
  share,
} from "rxjs";
import Decimal from "decimal.js-light";

import anyChanged from "../../../util/operators/anyChanged";
import fieldChanged from "../../../util/operators/fieldChanged";
import mapAllDefined from "../../../util/operators/mapAllDefined";

import { CollateralItem, CommercialIndustrialDependencies } from "../core";
import { CollateralType } from "../core/CollateralRecoveryTable";

export type CollateralItemInput = {
  loanAmount: Observable<Decimal | undefined>;
  collateralType: BehaviorSubject<CollateralType | undefined>;
  name: BehaviorSubject<string | undefined>;
  value: BehaviorSubject<Decimal | undefined>;
  priorLiens: BehaviorSubject<Decimal | undefined>;
};

export type CollateralItemOutput = {
  combinedLoanToValueRatio: Observable<Decimal | undefined>;
  effectiveValue: Observable<Decimal | undefined>;
  effectiveLoanToValueRatio: Observable<Decimal | undefined>;
};

export type CollateralItemModel = {
  isDirty: Observable<boolean>;
  input: CollateralItemInput;
  output: CollateralItemOutput;
};

export const trackChanges = (input: CollateralItemInput): Observable<boolean> =>
  anyChanged([
    fieldChanged(input.name),
    fieldChanged(input.value),
    fieldChanged(input.collateralType),
    fieldChanged(input.priorLiens),
  ]);

export const calculate = (
  input: CollateralItemInput,
  dependencies: Pick<CommercialIndustrialDependencies, any>,
): CollateralItemOutput => {
  const { loanAmount, value, priorLiens, collateralType } = input;

  const combinedLoanToValueRatio = combineLatest([
    loanAmount,
    value,
    priorLiens,
  ]).pipe(
    mapAllDefined(([loanAmount, value, priorLiens]) =>
      value.eq(0) ? undefined : loanAmount.plus(priorLiens).dividedBy(value),
    ),
  );

  const effectiveValue = combineLatest([
    value,
    priorLiens,
    collateralType,
  ]).pipe(
    mapAllDefined(([value, priorLiens, collateralType]) =>
      value.minus(priorLiens.dividedBy(collateralType.netRecoveryRate)),
    ),
  );

  const effectiveLoanToValueRatio = combineLatest([
    loanAmount,
    effectiveValue,
  ]).pipe(
    mapAllDefined(([loanAmount, effectiveValue]) =>
      effectiveValue.eq(0) ? undefined : loanAmount.dividedBy(effectiveValue),
    ),
  );

  return {
    combinedLoanToValueRatio,
    effectiveValue,
    effectiveLoanToValueRatio,
  };
};

export const fromCollateralType = (
  collateralType: CollateralType | undefined,
  loanAmount: Observable<Decimal | undefined>,
  dependencies: Pick<CommercialIndustrialDependencies, any>,
): CollateralItemModel => {
  const input: CollateralItemInput = {
    collateralType: new BehaviorSubject<CollateralType | undefined>(
      collateralType,
    ),
    loanAmount,
    name: new BehaviorSubject<string | undefined>(""),
    value: new BehaviorSubject<Decimal | undefined>(undefined),
    priorLiens: new BehaviorSubject<Decimal | undefined>(undefined),
  };

  const output = calculate(input, dependencies);

  return {
    isDirty: of(true),
    input,
    output,
  };
};

export const fromCollateralItem = (
  collateralItem: CollateralItem,
  loanAmount: Observable<Decimal | undefined>,
  dependencies: Pick<CommercialIndustrialDependencies, any>,
): CollateralItemModel => {
  const input: CollateralItemInput = {
    collateralType: new BehaviorSubject<CollateralType | undefined>(
      collateralItem.collateralType,
    ),
    loanAmount,
    name: new BehaviorSubject<string | undefined>(collateralItem.name),
    value: new BehaviorSubject<Decimal | undefined>(collateralItem.value),
    priorLiens: new BehaviorSubject<Decimal | undefined>(
      collateralItem.priorLiens,
    ),
  };

  const output = calculate(input, dependencies);
  const isDirty = trackChanges(input);

  return {
    isDirty,
    input,
    output,
  };
};

export const asObservable = (
  model: CollateralItemModel,
): Observable<CollateralItem> => {
  const { collateralType, name, value, priorLiens } = model.input;
  const {
    combinedLoanToValueRatio,
    effectiveValue,
    effectiveLoanToValueRatio,
  } = model.output;

  return combineLatest([
    name,
    collateralType,
    value,
    priorLiens,
    combinedLoanToValueRatio,
    effectiveValue,
    effectiveLoanToValueRatio,
  ]).pipe(
    map(
      ([
        name,
        collateralType,
        value,
        priorLiens,
        combinedLoanToValueRatio,
        effectiveValue,
        effectiveLoanToValueRatio,
      ]) => ({
        collateralType,
        name,
        value,
        priorLiens,
        combinedLoanToValueRatio,
        effectiveValue,
        effectiveLoanToValueRatio,
      }),
    ),
    share(),
  );
};
