import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Menu } from "@headlessui/react";

import "./styles.scss";

import logo from "./wijs_logo.png";

export type NavigationBarProps = {
  user: any;
  signOut: Function;
};

const NavigationBar = ({ user, signOut }: NavigationBarProps) => {
  return (
    <div className="component navigation-bar">
      <div className="content-group">
        <Link className="logo" to={"/"}>
          <img src={logo} alt="Wijs Labs logo" />
        </Link>
        <Link className="menu-item" to={"/clients"}>
          Clients
        </Link>
        <Link className="menu-item" to={"/opportunities"}>
          Opportunities
        </Link>
      </div>
      <div className="content-group">
        <span className="mr-3">{user.attributes?.email}</span>
        <Link className="menu-item" to={"/settings"}>
          <FontAwesomeIcon icon={["fas", "cog"]} />
        </Link>
        <Menu as="div" className="h-full">
          <Menu.Button className="menu-item drop-down">
            <FontAwesomeIcon icon={["fas", "user"]} />
          </Menu.Button>
          <Menu.Items className="drop-down-items">
            <div className="px-1 py-1 ">
              <Menu.Item as="div">
                {({ active }) => (
                  <button
                    onClick={() => signOut()}
                    className={`${
                      active ? "bg-blue-100" : "text-gray-900"
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                  >
                    <span className="w-6 h-full">
                      <FontAwesomeIcon icon={["far", "file-alt"]} />
                    </span>
                    Sign Out
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Menu>
      </div>
    </div>
  );
};

export default NavigationBar;
