import { match } from "ts-pattern";

import { Product } from "./core";
import { ProductModel, ScenarioDependencies } from "../scenario/model";

import { Treasury, TreasuryDependencies } from "./treasury/core";
import { fromTreasury } from "./treasury/model/TreasuryModel";

import { fromCommercialIndustrial } from "./ci/model/CommercialIndustrialModel";
import {
  CommercialIndustrial,
  CommercialIndustrialDependencies,
} from "./ci/core";

import { fromDeposits } from "./deposits/model";
import { Deposits, DepositsDependencies } from "./deposits/core";

import {
  CommercialRealEstate,
  CommercialRealEstateDependencies,
} from "./cre/core";
import { fromCommercialRealEstate } from "./cre/model";

export const fromProduct = (
  product: Product,
  dependencies: ScenarioDependencies,
): ProductModel => {
  return match(product._type)
    .with("treasury", () =>
      fromTreasury(product as Treasury, dependencies as TreasuryDependencies),
    )
    .with("ci", () =>
      fromCommercialIndustrial(
        product as CommercialIndustrial,
        dependencies as CommercialIndustrialDependencies,
      ),
    )
    .with("deposits", () =>
      fromDeposits(product as Deposits, dependencies as DepositsDependencies),
    )
    .with("cre", () =>
      fromCommercialRealEstate(
        product as CommercialRealEstate,
        dependencies as CommercialRealEstateDependencies,
      ),
    )
    .exhaustive();
};
