import { Schema as S } from "@effect/schema";

import { DecimalFromString } from "../../../util/Decimal";

export const CollateralTypeSchema = S.Struct({
  id: S.String,
  type: S.String,
  nominalRecoveryRate: DecimalFromString,
  averageTimeToRecoveryInYears: DecimalFromString,
  netRecoveryRate: DecimalFromString,
  loanToValueRatio: DecimalFromString,
});

export const CollateralRecoveryTableSchema = S.Array(CollateralTypeSchema);

export type CollateralRecoveryTable = S.Schema.Type<
  typeof CollateralRecoveryTableSchema
>;

export type CollateralType = S.Schema.Type<typeof CollateralTypeSchema>;
