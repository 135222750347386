import Decimal from "decimal.js-light";
import { BehaviorSubject } from "rxjs";
import { Schema as S } from "@effect/schema";

type IsDecimal<T> = T extends Decimal
  ? Decimal
  : T extends Decimal | undefined
  ? Decimal | undefined
  : never;

type AnyDecimal<T> = keyof OnlyDecimal<T> extends never ? never : T;

export type OnlyDecimal<T> = {
  [Key in keyof T as T[Key] extends IsDecimal<T[Key]>
    ? Key
    : T[Key] extends BehaviorSubject<Decimal>
    ? never
    : T[Key] extends BehaviorSubject<Decimal | undefined>
    ? never
    : T[Key] extends AnyDecimal<T[Key]>
    ? Key
    : never]: T[Key];
};

export const DecimalFromString = S.transform(S.String, S.instanceOf(Decimal), {
  strict: true,
  decode: (str) => new Decimal(str),
  encode: (dec) => dec.toString(),
});

export const OptDecimalFromString = S.optional(DecimalFromString);
