import React from "react";

import { Opportunity } from "@pricing-tool/lib/opportunity/core";
import { Client } from "@pricing-tool/graphql/lib/react";

import Modal from "../../../common/Modal";
import OpportunityForm from "../../forms/OpportunityForm";
import CreateClientModal from "../../../client/modals/CreateClientModal";

import "./styles.scss";

export type EditOpportunityModalProps = {
  opportunity: Opportunity;
  onClose: () => void;
  onConfirm: (opportunity: Opportunity) => void;
};

const EditOpportunityModal = ({
  opportunity,
  onClose,
  onConfirm,
}: EditOpportunityModalProps) => {
  const [showCreateClientModal, setShowCreateClientModal] =
    React.useState(false);
  const [createdClient, setCreatedClient] = React.useState<
    Client | undefined
  >();

  const onClientCreated = (client: Client) => {
    setCreatedClient(client);
    setShowCreateClientModal(false);
  };

  return (
    <div className="edit-opportunity-modal">
      {!showCreateClientModal && (
        <Modal
          title="Edit Opportunity"
          width={500}
          contentHeight={500}
          onClose={onClose}
        >
          <OpportunityForm
            opportunity={opportunity}
            client={createdClient}
            onCancel={onClose}
            onSubmit={onConfirm}
            onCreateClient={() => setShowCreateClientModal(true)}
          />
        </Modal>
      )}

      {showCreateClientModal && (
        <CreateClientModal
          onClose={() => setShowCreateClientModal(false)}
          onCreated={onClientCreated}
        />
      )}
    </div>
  );
};

export default EditOpportunityModal;
