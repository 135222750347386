import React, { useCallback, useState } from "react";
import Helmet from "react-helmet";
import { useNavigate } from "react-router";

import { Opportunity } from "@pricing-tool/lib/opportunity/core";

import {
  Client,
  useCreateOpportunityMutation,
} from "@pricing-tool/graphql/lib/react";

import OpportunityForm from "../../../components/opportunity/forms/OpportunityForm";
import LoadingOverlay from "../../../components/common/LoadingOverlay";
import ErrorBanner from "../../../components/common/ErrorBanner";
import CreateClientModal from "../../../components/client/modals/CreateClientModal";

import "./styles.scss";

function CreateOpportunity() {
  const navigate = useNavigate();

  const [showCreateClientModal, setShowCreateClientModal] = useState(false);
  const [createdClient, setCreatedClient] = useState<Client | undefined>();

  const [createOpportunity, { loading, error }] =
    useCreateOpportunityMutation();

  const onSubmit = useCallback(
    (opportunity: Opportunity) => {
      createOpportunity({
        variables: {
          branchId: "branch-1",
          input: {
            branchId: "branch-1",
            name: opportunity.name,
            clientId: opportunity.client!.id,
            status: opportunity.status,
            closeDate: opportunity.closeDate,
            pricingDate: opportunity.pricingDate,
            scenarios: [],
          },
        },
      }).then((createdOpportunity) => {
        if (createdOpportunity.data?.createOpportunity) {
          navigate(
            `/opportunities/${createdOpportunity.data.createOpportunity.id}`,
          );
        }
      });
    },
    [createOpportunity, navigate],
  );

  const onCancel = () => {
    navigate("/");
  };

  const onClientCreated = (client: Client) => {
    setCreatedClient(client);
    setShowCreateClientModal(false);
  };

  return (
    <div className="page create-opportunity">
      <Helmet>
        <title>Create Opportunity</title>
      </Helmet>
      <div className="text-2xl p-2">Create New Opportunity</div>
      <OpportunityForm
        client={createdClient}
        onCancel={onCancel}
        onSubmit={onSubmit}
        submitButtonLabel="Create"
        onCreateClient={() => setShowCreateClientModal(true)}
      />

      {showCreateClientModal && (
        <CreateClientModal
          onClose={() => setShowCreateClientModal(false)}
          onCreated={onClientCreated}
        />
      )}

      {loading && <LoadingOverlay text="Creating opportunity" />}
      {error && <ErrorBanner text={error.message} />}
    </div>
  );
}

export default CreateOpportunity;
