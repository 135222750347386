import React, { forwardRef } from "react";
import { map } from "ramda";
import Decimal from "decimal.js-light";

import { TreasuryServiceModel } from "@pricing-tool/lib/products/treasury/model/TreasuryServiceModel";

import TreasuryServiceRow from "../TreasuryServiceRow";
import { Modifier } from "../../../../../common/Input";
import GroupSelectIcon from "../GroupSelectIcon";
import { TreasuryServiceRowRef } from "../TreasuryServiceRow/component";
import useShareForwardedRef from "../../../../../../hooks/useSharedForwardedRef";

type ProductFamilyRowGroupProps = {
  productFamily: string;
  treasuryServiceModels: TreasuryServiceModel[];
  selectedColumn?: string;
  selectedKeys: string[];
  onTreasuryServiceDelete: (treasuryServiceModel: TreasuryServiceModel) => void;
  onInputSelect: (
    key: string,
    column: string,
    modifier: Modifier,
    input: HTMLInputElement,
  ) => void;
  onInputDeselect: (input: HTMLInputElement) => void;
  onInputChange: (value: Decimal | number) => void;
  onGroupSelect?: (
    keys: string[],
    column: string,
    modifier: Modifier,
    input: HTMLInputElement,
    activeKey: string,
  ) => void;
};

const ProductFamilyRowGroup = forwardRef<
  TreasuryServiceRowRef,
  ProductFamilyRowGroupProps
>(
  (
    {
      productFamily,
      treasuryServiceModels,
      selectedColumn,
      selectedKeys,
      onTreasuryServiceDelete,
      onInputSelect,
      onInputDeselect,
      onInputChange,
      onGroupSelect,
    }: ProductFamilyRowGroupProps,
    forwardedRef,
  ) => {
    const firstRowRef =
      useShareForwardedRef<TreasuryServiceRowRef>(forwardedRef);

    const _onGroupSelect = (column: string) => (modifier: Modifier) => {
      if (!firstRowRef.current) return;
      if (!firstRowRef.current.discount) return;
      if (!firstRowRef.current.volume) return;
      if (!firstRowRef.current.price) return;

      let input: HTMLInputElement = firstRowRef.current.discount;
      if (column === "discount") input = firstRowRef.current.discount;
      if (column === "price") input = firstRowRef.current.price;
      if (column === "volume") input = firstRowRef.current.volume;

      const keysInProductFamily = map(
        (treasuryServiceModel) => treasuryServiceModel.input.element.id,
        treasuryServiceModels,
      );
      onGroupSelect &&
        onGroupSelect(
          keysInProductFamily,
          column,
          modifier,
          input,
          keysInProductFamily[0],
        );
    };

    const productFamilyRow = (
      <tr key={productFamily} className="product-family-row">
        <td colSpan={3} className="product-family">
          {productFamily}
        </td>
        <td className="edit">
          Discount{" "}
          <GroupSelectIcon onGroupSelect={_onGroupSelect("discount")} />
        </td>
        <td className="edit">
          Volume <GroupSelectIcon onGroupSelect={_onGroupSelect("volume")} />
        </td>
        <td className="edit">
          Price <GroupSelectIcon onGroupSelect={_onGroupSelect("price")} />
        </td>
        <td colSpan={3}>&nbsp;</td>
      </tr>
    );

    const [firstTreasuryService, ...restTreasuryServices] =
      treasuryServiceModels;

    const firstRow = (
      <TreasuryServiceRow
        ref={firstRowRef}
        key={firstTreasuryService.input.element.id}
        selectedColumn={selectedColumn}
        selectedKeys={selectedKeys}
        treasuryServiceModel={firstTreasuryService}
        onDelete={() => onTreasuryServiceDelete(firstTreasuryService)}
        onInputSelect={onInputSelect}
        onInputDeselect={onInputDeselect}
        onInputChange={onInputChange}
      />
    );

    const rows = map(
      (treasuryServiceModel) => (
        <TreasuryServiceRow
          key={treasuryServiceModel.input.element.id}
          selectedColumn={selectedColumn}
          selectedKeys={selectedKeys}
          treasuryServiceModel={treasuryServiceModel}
          onDelete={() => onTreasuryServiceDelete(treasuryServiceModel)}
          onInputSelect={onInputSelect}
          onInputDeselect={onInputDeselect}
          onInputChange={onInputChange}
        />
      ),
      restTreasuryServices,
    );
    return <tbody>{[productFamilyRow, firstRow, ...rows]}</tbody>;
  },
);

export default ProductFamilyRowGroup;
