import React, { PropsWithChildren, useCallback, useRef, useState } from "react";

import "./styles.scss";
import Modal from "../Modal";

export type ScenarioNameModalProps = {
  onClose: () => void;
  onConfirm: (name: string) => void;
};

const ScenarioNameModal = ({
  onClose,
  onConfirm,
}: PropsWithChildren<ScenarioNameModalProps>) => {
  const [name, setName] = useState("");

  const nameFieldRef = useRef<HTMLInputElement>(null);
  const focusNameField = () => {
    if (nameFieldRef.current) {
      (nameFieldRef.current as HTMLInputElement).focus();
    }
  };

  React.useEffect(focusNameField, [nameFieldRef]);

  const _onConfirm = useCallback(() => {
    if (name === "") {
      // TODO - visual indicator of issue with name
    } else {
      onConfirm(name);
    }
  }, [onConfirm, name]);

  return (
    <Modal
      title={"Scenario Name"}
      width={400}
      contentHeight={400}
      onClose={onClose}
    >
      <div className="add-elements-form">
        <div className="header relative mb-3 mx-3">
          <input
            className="search-input w-full pl-1 py-1 border rounded-md bg-gray-100 text-sm"
            type="text"
            ref={nameFieldRef}
            onChange={(field) => setName(field.target.value)}
            placeholder="Scenario name"
            value={name}
          />
        </div>
        <div className="footer">
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:items-end">
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-auto sm:w-auto sm:text-sm"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={_onConfirm}
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ScenarioNameModal;
