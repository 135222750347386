import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Menu } from "@headlessui/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { ScenarioSummary } from "../../utils/ScenarioSummary";

import "./styles.scss";

export type ScenarioProps = {
  scenario: ScenarioSummary;
  onSelect: () => void;
  onRemove: () => void;
  onRename: () => void;
  onDuplicate: () => void;
  onCompareWith?: () => void;
  onMakePipelineScenario?: () => void;
  onCreateDocument?: () => void;
};

const performanceColor: { [key: string]: string } = {
  meets: "bg-green-400",
  close: "bg-amber-400",
  neutral: "bg-gray-300",
  poor: "bg-red-500",
};

const performanceIcon: { [key: string]: IconProp | undefined } = {
  meets: ["fas", "battery-full"],
  close: ["fas", "battery-three-quarters"],
  neutral: undefined,
  poor: ["fas", "battery-quarter"],
};

const ScenarioMenu = ({
  onRemove,
  onRename,
  onDuplicate,
  onCompareWith,
  onMakePipelineScenario,
  onCreateDocument,
}: ScenarioProps) => {
  return (
    <Menu as="div" className="relative inline-block text-left w-full">
      <Menu.Button className="w-full text-gray-300 hover:text-blue-300">
        <FontAwesomeIcon icon="ellipsis-v" />
      </Menu.Button>
      <Menu.Items className="absolute -left-2 w-44 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
        {onMakePipelineScenario && (
          <div className="px-1 py-1 ">
            <Menu.Item as="div">
              {({ active }) => (
                <button
                  onClick={onMakePipelineScenario}
                  className={`${
                    active ? "bg-blue-100" : "text-gray-900"
                  } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                >
                  <span className="w-6 h-full">
                    <FontAwesomeIcon icon={["far", "star"]} />
                  </span>
                  Make Pipeline
                </button>
              )}
            </Menu.Item>
          </div>
        )}
        {onCreateDocument && (
          <div className="px-1 py-1 ">
            <Menu.Item as="div">
              {({ active }) => (
                <button
                  onClick={onCreateDocument}
                  className={`${
                    active ? "bg-blue-100" : "text-gray-900"
                  } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                >
                  <span className="w-6 h-full">
                    <FontAwesomeIcon icon={["fas", "print"]} />
                  </span>
                  Create Document
                </button>
              )}
            </Menu.Item>
          </div>
        )}
        <div className="px-1 py-1 ">
          <Menu.Item as="div">
            {({ active }) => (
              <button
                onClick={onRename}
                className={`${
                  active ? "bg-blue-100" : "text-gray-900"
                } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
              >
                <span className="w-6 h-full">
                  <FontAwesomeIcon icon={["far", "file-alt"]} />
                </span>
                Rename
              </button>
            )}
          </Menu.Item>
        </div>
        <div className="px-1 py-1 ">
          <Menu.Item as="div">
            {({ active }) => (
              <button
                onClick={onDuplicate}
                className={`${
                  active ? "bg-blue-100" : "text-gray-900"
                } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
              >
                <span className="w-6 h-full">
                  <FontAwesomeIcon icon={["far", "copy"]} />
                </span>
                Duplicate
              </button>
            )}
          </Menu.Item>
        </div>
        {onCompareWith && (
          <div className="px-1 py-1">
            <Menu.Item as="div">
              {({ active }) => (
                <button
                  onClick={onCompareWith}
                  className={`${
                    active ? "bg-blue-100" : "text-gray-900"
                  } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                >
                  <span className="w-6 h-full">
                    <FontAwesomeIcon icon={["far", "dot-circle"]} />
                  </span>
                  Compare with...
                </button>
              )}
            </Menu.Item>
          </div>
        )}
        <div className="px-1 py-1">
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={onRemove}
                className={`${
                  active ? "bg-blue-100" : "text-gray-900"
                } group flex rounded-md items-center w-full px-2 py-2 text-sm text-red-400`}
              >
                <span className="w-6 h-full">
                  <FontAwesomeIcon icon={["far", "trash-alt"]} />
                </span>
                Delete
              </button>
            )}
          </Menu.Item>
        </div>
      </Menu.Items>
    </Menu>
  );
};

const Component = (props: ScenarioProps) => {
  const { scenario, onSelect, onMakePipelineScenario } = props;
  const { name, selected, metrics, performance } = scenario;
  const { returnOnEquity, returnOnAssets } = metrics;
  const { aggregatePerformance } = performance;

  return (
    <div className="relative">
      {!onMakePipelineScenario && (
        <>
          <div className="pipeline-star pointer-events-none">
            <FontAwesomeIcon icon="star" size="lg" className="bigger" />
            <FontAwesomeIcon icon="star" size="lg" />
          </div>
        </>
      )}
      <div
        className={`flex flex-none pb-2 border-b-2 ${
          selected ? "border-blue-600" : "border-gray-200"
        } w-min`}
      >
        <div
          className={`${performanceColor[aggregatePerformance]} flex flex-none w-12 aspect-square justify-center items-center rounded-md`}
        >
          {aggregatePerformance && performanceIcon[aggregatePerformance] && (
            <FontAwesomeIcon
              icon={performanceIcon[aggregatePerformance]!}
              size="lg"
              color="white"
            />
          )}
        </div>
        <div className="flex px-2 flex-col text-sm justify-evenly w-32">
          <button
            className="hover:underline decoration-4 decoration-blue-200 mb-1"
            onClick={onSelect}
            title={name}
          >
            <div className="truncate text-left font-semibold">{name}</div>
          </button>
          <div className="whitespace-nowrap text-xs">
            <span className="font-thin">ROE</span>:{" "}
            {returnOnEquity ? (
              <span className="font-normal">
                {returnOnEquity.mul(100).toFormat(2)}%
              </span>
            ) : (
              <span className="font-normal">Unknown</span>
            )}
          </div>
          <div className="whitespace-nowrap text-xs">
            <span className="font-thin">ROA</span>:{" "}
            {returnOnAssets ? (
              <span className="font-normal">
                {returnOnAssets.mul(100).toFormat(2)}%
              </span>
            ) : (
              <span className="font-normal">Unknown</span>
            )}
          </div>
        </div>
        <div
          className={`flex flex-none w-8 aspect-square justify-center items-center rounded-md`}
        >
          <ScenarioMenu {...props} />
        </div>
      </div>
    </div>
  );
};

export default Component;
