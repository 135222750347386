import { useCallback, useRef, useState } from "react";
import { toLower } from "ramda";

import {
  OpportunitySummaryFragment,
  useSearchOpportunitiesByNameLazyQuery,
} from "@pricing-tool/graphql/lib/react";

import Component from "./component";
import ErrorBanner from "../../../common/ErrorBanner";

type OpportunitySearchFormProps = {
  onSelectOpportunity: (opportunity: OpportunitySummaryFragment) => void;
  onCancel: () => void;
};

function OpportunitySearchForm({
  onSelectOpportunity,
  onCancel,
}: OpportunitySearchFormProps) {
  const abortControllerRef = useRef<AbortController>();

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<
    OpportunitySummaryFragment[]
  >([]);

  const [searchOpportunitiesByName, { loading, error }] =
    useSearchOpportunitiesByNameLazyQuery();

  const onSearch = useCallback(
    (search: string) => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      if (search === "") {
        setSearchResults([]);
        abortControllerRef.current = undefined;
        return;
      }

      abortControllerRef.current = new AbortController();

      searchOpportunitiesByName({
        variables: {
          branchId: "branch-1",
          searchTerm: toLower(search),
        },
        fetchPolicy: "network-only",
        context: {
          fetchOptions: {
            signal: abortControllerRef.current.signal,
          },
        },
      }).then(({ data }) => {
        setSearchResults(data?.searchOpportunitiesByName.items || []);
        abortControllerRef.current = undefined;
      });
    },
    [searchOpportunitiesByName],
  );

  return (
    <>
      <Component
        searchTerm={searchTerm}
        searching={loading}
        onCancel={onCancel}
        onSearchTermUpdated={setSearchTerm}
        onSearch={onSearch}
        searchResults={searchResults}
        onSelectOpportunity={onSelectOpportunity}
      />

      {error && <ErrorBanner text={error.message} />}
    </>
  );
}

export default OpportunitySearchForm;
