import React, { useCallback } from "react";
import { ApolloError } from "@apollo/client";

import {
  Client,
  useCreateClientMutation,
} from "@pricing-tool/graphql/lib/react";

import Modal from "../../../common/Modal";
import LoadingOverlay from "../../../common/LoadingOverlay";
import ErrorBanner from "../../../common/ErrorBanner";
import ClientForm, { ClientFormValue } from "../../forms/ClientForm";

import "./styles.scss";

export type CreateClientModalProps = {
  onClose: () => void;
  onCreated: (client: Client) => void;
};

const CreateClientModal = ({ onClose, onCreated }: CreateClientModalProps) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<ApolloError | null>(null);

  let [createClient] = useCreateClientMutation();

  const onSubmit = useCallback(
    (client: ClientFormValue) => {
      setLoading(true);
      createClient({
        variables: {
          branchId: "branch-1",
          input: {
            branchId: "branch-1",
            ...client,
          },
        },
      })
        .then((createdClient) => {
          if (!createdClient.data?.createClient) {
            setError(
              new ApolloError({
                errorMessage: "Failed to create client",
              }),
            );
            setLoading(false);
            return;
          }

          onCreated(createdClient.data.createClient);
        })
        .catch((err) => {
          console.log(err);
          setError(
            new ApolloError({
              errorMessage: "Failed to create client",
            }),
          );
          setLoading(false);
        });
    },
    [createClient, onCreated],
  );

  return (
    <div className="modal create-client">
      <Modal
        title="Create Client"
        width={500}
        contentHeight={500}
        onClose={onClose}
      >
        <ClientForm onCancel={onClose} onSubmit={onSubmit} />

        {loading && <LoadingOverlay text="Creating client" />}
        {error && <ErrorBanner text={error.message} />}
      </Modal>
    </div>
  );
};

export default CreateClientModal;
