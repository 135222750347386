import { forwardRef, TextareaHTMLAttributes } from "react";

import "./styles.scss";

type TextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  className?: string;
};

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (props, ref) => {
    const { value, onChange, className, label, ...rest } = props;

    return (
      <div>
        {props.label && (
          <label
            htmlFor="input-box"
            className="block text-md font-medium text-gray-700"
          >
            {props.label}
          </label>
        )}
        <div
          className={`${
            props.label ? "mt-1" : ""
          } relative rounded-md shadow-sm`}
        >
          <textarea
            ref={ref}
            className={`${props.className} focus:ring-2 focus:ring-blue-500 block w-full py-1 border border-gray-300 rounded-md`}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            {...rest}
          />
        </div>
      </div>
    );
  },
);

export default TextArea;
