import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import { Opportunity as OpportunityType } from "@pricing-tool/lib/opportunity/core";

export type OpportunityDetailsProps = {
  opportunity: OpportunityType;
  collapsed?: boolean;
  onCollapseToggle: () => void;
  onEditButtonPressed: () => void;
};

const OpportunityDetails = ({
  collapsed,
  opportunity,
  onCollapseToggle,
  onEditButtonPressed,
}: OpportunityDetailsProps) => {
  if (collapsed) {
    return (
      <div className="py-2 px-4 bg-blue-100">
        <span className="inline-block w-4">
          <button onClick={onCollapseToggle}>
            <FontAwesomeIcon icon="chevron-right" />
          </button>
        </span>
        <span className="px-4 font-bold text-lg">
          {opportunity.client?.name || "Unknown Client"} - {opportunity.name}
        </span>
        <span className="">
          <button onClick={onEditButtonPressed}>
            <FontAwesomeIcon icon="edit" />
          </button>
        </span>
      </div>
    );
  }

  return (
    <div className="py-2 px-4 bg-blue-100">
      <div>
        <span className="inline-block w-4">
          <button onClick={onCollapseToggle}>
            <FontAwesomeIcon icon="chevron-down" />
          </button>
        </span>
        <span className="px-4 font-bold text-lg">
          {opportunity.client?.name || "Unknown Client"} - {opportunity.name}
        </span>
        <span className="">
          <button onClick={onEditButtonPressed}>
            <FontAwesomeIcon icon="edit" />
          </button>
        </span>
      </div>
      <div className="pl-8 py-3 text-xs">
        <span className="inline-block pr-6">
          <div className="pb-1">
            <span className="inline-block w-6 text-sm">
              <FontAwesomeIcon icon="user" />
            </span>
            <span className="text-sm">
              {opportunity.client?.name || "Unknown Client"}
            </span>
          </div>
          <div>
            <span className="inline-block w-6 text-sm">
              <FontAwesomeIcon icon="user-friends" />
            </span>
            <span className="text-sm">{opportunity.owner}</span>
          </div>
        </span>
        <span className="inline-block pr-4 pl-2 border-l-gray-500 border-l">
          <div className="font-semibold uppercase text-gray-500 pb-2">
            Pricing Date
          </div>
          <div className="">
            {opportunity.pricingDate
              ? moment(opportunity.pricingDate).format("LL")
              : "--"}
          </div>
        </span>
        <span className="inline-block pr-4 pl-2 border-l-gray-500 border-l">
          <div className="font-semibold uppercase text-gray-500 pb-2">
            Close Date
          </div>
          <div className="">
            {opportunity.closeDate
              ? moment(opportunity.closeDate).format("LL")
              : "--"}
          </div>
        </span>
        <span className="inline-block pr-4 pl-2 border-l-gray-500 border-l">
          <div className="font-semibold uppercase text-gray-500 pb-2">
            Region
          </div>
          <div className="">{opportunity.region || "--"}</div>
        </span>
      </div>
    </div>
  );
};

export default OpportunityDetails;
