import { Schema as S } from "@effect/schema";

import { TreasurySchema } from "./treasury/core";
import { CommercialIndustrialSchema } from "./ci/core";
import { DepositsSchema } from "./deposits/core";
import { CommercialRealEstateSchema } from "./cre/core";

export const ProductSchema = S.Union(
  TreasurySchema,
  CommercialIndustrialSchema,
  DepositsSchema,
  CommercialRealEstateSchema,
);
export type Product = S.Schema.Type<typeof ProductSchema>;

export const ProductTypeSchema = S.Literal("treasury", "ci", "deposits", "cre");
export type ProductType = S.Schema.Type<typeof ProductTypeSchema>;

export const ProductsFromStringSchema = S.parseJson(S.Array(ProductSchema));

export const products: ProductType[] = ["treasury", "ci", "deposits", "cre"];
