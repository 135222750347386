import React, { useState } from "react";

import { Opportunity } from "@pricing-tool/lib/opportunity/core";

import OpportunityDetails from "./component";
import EditOpportunityModal from "../modals/EditOpportunityModal";

export type OpportunityProps = {
  opportunity: Opportunity;
  onEdit: (opportunity: Opportunity) => void;
};

const Component = (props: OpportunityProps) => {
  const [collapsed, setCollapsed] = useState(true);
  const [editModalDisplayed, setEditModalDisplayed] = useState(false);

  return (
    <>
      <OpportunityDetails
        opportunity={props.opportunity}
        collapsed={collapsed}
        onCollapseToggle={() => setCollapsed(!collapsed)}
        onEditButtonPressed={() => setEditModalDisplayed(true)}
      />

      {editModalDisplayed && (
        <EditOpportunityModal
          opportunity={props.opportunity}
          onClose={() => setEditModalDisplayed(false)}
          onConfirm={(opportunity) => {
            setEditModalDisplayed(false);
            props.onEdit(opportunity);
          }}
        />
      )}
    </>
  );
};

export default Component;
