import React from "react";
import { find } from "ramda";

import Modal from "../../../../common/Modal";

import ScenarioComparator from "../../ScenarioComparator";
import { OpportunityIncomeStatementModalProps } from "./props";

const OpportunityIncomeStatementModal = ({
  opportunity,
  performanceTargets,
  onClose,
}: OpportunityIncomeStatementModalProps) => {
  const scenario = find(
    (scenario) => scenario.id === opportunity.pipelineScenarioId,
    opportunity.scenarios,
  );

  return (
    <Modal
      title="Income Statement"
      width={400}
      contentHeight={scenario ? 505 : 100}
      onClose={onClose}
    >
      {scenario ? (
        <ScenarioComparator
          scenarios={[scenario]}
          performanceTargets={performanceTargets}
          selectedScenarioId={scenario.id}
          comparisonScenarioIds={[]}
          onClose={onClose}
        />
      ) : (
        <>
          <div className="body px-4 py-2">
            There is no pipeline scenario for the opportunity.
          </div>
          <div className="footer">
            <div className="bg-gray-50 py-3 px-6 sm:flex sm:items-end">
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto sm:text-sm sm:ml-auto"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default OpportunityIncomeStatementModal;
