import React from "react";
import Helmet from "react-helmet";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import {
  TemplateSummaryFragment,
  useGetTemplatesQuery,
} from "@pricing-tool/graphql/lib/react";

import TemplatesTable from "../../../components/templates/tables/TemplatesTable";

function Templates() {
  const navigate = useNavigate();

  const { data, loading, error } = useGetTemplatesQuery({
    variables: {
      branchId: "branch-1",
    },
    fetchPolicy: "network-only",
  });

  return (
    <div className="page templates p-4">
      <Helmet>
        <title>Templates</title>
      </Helmet>
      <div className="text-2xl mb-4">Templates</div>

      <div className="my-3 underline decoration-4 decoration-blue-200">
        <Link to="/settings/templates/new">Create a new template</Link>
      </div>

      {loading && <div>Loading...</div>}
      {error && <div>Error: {error.message}</div>}
      {data && data.getTemplates && (
        <TemplatesTable
          templates={data.getTemplates as TemplateSummaryFragment[]}
          onSelect={(template) =>
            navigate(`/settings/templates/${template.id}`)
          }
        />
      )}
    </div>
  );
}

export default Templates;
