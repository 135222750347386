import {
  CommercialIndustrialModel,
  fromCommercialIndustrial,
  asObservable as asCommercialIndustrialObservable,
} from "../ci/model/CommercialIndustrialModel";
import { CommercialRealEstate, CommercialRealEstateDependencies } from "./core";
import { map, Observable } from "rxjs";

export type CommercialRealEstateModel = {
  _type: "cre";
} & Omit<CommercialIndustrialModel, "_type">;

export const fromCommercialRealEstate = (
  commercialRealEstate: CommercialRealEstate,
  dependencies: CommercialRealEstateDependencies,
): CommercialRealEstateModel => {
  const { _type, ...rest } = commercialRealEstate;
  const commercialIndustrialModel = fromCommercialIndustrial(
    {
      _type: "ci",
      ...rest,
    },
    dependencies,
  );

  const { _type: _, ...creModel } = commercialIndustrialModel;
  return {
    _type: "cre",
    ...creModel,
  };
};

export const asObservable = (
  model: CommercialRealEstateModel,
): Observable<CommercialRealEstate> => {
  const { _type, ...rest } = model;
  const commercialIndustrialModel = asCommercialIndustrialObservable({
    _type: "ci",
    ...rest,
  });

  return commercialIndustrialModel.pipe(
    map((ci) => {
      const { _type: _, ...cre } = ci;
      return {
        _type: "cre",
        ...cre,
      };
    }),
  );
};
