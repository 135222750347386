import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Textarea from "react-expanding-textarea";

import "./styles.scss";

export type MessageComposerProps = {
  message?: string;
  disabled?: boolean;
  onChange: (message: string) => void;
  onSend: () => void;
};
const MessageComposer = ({
  disabled,
  message,
  onChange,
  onSend,
}: MessageComposerProps) => {
  return (
    <div className="component message-composer">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSend();
        }}
      >
        <Textarea
          disabled={disabled}
          placeholder="Type a message..."
          value={message}
          rows={1}
          onChange={(e) => onChange(e.target.value)}
        />
        <button disabled={disabled} type="submit">
          <FontAwesomeIcon icon={["fas", "paper-plane"]} />
        </button>
      </form>
    </div>
  );
};

export default MessageComposer;
