import Decimal from "decimal.js-light";
import {
  BehaviorSubject,
  combineLatest,
  map,
  merge,
  Observable,
  share,
  Subject,
  withLatestFrom,
  of,
  tap,
} from "rxjs";
import { match } from "ts-pattern";

import { TreasuryService } from "../core";
import { Element } from "../core/Element";

import anyChanged from "../../../util/operators/anyChanged";
import fieldChanged from "../../../util/operators/fieldChanged";

export type TreasuryServiceInput = {
  element: Element;
  discountInput: Subject<Decimal>;
  priceInput: BehaviorSubject<Decimal>;
  monthlyVolumeInput: BehaviorSubject<number>;
};

export type TreasuryServiceOutput = {
  discount: Observable<Decimal>;
  price: Observable<Decimal>;
  monthlyVolume: Observable<number>;
  revenue: Observable<Decimal>;
  profit: Observable<Decimal>;
};

export type TreasuryServiceModel = {
  isDirty: Observable<boolean>;
  input: TreasuryServiceInput;
  output: TreasuryServiceOutput;
};

enum PriceUpdateType {
  Price,
  Discount,
}

type PriceUpdate = [PriceUpdateType, Decimal];

const trackChanges = (
  input: TreasuryServiceInput,
  output: TreasuryServiceOutput,
): Observable<boolean> =>
  anyChanged([
    fieldChanged(output.discount),
    fieldChanged(output.price),
    fieldChanged(input.monthlyVolumeInput),
  ]);

const calculate = (input: TreasuryServiceInput): TreasuryServiceOutput => {
  const { element, discountInput, priceInput, monthlyVolumeInput } = input;

  const priceUpdate$: Observable<PriceUpdate> = merge(
    discountInput.pipe(
      map((value): PriceUpdate => [PriceUpdateType.Discount, value]),
    ),
    priceInput.pipe(
      map((value): PriceUpdate => [PriceUpdateType.Price, value]),
    ),
  );

  const discountAndPrice$ = priceUpdate$.pipe(
    map(([type, value]) => {
      const calculateNewDiscount = (newPrice: Decimal) =>
        element.rackRate
          .minus(newPrice)
          .dividedBy(element.rackRate)
          .toDecimalPlaces(4);

      const calculateNewPrice = (newDiscount: Decimal) =>
        new Decimal(1)
          .minus(newDiscount)
          .mul(element.rackRate)
          .toDecimalPlaces(6);

      return match(type)
        .with(PriceUpdateType.Price, () => [calculateNewDiscount(value), value])
        .with(PriceUpdateType.Discount, () => [value, calculateNewPrice(value)])
        .run();
    }),
  );

  const discount = discountAndPrice$.pipe(map((result) => result[0]));
  const price = discountAndPrice$.pipe(map((result) => result[1]));

  const revenue = combineLatest([price, monthlyVolumeInput]).pipe(
    map(([price, volume]: [Decimal, number]) =>
      price.mul(new Decimal(volume)).toDecimalPlaces(6),
    ),
  );

  const profit = revenue.pipe(
    map((revenue: Decimal) => revenue.mul(element.margin).toDecimalPlaces(6)),
  );

  return {
    discount,
    price,
    monthlyVolume: monthlyVolumeInput.asObservable(),
    revenue,
    profit,
  };
};

export const fromElement = (element: Element): TreasuryServiceModel => {
  const discountInput = new Subject<Decimal>();
  const priceInput = new BehaviorSubject(element.rackRate);
  const monthlyVolumeInput = new BehaviorSubject(element.monthlyVolume);

  const input = {
    element,
    discountInput,
    priceInput,
    monthlyVolumeInput,
  };

  const output = calculate(input);
  const isDirty = of(true);

  return {
    isDirty,
    input,
    output,
  };
};

export const fromTreasuryService = (
  treasuryService: TreasuryService,
): TreasuryServiceModel => {
  const element = treasuryService.element;
  const discountInput = new Subject<Decimal>();
  const priceInput = new BehaviorSubject(treasuryService.price);
  const monthlyVolumeInput = new BehaviorSubject(treasuryService.monthlyVolume);

  const input = {
    element,
    discountInput,
    priceInput,
    monthlyVolumeInput,
  };

  const output = calculate(input);
  const isDirty = trackChanges(input, output);

  return {
    isDirty,
    input,
    output,
  };
};

export const asObservable = (
  model: TreasuryServiceModel,
): Observable<TreasuryService> => {
  const { element } = model.input;
  const { discount, price, monthlyVolume, revenue, profit } = model.output;

  return combineLatest([discount, price, monthlyVolume, revenue])
    .pipe(withLatestFrom(profit))
    .pipe(
      map(([[discount, price, monthlyVolume, revenue], profit]) => ({
        element,
        discount,
        price,
        monthlyVolume,
        revenue,
        profit,
      })),
      share(),
    );
};
