import React from "react";
import { map } from "ramda";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Scenario from "../Scenario";
import { ScenariosProps } from "./props";

const Scenarios = ({
  opportunityId,
  scenarios,
  pipelineScenarioId,
  onAddScenario,
  onSelectScenario,
  onRemoveScenario,
  onRenameScenario,
  onDuplicateScenario,
  onCompareScenarioWith,
  onMakePipelineScenario,
}: ScenariosProps) => {
  return (
    <div className="py-2 px-4 bg-gray-100">
      <div className="uppercase text-gray-400 text-xs font-semibold">
        Scenarios
      </div>
      <div className="flex items-center py-2">
        {map(
          (scenario) => (
            <div key={scenario.name} className="pr-4">
              <Scenario
                opportunityId={opportunityId}
                scenario={scenario}
                onSelect={() => onSelectScenario(scenario.id)}
                onRemove={() => onRemoveScenario(scenario.id)}
                onRename={(name: string) => onRenameScenario(scenario.id, name)}
                onDuplicate={(name: string) =>
                  onDuplicateScenario(scenario.id, name)
                }
                onCompareWith={
                  scenarios.length > 1
                    ? () => onCompareScenarioWith(scenario.id)
                    : undefined
                }
                onMakePipelineScenario={
                  scenario.id === pipelineScenarioId
                    ? undefined
                    : () => onMakePipelineScenario(scenario.id)
                }
              />
            </div>
          ),
          scenarios,
        )}
        <button onClick={onAddScenario}>
          <div className="flex w-10 justify-center items-center aspect-square rounded-full border-blue-300 border-2">
            <FontAwesomeIcon className="text-blue-300" icon="plus" size="lg" />
          </div>
        </button>
      </div>
    </div>
  );
};

export default Scenarios;
