import { Schema as S } from "@effect/schema";
import Decimal from "decimal.js-light";
import { match } from "ts-pattern";

import { Product, ProductType } from "@pricing-tool/lib/products/core";

import { IncomeStatement } from "@pricing-tool/lib/incomeStatement";
import { DecimalFromString } from "@pricing-tool/lib/util/Decimal";

export const ProductSummarySchema = S.Struct({
  productType: S.Literal("treasury", "ci", "deposits", "cre"), // FIXME
  name: S.String,
  keyMetricName: S.String,
  keyMetricUnit: S.Literal("dollar", "percent"),
  keyMetric: DecimalFromString,
});
export type ProductSummary = S.Schema.Type<typeof ProductSummarySchema>;
export type ProductSummaryDto = S.Schema.Encoded<typeof ProductSummarySchema>;

const constructProduct = (
  productType: ProductType,
  name: string,
  keyMetricName: string,
  keyMetricUnit: "dollar" | "percent",
  keyMetricField: keyof IncomeStatement,
  incomeStatement: IncomeStatement | undefined,
) => {
  return {
    productType,
    name,
    keyMetricName,
    keyMetricUnit,
    keyMetric: incomeStatement
      ? incomeStatement[keyMetricField]
      : new Decimal(0),
  } as ProductSummary;
};

export const toProductSummary = (product: Product): ProductSummary => {
  return match(product)
    .with({ _type: "treasury" }, (res) =>
      constructProduct(
        "treasury",
        "Treasury",
        "NI",
        "dollar",
        "netIncome",
        res.incomeStatement,
      ),
    )
    .with({ _type: "ci" }, (res) =>
      constructProduct(
        "ci",
        "C&I",
        "ROE",
        "percent",
        "returnOnEquity",
        res.incomeStatement,
      ),
    )
    .with({ _type: "deposits" }, (res) =>
      constructProduct(
        "deposits",
        "Deposits",
        "ROE",
        "percent",
        "returnOnEquity",
        res.incomeStatement,
      ),
    )
    .with({ _type: "cre" }, (res) =>
      constructProduct(
        "cre",
        "CRE",
        "ROE",
        "percent",
        "returnOnEquity",
        res.incomeStatement,
      ),
    )
    .exhaustive();
};
