import fetch from "cross-fetch";
import { HttpLink, ApolloLink } from "@apollo/client";
import { Amplify } from "aws-amplify";
import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";

const url = process.env.REACT_APP_GQL_API_URL!;
const region = process.env.REACT_APP_REGION!;

const auth = {
  type: "AMAZON_COGNITO_USER_POOLS" as const,
  jwtToken: async () =>
    (await Amplify.Auth.currentSession()).getIdToken().getJwtToken(),
};

const httpLink = new HttpLink({ uri: url, fetch });

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
]);

export default link;
