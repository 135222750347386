import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PerformanceMetricProps } from "./props";

const performanceColor: { [key: string]: string } = {
  meets: "bg-green-400",
  close: "bg-amber-400",
  neutral: "bg-gray-300",
  poor: "bg-red-500",
};

const performanceIcon: { [key: string]: IconProp | undefined } = {
  meets: ["fas", "battery-full"],
  close: ["fas", "battery-three-quarters"],
  neutral: undefined,
  poor: ["fas", "battery-quarter"],
};

const PerformanceMetric = ({ metric, performance }: PerformanceMetricProps) => {
  return (
    <div className="inline-flex flex-row justify-center items-center">
      <div
        className={`${performanceColor[performance]} inline-flex flex-row flex-none w-6 mr-1 p-1 aspect-square justify-center items-center rounded-md`}
      >
        <FontAwesomeIcon
          icon={performanceIcon[performance] || ["fas", "battery-empty"]}
          size="xs"
          color="white"
          style={!performanceIcon[performance] ? { opacity: 0 } : {}}
        />
      </div>
      <span>{metric ? `${metric.mul(100).toFormat(2)}%` : "Unknown"}</span>
    </div>
  );
};

export default PerformanceMetric;
