import {
  BehaviorSubject,
  combineLatest,
  defaultIfEmpty,
  map,
  mergeMap,
  Observable,
  share,
  tap,
} from "rxjs";
import Decimal from "decimal.js-light";
import * as R from "ramda";

import anyChanged from "../../../util/operators/anyChanged";
import mapAllDefined from "../../../util/operators/mapAllDefined";

import {
  Collateral,
  CollateralItem,
  CommercialIndustrialDependencies,
} from "../core";
import {
  asObservable as asCollateralItemObservable,
  CollateralItemModel,
  fromCollateralItem,
} from "./CollateralItemModel";
import mapOnlyDefined from "../../../util/operators/mapOnlyDefined";
import fieldChanged from "../../../util/operators/fieldChanged";

export type CollateralInput = {
  collateralItems: BehaviorSubject<CollateralItemModel[]>;
  loanAmount: Observable<Decimal | undefined>;
};

export type CollateralOutput = {
  combinedLoanToValueRatio: Observable<Decimal | undefined>;
  value: Observable<Decimal | undefined>;
  priorLiens: Observable<Decimal | undefined>;
  effectiveValue: Observable<Decimal | undefined>;
  effectiveLoanToValueRatio: Observable<Decimal | undefined>;
  netRecoveryRate: Observable<Decimal | undefined>;
};

export type CollateralModel = {
  input: CollateralInput;
  output: CollateralOutput;
};

export const trackChanges = (
  input: CollateralInput,
  output: CollateralOutput,
): Observable<boolean> =>
  anyChanged([fieldChanged(output.value), fieldChanged(output.priorLiens)]);

export const calculate = (
  input: CollateralInput,
  dependencies: Pick<
    CommercialIndustrialDependencies,
    "collateralRecoveryTable"
  >,
): CollateralOutput => {
  const { collateralItems, loanAmount } = input;

  const value = collateralItems.pipe(
    mergeMap((models: CollateralItemModel[]) =>
      defaultIfEmpty<(Decimal | undefined)[], (Decimal | undefined)[]>([])(
        combineLatest(models.map((model) => model.input.value)),
      ),
    ),
    mapOnlyDefined(
      R.reduce(
        (acc: Decimal, revenue: Decimal) => acc.plus(revenue),
        new Decimal(0),
      ),
    ),
  );

  const priorLiens = collateralItems.pipe(
    mergeMap((models: CollateralItemModel[]) =>
      defaultIfEmpty<(Decimal | undefined)[], (Decimal | undefined)[]>([])(
        combineLatest(models.map((model) => model.input.priorLiens)),
      ),
    ),
    mapOnlyDefined(
      R.reduce(
        (acc: Decimal, revenue: Decimal) => acc.plus(revenue),
        new Decimal(0),
      ),
    ),
  );

  const combinedLoanToValueRatio = combineLatest([loanAmount, value]).pipe(
    mapAllDefined(([loanAmount, value]) =>
      value.eq(0) ? undefined : loanAmount.dividedBy(value),
    ),
  );

  const effectiveValue = collateralItems.pipe(
    mergeMap((models: CollateralItemModel[]) =>
      defaultIfEmpty<(Decimal | undefined)[], (Decimal | undefined)[]>([])(
        combineLatest(models.map((model) => model.output.effectiveValue)),
      ),
    ),
    mapOnlyDefined(
      R.reduce(
        (acc: Decimal, revenue: Decimal) => acc.plus(revenue),
        new Decimal(0),
      ),
    ),
  );

  const effectiveLoanToValueRatio = combineLatest([
    loanAmount,
    effectiveValue,
  ]).pipe(
    mapAllDefined(([loanAmount, effectiveValue]) =>
      effectiveValue.eq(0) ? undefined : loanAmount.dividedBy(effectiveValue),
    ),
  );

  const netRecoveryRate = collateralItems.pipe(
    mergeMap((models: CollateralItemModel[]) =>
      defaultIfEmpty<
        ([Decimal, Decimal] | undefined)[],
        ([Decimal, Decimal] | undefined)[]
      >([])(
        combineLatest(
          models.map((model) =>
            combineLatest([model.input.value, model.input.collateralType]).pipe(
              mapAllDefined(
                ([value, collateralType]) =>
                  [value, collateralType.netRecoveryRate] as [Decimal, Decimal],
              ),
            ),
          ),
        ),
      ),
    ),
    mapOnlyDefined((values: [Decimal, Decimal][]) => {
      const totals = R.reduce(
        (
          [totalValue, totalWeightedNetRecovery]: [Decimal, Decimal],
          [value, netRecoveryRate]: [Decimal, Decimal],
        ) =>
          [
            totalValue.plus(value),
            totalWeightedNetRecovery.plus(value.times(netRecoveryRate)),
          ] as [Decimal, Decimal],
        [new Decimal(0), new Decimal(0)] as [Decimal, Decimal],
        values,
      );
      return totals[0].eq(0) ? undefined : totals[1].dividedBy(totals[0]);
    }),
    tap((value) => console.log("netRecoverRate", value?.toFixed(2))),
  );

  return {
    value,
    priorLiens,
    combinedLoanToValueRatio,
    effectiveValue,
    effectiveLoanToValueRatio,
    netRecoveryRate,
  };
};

export const fromCollateral = (
  collateral: Collateral | undefined,
  loanAmount: Observable<Decimal | undefined>,
  dependencies: Pick<
    CommercialIndustrialDependencies,
    "collateralRecoveryTable"
  >,
): CollateralModel => {
  const collateralItems = new BehaviorSubject<CollateralItemModel[]>(
    R.map(
      (item) => fromCollateralItem(item, loanAmount, dependencies),
      collateral?.collateralItems || [],
    ),
  );

  const input: CollateralInput = {
    collateralItems,
    loanAmount,
  };

  const output = calculate(input, dependencies);

  return {
    input,
    output,
  };
};

export const asObservable = (
  model: CollateralModel,
): Observable<Collateral> => {
  const { collateralItems } = model.input;
  const {
    combinedLoanToValueRatio,
    value,
    priorLiens,
    effectiveValue,
    effectiveLoanToValueRatio,
    netRecoveryRate,
  } = model.output;

  const collateralItems$ = collateralItems.pipe(
    mergeMap((models) =>
      defaultIfEmpty<CollateralItem[], CollateralItem[]>([])(
        combineLatest(models.map(asCollateralItemObservable)),
      ),
    ),
  );

  return combineLatest([
    collateralItems$,
    combinedLoanToValueRatio,
    value,
    priorLiens,
    effectiveValue,
    effectiveLoanToValueRatio,
    netRecoveryRate,
  ]).pipe(
    map(
      ([
        collateralItems,
        combinedLoanToValueRatio,
        value,
        priorLiens,
        effectiveValue,
        effectiveLoanToValueRatio,
        netRecoveryRate,
      ]) => ({
        collateralItems,
        combinedLoanToValueRatio,
        value,
        priorLiens,
        effectiveValue,
        effectiveLoanToValueRatio,
        netRecoveryRate,
      }),
    ),
    share(),
  );
};
