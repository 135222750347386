import React from "react";
import { map, sortBy } from "ramda";
import moment from "moment";

import { DocumentSummaryFragment } from "@pricing-tool/graphql/lib/react";

import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { Link } from "react-router-dom";
import { useLocation, useParams } from "react-router";

export type DocumentsTableProps = {
  documents: DocumentSummaryFragment[];
  onDownload: (document: DocumentSummaryFragment) => void;
  onDelete: (document: DocumentSummaryFragment) => void;
};

const docTypeToIcon: { [key: string]: IconName } = {
  "application/pdf": "file-pdf",
  "application/msword": "file-word",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "file-word",
  "application/vnd.ms-excel": "file-excel",
  "image/jpeg": "file-image",
  "image/png": "file-image",
  "text/plain": "file-lines",
  "text/csv": "file-csv",
  "application/zip": "file-zipper",
  "application/vnd.ms-powerpoint": "file-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    "file-powerpoint",
};

const getIcon = (type: string) => {
  return docTypeToIcon[type] || "file";
};

const DocumentsTable = ({
  documents,
  onDownload,
  onDelete,
}: DocumentsTableProps) => {
  const { opportunityId } = useParams();
  const location = useLocation();

  let state = location.state as { backgroundLocation?: Location } | null;

  if (!documents || documents.length === 0) {
    return <div className="p-4">No documents</div>;
  }

  const sortedDocuments = sortBy((d) => d?.name || 1, documents);

  return (
    <table className="component documents-table">
      <thead>
        <tr>
          <th scope="col">&nbsp;</th>
          <th scope="col">Name</th>
          <th scope="col">Description</th>
          <th scope="col">Last Updated At</th>
          <th scope="col">Last Updated By</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {map((document) => {
          return (
            <tr key={document.id}>
              <td className="w-6">
                <button onClick={() => onDownload(document)}>
                  <FontAwesomeIcon
                    icon={["fas", getIcon(document.documentType)]}
                  />
                </button>
              </td>
              <td>
                <Link
                  to={`/opportunities/${opportunityId}/documents/${document.id}`}
                  state={state}
                >
                  {document.name}
                </Link>
              </td>
              <td>{document.description}</td>
              <td>{moment(document.updatedAt).format("LLL")}</td>
              <td>{document.updatedBy}</td>
              <td className="field whitespace-nowrap px-2 max-w-30 text-right text-gray-300 hover:text-red-300">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onDelete(document);
                  }}
                >
                  <FontAwesomeIcon icon="minus-square" size="lg" />
                </button>
              </td>
            </tr>
          );
        }, sortedDocuments)}
      </tbody>
    </table>
  );
};

export default DocumentsTable;
