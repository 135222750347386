import { Schema as S } from "@effect/schema";
import { IncomeStatementSchema } from "../incomeStatement";
import { ProductsFromStringSchema } from "../products/core";

export const ScenarioSchema = S.Struct({
  id: S.String,
  opportunityId: S.optional(S.String),
  name: S.String,
  sortOrder: S.Number,
  products: ProductsFromStringSchema,
  incomeStatement: S.optional(IncomeStatementSchema),
  isDirty: S.optional(S.mutable(S.Boolean)),
});
export type Scenario = S.Schema.Type<typeof ScenarioSchema>;
